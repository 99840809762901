import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from "../Context/ThemeContext";
import DropdownComponent from './UIComponents/DropdownComponent';
import DatePickerComponent from './UIComponents/DatePickerComponent';
import TimePickerComponent from './UIComponents/TimePickerComponent';
import styles from "../styles/ComponentStyles/HomeFilter.module.scss";
import SecondaryButtonComponent from './UIComponents/SecondaryButtonComponent';
import PrimaryButtonComponent from './UIComponents/PrimaryButtonComponent';
import { modifyFilter, getFiltersSelector, initialFilterValue } from '../Store/Fliters.slice'
import { toogleApiCall, apicall } from '../Store/VideoFiltersApiCall.slice'
import { useSelector, useDispatch } from 'react-redux'
import apiFunction from '../API/apiFunction';
import { UseAuth } from "../API/authUtils";
import {  modifyHomeFilter } from '../Store/HomeFilter.slice';
import CloseIcon from '../Assets/Svg/CloseIconSVG'
import { IconButton } from '@mui/material';


export default function HomeFilter() {

  const filters = useSelector(getFiltersSelector);
  const dispatch = useDispatch();
  const apicallCondition = useSelector(apicall);
  const { theme } = useContext(ThemeContext);
  const [mediaSource, setMediaSource] = useState<string>("");
  const [mediaOptions, setMediaOptions] = useState<any>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<any | null>(null);
  const [endTime, SetEndTime] = useState<string | null>(null);
  const { handleGetToken } = UseAuth();



  useEffect(() => {
    const api = async () => {
      try {
        const token = await handleGetToken();
        const mediaOptionsApi = await apiFunction.getReferenceMediaSourceAPI(token);
        setMediaOptions(mediaOptionsApi.data);
      } catch (error) {
        console.log(error);
      }
    };
    api();
  }, []);

  const mediaSourceArr = mediaOptions?.map(item => {
    const key = item.MediaSource;
    return { key, value: key };
  });

  const handleApplyFiltersClick = () => {

    const currentApiCondition = true;
    if (apicallCondition !== true) {
      const updatedFilter = {
        ...filters,
        mediasource: mediaOptions?.find(obj => obj.MediaSource === mediaSource)?.ReferenceMediaSourceId || "",
        startdate: startDate === null ? initialFilterValue.startdate : startDate || filters.startdate,
        enddate: endDate === null ? initialFilterValue.enddate : endDate || filters.enddate,
        starttime: startTime === null ? initialFilterValue.starttime : startTime || filters.starttime,
        endtime: endTime === null ? initialFilterValue.endtime : endTime || filters.endtime,
        name: ""
      };

      if (
        updatedFilter.mediasource !== filters.mediasource ||
        updatedFilter.startdate !== filters.startdate ||
        updatedFilter.enddate !== filters.enddate ||
        updatedFilter.starttime !== filters.starttime ||
        updatedFilter.endtime !== filters.endtime ||
        updatedFilter.name !== filters.name
      ) {
        dispatch(modifyFilter(updatedFilter));
      }
      dispatch(toogleApiCall(currentApiCondition))
    }
    dispatch(modifyHomeFilter(false))
  }


  const handleClearClick = () => {
    const currentApiCondition = true;
    dispatch(modifyFilter(initialFilterValue));
    dispatch(toogleApiCall(currentApiCondition));

    SetEndTime(null)
    setStartTime(null)
    setStartDate(null)
    setEndDate(null)
    setMediaSource('')
  }

  const handleStartTimeChange = (newTime: any) => {
    setStartTime(newTime);
  };

  const handleEndTimeChange = (newTime: any) => {
    SetEndTime(newTime);
  };
  const handleMediaSourceChange = (event) => {
    setMediaSource(event.target.value)
  }

  const handleClose = () => {
    dispatch(modifyHomeFilter(false))
  };



  return (
    <div style={{ height: "100vh", }} className={`${styles[theme]}`}>
      <form action="#" >
        <aside className={`${styles.parent} ${styles[theme]}`} >
          <div className={`${styles.fiterHeader} ${styles[theme]}`}>
            <p className={`${styles.header} ${styles[theme]}`} >Filters</p>
            <IconButton className={styles.iconContent} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <section style={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
            <div >
              <p className={`${styles.filterDiv} ${styles[theme]}`}>Select Your Source</p>
              <DropdownComponent value={mediaSource} handleChangeFunc={handleMediaSourceChange} label={""} menuItemValues={mediaSourceArr} name={"mediaSource"} />
            </div>
            <div >
              <p className={`${styles.filterDiv} ${styles[theme]}`}>Select Your Date</p>
              <DatePickerComponent value={startDate} handleChange={setStartDate} label={"From"} />
              <DatePickerComponent value={endDate} handleChange={setEndDate} label={"To"} />
            </div>
            <div >
              <p className={`${styles.filterDiv} ${styles[theme]}`}>Select Your Time</p>
              <TimePickerComponent value={startTime}
                onTimeChange={handleStartTimeChange}
                label={"From"} />
              <TimePickerComponent value={endTime}
                onTimeChange={handleEndTimeChange}
                label={"To"} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }} >
              <div className={`${styles.SecondaryButtonContainer} ${styles[theme]}`} >
                <SecondaryButtonComponent onClick={handleClearClick} variant={"outlined"} label={"Clear"} fullwidth={false} size={"small"} />
              </div>
              <div className={`${styles.PrimaryButtonContainer} ${styles[theme]}`}>
                <PrimaryButtonComponent onClick={handleApplyFiltersClick} variant={"contained"} label={"Apply Filters"} fullwidth={false} size={"small"} />
              </div>
            </div>
          </section>
        </aside>
      </form>
    </div>
  )

}