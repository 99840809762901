import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";

const initialState: string = "";

const sasToken = createSlice({
  name: "sasToken",
  initialState,
  reducers: {
    setSasToken: (_, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const getSasToken = (state: Rootstate) => state.SasTokenSlice;
export const { setSasToken } = sasToken.actions;

export default sasToken.reducer;
