import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import styles from "../../styles/UIComponent.module.scss";
import { useContext } from "react";
import { ThemeContext } from "../../Context/ThemeContext"
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from "dayjs";

export default function TimePickerComponent(props: any) {

  const { theme } = useContext(ThemeContext);
  const { value, label, onTimeChange, error } = props

  const handleTime = (event: any) => {
    const formattedDate = dayjs(event).format("HH:mm:ss");

    // Check if the formatted date is "Invalid Date"
    if (formattedDate === "Invalid Date") {
      onTimeChange(null);
      return;
    }
    onTimeChange(formattedDate);
  };


  return (
    <Box>
      <FormControl style={{ width: "100%" }}>
        <p className={`${styles.labelContent} ${styles[theme]}`} >{label}</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={value ? dayjs(value) : null}
            onChange={(event: any) => handleTime(event)}
            className={`${styles.dateTimePickerStyle} ${styles[theme]}`}
            ampm={false}
            views={[
              "hours",
              "minutes",
              "seconds",
            ]}
            timeSteps={{
              hours: 1,
              minutes: 1,
              seconds: 1,
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme === 'light' ? "#2e5cff" : "#6a48f2",
              },

            }}

            slotProps={{
              textField: {
                style: { border: error === "MinToDetect is required" ? '1px solid red' : "", },
                inputProps: {
                  className : `${styles.dateTimePropStyle}`
                },
              },
              // layout: {
              //   sx: {
              //     '.MuiPickersLayout-contentWrapper': {
              //       width: "10vw",
              //       height: "17vw"
              //     },
              //     '.MuiDialogActions-root': {
              //       paddingRight: "0.5vw"
              //     },
              //     '.MuiButtonBase-root': {
              //       fontSize: "1vw",
              //       height: "1vw",
              //       width: "2vw",
              //       padding: "1vw",
              //     },
              //     '.MuiList-root ': {
              //       width: "10vw",
              //       height: "50vw",
              //       maxHeight: "50vw"
              //     },
              //     '.MuiMultiSectionDigitalClock-root': {
              //       maxHeight: "30vw",
              //       height: "50vw"
              //     }

              //   },
              // },
            }}
             />
        </LocalizationProvider>
      </FormControl>
    </Box >
  );
}