import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";

const initialState = false;

const VideoFiltersApiCallSlice = createSlice({
    name: "VideoFiltersApiCallSlice",
    initialState,
    reducers: {
        toogleApiCall: (state, action: PayloadAction<boolean>) => {
            return action.payload;
        },
    }
})


export const { toogleApiCall } = VideoFiltersApiCallSlice.actions;

export const apicall = (state: Rootstate) => state.ApiCall;

export default VideoFiltersApiCallSlice.reducer;