import axios from 'axios';
import ApiConfig from '../ApiConfig';

    const getAiVideoAPI = async (filters: any, searchValue: any, cancel: any, token:any) => {
        try {
            const response = await axios.get(
                `${ApiConfig.api.getAiVideo}?startdate=${filters.startdate}&enddate=${filters.enddate}&name=${searchValue ? searchValue : filters.name}&starttime=${filters.starttime}&endtime=${filters.endtime}&mediasource=${filters.mediasource}`,
                {
                    cancelToken: new axios.CancelToken(c => cancel = c),
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getSasTokenAPI = async (token : any) => {
        try {
            const response =  await axios.get(ApiConfig.api.getSASToken,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getReferenceMediaSourceAPI = async (token : any) => {
        try {
            const response =  await axios.get(`${ApiConfig.api.getReferenceMediaSource}`,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getSelectedVideoAPI = async (token : any , selectedVideo : any) => {
        try {
            const response =  await axios.get(`${ApiConfig.api.getSelectedVideo}?id=${selectedVideo}`,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getAllInferenceGroupApi = async (token : any ) => {
        try {
            const response =  await axios.get(`${ApiConfig.api.getInferenceGroup}`,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getServerIpAPI = async (token: any , id: any) => {
        try {
            const response =  await axios.get(`${ApiConfig.api.getServerIP}?id=${id}`,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getAllServerDetailsAPI = async (token: any , id: any) => {
        try {
            const response =  await axios.get(`${ApiConfig.api.getServerDetails}?id=${id}`,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getServerMontiorsAPI = async (token: any , id: any) => {
        try {
            const response =  await axios.get(`${ApiConfig.api.getServerMatix}?inferenceId=${id}`,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getContainerDetailsAPI = async (token: any , id: any) => {
        try {
            const response =  await axios.get(`${ApiConfig.api.getContainerDetails}?id=${id}`,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getRunHistoryAPI = async (token: any , runHistoryData: any) => {
        try {
            const response =  await axios.post(ApiConfig.api.getContainerHistory, runHistoryData,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getValidatedCameraDetailsAPI = async (token: any , id: any) => {
        try {
            const response =  await axios.get(ApiConfig.api.getValidatedCameraDetails + "?id=" + id,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const insertLiveAPI = async (token: any , data: any) => {
        try {
            const response =  await axios.post(ApiConfig.api.insertUpdateLive, data,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const deleteLiveAPI = async (token: any , id: any) => {
        try {
            const response =  await axios.put(
                `${ApiConfig.api.deleteRTSP}`,
                {
                    "cameraStreamId" : id  
                },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getAllZoneDetailsAPI = async (token : any ) => {
        try {
            const response =  await axios.get(ApiConfig.api.getAllZoneDetails,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getMediaDetailsAPI = async (token: any , id: any) => {
        try {
            const response =  axios.get(`${ApiConfig.api.getMediaDrive}?usermail=` + id,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const insertAnnotationDetailsAPI = async (token: any , data: any) => {
        try {
            const response =  await axios.post(ApiConfig.api.insertAnnotationDetails, data,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const insertMediaDriveAPI = async (token: any , data: any) => {
        try {
            const response =  await axios.post(ApiConfig.api.insertUpdateMediaDrive, data,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const getRunAnalyticsAPI = async (token : any ) => {
        try {
            const response =  await axios.get(`${ApiConfig.api.getRunAnalytics}`,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const insertZoneDetailsAPI = async (token: any , data: any) => {
        try {
            const response =  await axios.post(ApiConfig.api.insertZoneDetails, data,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const insertMediaFilesAPI = async (token: any , data: any) => {
        try {
            const response =  axios.post(ApiConfig.api.insertMediaFile , data,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const stopContainerAPI = async (token: any , data: any) => {
        try {
            const response =  axios.post(`${ApiConfig.api.stopContainer}containerID=${data}`,
            {},
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const insertServerAPI = async (token: any , data: any) => {
        try {
            const response =  await axios.post(ApiConfig.api.insertUpdateServer, data,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const updateContainerStatusAPI = async (token: any , data: any) => {
        try {
            const response =  await axios.post(`${ApiConfig.api.updateContainerStatus}?id=${data}`, 
            {},
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };

    const insertInferencegroupAPI = async (token: any , data: any) => {
        try {
            const response =  await axios.post(ApiConfig.api.insertInferenceGroup, data,
            {
                headers :{
                    Authorization: `Bearer ${token}`
                }
            })
            return response;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };



export default {
    getAiVideoAPI,
    getSasTokenAPI,
    getReferenceMediaSourceAPI,
    getSelectedVideoAPI,
    getAllInferenceGroupApi,
    getServerIpAPI,
    getContainerDetailsAPI,
    getAllServerDetailsAPI,
    getServerMontiorsAPI,
    getRunHistoryAPI,
    getValidatedCameraDetailsAPI,
    insertLiveAPI,
    deleteLiveAPI,
    getAllZoneDetailsAPI,
    getMediaDetailsAPI,
    insertAnnotationDetailsAPI,
    insertMediaDriveAPI,
    getRunAnalyticsAPI,
    insertZoneDetailsAPI,
    insertMediaFilesAPI,
    stopContainerAPI,
    insertServerAPI,
    updateContainerStatusAPI,
    insertInferencegroupAPI
}