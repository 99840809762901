import React , { SVGProps , useContext} from "react"
import { ThemeContext } from '../../Context/ThemeContext'

const AnalyticsIconFilledDarkTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg

    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2696 11.5355C26.2696 9.71841 25.5615 8.00956 24.276 6.72368C22.9904 5.43817 21.2816 4.73008 19.4645 4.73008C17.647 4.73008 15.9381 5.43817 14.6526 6.72368C13.3671 8.00956 12.659 9.71805 12.659 11.5355C12.659 13.353 13.3671 15.0619 14.6526 16.3474C15.9381 17.6329 17.647 18.3406 19.4645 18.3406C21.2816 18.3406 22.9904 17.6329 24.276 16.3474C25.5615 15.0619 26.2696 13.353 26.2696 11.5355ZM16.7271 8.3784C17.2908 7.81432 18.2046 7.81432 18.7683 8.3784C19.3324 8.94212 19.3324 9.85628 18.7683 10.4196C18.2046 10.9837 17.2908 10.9837 16.7271 10.4196C16.163 9.85628 16.163 8.94212 16.7271 8.3784ZM21.1712 9.36329L22.6555 9.79781L24.1395 9.36329L23.705 10.8473L24.1395 12.3313L22.6555 11.8968L21.1712 12.3313L21.606 10.8473L21.1712 9.36329ZM21.7103 13.0499L22.1142 15.1095L20.0542 15.5134L19.6507 13.4533L21.7103 13.0499ZM17.6715 12.2144C17.822 12.0841 18.0505 12.1003 18.1807 12.2508C18.3114 12.4017 18.2952 12.6294 18.1432 12.7601L17.1572 13.6143L18.0122 14.6014C18.1429 14.7522 18.1263 14.9803 17.9758 15.1106C17.9075 15.1698 17.8235 15.1986 17.7397 15.1986C17.6387 15.1986 17.5384 15.1564 17.4669 15.0741L16.6119 14.0871L15.6252 14.9424C15.557 15.0012 15.4729 15.0305 15.3892 15.0305C15.2882 15.0305 15.1878 14.9886 15.1164 14.906C14.9857 14.7551 15.0023 14.5274 15.1528 14.3967L16.1399 13.5414L15.2856 12.5551C15.155 12.4042 15.1716 12.1761 15.3221 12.0458C15.4733 11.9148 15.7007 11.9318 15.8313 12.0823L16.6855 13.0686L17.6715 12.2144ZM21.9114 8.28024L19.9888 7.82875L21.3411 6.38985L21.9114 8.28024Z"
      fill="#F5F5F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0414 17.1129C26.5316 15.6227 27.3522 13.6421 27.3522 11.5355C27.3522 9.42934 26.5316 7.44837 25.0414 5.95822C23.5516 4.46807 21.571 3.64739 19.4645 3.64739C17.3575 3.64739 15.3769 4.46807 13.8871 5.95822C12.397 7.44837 11.5763 9.42934 11.5763 11.5355C11.5763 13.6421 12.397 15.6231 13.8871 17.1129C15.3773 18.603 17.3579 19.4233 19.4645 19.4233C21.571 19.4233 23.5516 18.603 25.0414 17.1129ZM24.276 6.72368C25.5615 8.00956 26.2696 9.71841 26.2696 11.5355C26.2696 13.353 25.5615 15.0619 24.276 16.3474C22.9904 17.6329 21.2816 18.3406 19.4645 18.3406C17.647 18.3406 15.9381 17.6329 14.6526 16.3474C13.3671 15.0619 12.659 13.353 12.659 11.5355C12.659 9.71805 13.3671 8.00956 14.6526 6.72368C15.9381 5.43817 17.647 4.73008 19.4645 4.73008C21.2816 4.73008 22.9904 5.43817 24.276 6.72368ZM27.2671 19.3385C29.3516 17.254 30.5 14.483 30.5 11.5359C30.5 8.5888 29.352 5.81747 27.2671 3.73292C25.1829 1.64838 22.4119 0.5 19.4645 0.5C16.5174 0.5 13.7464 1.64838 11.6619 3.73292C8.00271 7.39207 7.45775 13.0015 10.0223 17.2442L1.27196 25.9942C0.24268 27.0235 0.24268 28.6984 1.27196 29.728C1.7866 30.2427 2.46292 30.5 3.13889 30.5C3.81485 30.5 4.49117 30.2427 5.00581 29.728L13.7529 20.981C15.4581 22.0167 17.4185 22.5711 19.4645 22.5711C22.4116 22.5711 25.1825 21.4231 27.2671 19.3385ZM11.6619 19.3385C12.0336 19.7102 12.428 20.0509 12.8409 20.362L4.24035 28.9626C3.63259 29.5696 2.64409 29.57 2.03743 28.9626C1.43039 28.3552 1.43039 27.367 2.03743 26.7597L10.6394 18.158C10.9487 18.5691 11.288 18.9646 11.6619 19.3385ZM26.5016 4.49839C28.3819 6.37867 29.4173 8.87788 29.4173 11.5359C29.4173 14.1935 28.3819 16.6928 26.5016 18.573C24.6217 20.453 22.1225 21.4884 19.4645 21.4884C16.8064 21.4884 14.3072 20.453 12.4273 18.573C8.5473 14.6927 8.5473 8.37876 12.4273 4.49839C14.3072 2.61811 16.8064 1.58269 19.4645 1.58269C22.1225 1.58269 24.6217 2.61811 26.5016 4.49839Z"
      fill="#F5F5F5"
    />
  </svg>
)

const AnalyticsIconFilledLightTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg

    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2696 11.5355C26.2696 9.71841 25.5615 8.00956 24.276 6.72368C22.9904 5.43817 21.2816 4.73008 19.4645 4.73008C17.647 4.73008 15.9381 5.43817 14.6526 6.72368C13.3671 8.00956 12.659 9.71805 12.659 11.5355C12.659 13.353 13.3671 15.0619 14.6526 16.3474C15.9381 17.6329 17.647 18.3406 19.4645 18.3406C21.2816 18.3406 22.9904 17.6329 24.276 16.3474C25.5615 15.0619 26.2696 13.353 26.2696 11.5355ZM16.7271 8.3784C17.2908 7.81432 18.2046 7.81432 18.7683 8.3784C19.3324 8.94212 19.3324 9.85628 18.7683 10.4196C18.2046 10.9837 17.2908 10.9837 16.7271 10.4196C16.163 9.85628 16.163 8.94212 16.7271 8.3784ZM21.1712 9.36329L22.6555 9.79781L24.1395 9.36329L23.705 10.8473L24.1395 12.3313L22.6555 11.8968L21.1712 12.3313L21.606 10.8473L21.1712 9.36329ZM21.7103 13.0499L22.1142 15.1095L20.0542 15.5134L19.6507 13.4533L21.7103 13.0499ZM17.6715 12.2144C17.822 12.0841 18.0505 12.1003 18.1807 12.2508C18.3114 12.4017 18.2952 12.6294 18.1432 12.7601L17.1572 13.6143L18.0122 14.6014C18.1429 14.7522 18.1263 14.9803 17.9758 15.1106C17.9075 15.1698 17.8235 15.1986 17.7397 15.1986C17.6387 15.1986 17.5384 15.1564 17.4669 15.0741L16.6119 14.0871L15.6252 14.9424C15.557 15.0012 15.4729 15.0305 15.3892 15.0305C15.2882 15.0305 15.1878 14.9886 15.1164 14.906C14.9857 14.7551 15.0023 14.5274 15.1528 14.3967L16.1399 13.5414L15.2856 12.5551C15.155 12.4042 15.1716 12.1761 15.3221 12.0458C15.4733 11.9148 15.7007 11.9318 15.8313 12.0823L16.6855 13.0686L17.6715 12.2144ZM21.9114 8.28024L19.9888 7.82875L21.3411 6.38985L21.9114 8.28024Z"
      fill="#28282b"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0414 17.1129C26.5316 15.6227 27.3522 13.6421 27.3522 11.5355C27.3522 9.42934 26.5316 7.44837 25.0414 5.95822C23.5516 4.46807 21.571 3.64739 19.4645 3.64739C17.3575 3.64739 15.3769 4.46807 13.8871 5.95822C12.397 7.44837 11.5763 9.42934 11.5763 11.5355C11.5763 13.6421 12.397 15.6231 13.8871 17.1129C15.3773 18.603 17.3579 19.4233 19.4645 19.4233C21.571 19.4233 23.5516 18.603 25.0414 17.1129ZM24.276 6.72368C25.5615 8.00956 26.2696 9.71841 26.2696 11.5355C26.2696 13.353 25.5615 15.0619 24.276 16.3474C22.9904 17.6329 21.2816 18.3406 19.4645 18.3406C17.647 18.3406 15.9381 17.6329 14.6526 16.3474C13.3671 15.0619 12.659 13.353 12.659 11.5355C12.659 9.71805 13.3671 8.00956 14.6526 6.72368C15.9381 5.43817 17.647 4.73008 19.4645 4.73008C21.2816 4.73008 22.9904 5.43817 24.276 6.72368ZM27.2671 19.3385C29.3516 17.254 30.5 14.483 30.5 11.5359C30.5 8.5888 29.352 5.81747 27.2671 3.73292C25.1829 1.64838 22.4119 0.5 19.4645 0.5C16.5174 0.5 13.7464 1.64838 11.6619 3.73292C8.00271 7.39207 7.45775 13.0015 10.0223 17.2442L1.27196 25.9942C0.24268 27.0235 0.24268 28.6984 1.27196 29.728C1.7866 30.2427 2.46292 30.5 3.13889 30.5C3.81485 30.5 4.49117 30.2427 5.00581 29.728L13.7529 20.981C15.4581 22.0167 17.4185 22.5711 19.4645 22.5711C22.4116 22.5711 25.1825 21.4231 27.2671 19.3385ZM11.6619 19.3385C12.0336 19.7102 12.428 20.0509 12.8409 20.362L4.24035 28.9626C3.63259 29.5696 2.64409 29.57 2.03743 28.9626C1.43039 28.3552 1.43039 27.367 2.03743 26.7597L10.6394 18.158C10.9487 18.5691 11.288 18.9646 11.6619 19.3385ZM26.5016 4.49839C28.3819 6.37867 29.4173 8.87788 29.4173 11.5359C29.4173 14.1935 28.3819 16.6928 26.5016 18.573C24.6217 20.453 22.1225 21.4884 19.4645 21.4884C16.8064 21.4884 14.3072 20.453 12.4273 18.573C8.5473 14.6927 8.5473 8.37876 12.4273 4.49839C14.3072 2.61811 16.8064 1.58269 19.4645 1.58269C22.1225 1.58269 24.6217 2.61811 26.5016 4.49839Z"
      fill="#28282b"
    />
  </svg>
)

const AnalyticsRunFilledSVG = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      {
        theme === "light"
          ?
          <AnalyticsIconFilledLightTheme /> : <AnalyticsIconFilledDarkTheme />}
    </>
  )
}


export default AnalyticsRunFilledSVG
