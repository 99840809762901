import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from '../../styles/UIComponent.module.scss';
import { ThemeContext } from '../../Context/ThemeContext';

interface MyProps {
    data?: string | undefined;
    lastRow: boolean;
}

const ToolTipComponent: React.FC<MyProps> = (props: MyProps) => {
    const theme = useContext(ThemeContext);
    const truncatedTextRef = useRef<HTMLDivElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const element = truncatedTextRef.current;
        if (element) {
            setIsTruncated(element.scrollWidth > element.clientWidth);
        }
    }, [props.data]);

    return (
        <div className={`${styles.toolTipContainer} ${styles[theme]}`}>
            <div
                ref={truncatedTextRef}
                className={`${styles.truncatedText} ${styles[theme]}`}
            >
                {props.data}
            </div>
            {isTruncated && (
                <div
                    className={`${styles.tooltip} ${styles[theme]}`}
                    style={{ marginTop: props.lastRow ? '-3.5rem' : '' }}
                >
                    {props.data}
                </div>
            )}
        </div>
    );
};

export default ToolTipComponent;
