import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { LogInRequest } from "../Config";
import Grid from "@mui/material/Grid";
import styles from "../styles/LogIn.module.scss";
import Footer from "../Components/Footer";
import Bi3TechLogoSVG from '../Assets/Svg/Bi3TechLogoSVG'
import SmartiLogoSVG from '../Assets/Svg/SmartiFullLogoSVG'
import Illustration from '../Assets/Svg/LoginPageIllustraionSVG'
import { ThemeContext } from '../Context/ThemeContext'
import PrimaryButtonComponent from "../Components/UIComponents/PrimaryButtonComponent";


export let Token = " ";


export const LogIn = () => {
  const { theme } = useContext(ThemeContext);
  const { instance } = useMsal();



  const HandleLogin = async (e: any) => {
    try {
      const Response = await instance.loginPopup(LogInRequest);
      Token = Response.accessToken;
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className={`${styles[theme]}`} >
      <div className={`${styles.wallpaper} ${styles[theme]}`}>

        <div className={styles.loginDiv}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={4} lg={2} xl={2} >
              <div className={styles.logoContainer}>
                <Bi3TechLogoSVG />
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={10} sm={6} md={6} lg={5} xl={5} className={styles.leftContainer} >
                <div className={styles.smartImgContainer}>
                  <SmartiLogoSVG />
                </div>
                <div className={styles.buttonContainer}>
                  <PrimaryButtonComponent onClick={HandleLogin} label={"Login"} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={styles.rightContainer}>
                <div className={styles.imageContainer}>
                  <Illustration />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div style={{bottom : "0", position: "fixed", width : "100%"}}>
          <Footer />
          </div>
        </div>
      </div>
    </div>

  );
};

export default LogIn;