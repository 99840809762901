import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";
import { fileNode } from '../types';

const initialState: Array<fileNode> = [];


const treeStructure = createSlice({
    name: "tree",
    initialState,
    reducers: {
        setTree: (_, action: PayloadAction<any>) => {
            return action.payload
        }
    }
})

export const { setTree } = treeStructure.actions;

export const getTree = (state: Rootstate) => state.FileExplorerSlice;

export default treeStructure.reducer
