import React, { useContext, useState, useEffect, useCallback } from 'react'
import { motion, useAnimation } from "framer-motion"
import { msalInstance } from "..";
import { ThemeContext } from "../Context/ThemeContext";
import styles from '../styles/ComponentStyles/MenuBar.module.scss'
import HomeIcon from '../Assets/Svg/HomeIconOutlinedSVG'
import ServerIcon from '../Assets/Svg/ServerIconOutlinedSVG'
import AnalysisIcon from '../Assets/Svg/AnalyticsIconOutlinedSVG'
import AnalyticsSideBar from './AnalyticsSideBar';
import HomeFilter from './HomeFilter';
import ServerIconFilled from '../Assets/Svg/ServerIconFilledSVG'
import HomeIconFilled from '../Assets/Svg/HomeIconFilledSVG'
import AnalyticsIconFilled from '../Assets/Svg/AnalyticsFilledDarkThemeSVG'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartiLogoSVG from '../Assets/Svg/SmartiLogoSVG'
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import UserProfile from '../Components/UserProfile'
import { UserDetailsrequest } from "../Config";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from 'react-redux'
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { modifyUserDetails } from '../Store/Userdetails.slice'
import { getcurrentPage, setcurrentPage } from '../Store/PageNavigation.slice'
import { setSasToken } from '../Store/SasToken.slice'
import HamburgerIcon from '../Assets/Svg/HamburgerIconLightThemeSVG'
import { UseAuth } from "../API/authUtils";
import ApiFunction from '../API/apiFunction';
import { setcurrentActivePage, getcurrentActivePage } from "../Store/ComponentNavigation.slice";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import { getIndex, setIndex} from '../Store/SideBarNavigation.slice'

import {
    GetUserDetails,
    GetUserDisplayPicture,
    GraphData,
} from "./GetGraphDetails";

export interface ProfileProps {
    AccName: AccountInfo;
    userDialogBox: boolean;
}

const MenuBar = () => {

    const { theme } = useContext(ThemeContext);
    const [userDialogBox, setUserDialogBox] = useState(false);
    const Account = msalInstance.getActiveAccount();
    const [GetProfileImageURL, SetProfileImageURL] = useState<any>(" ");
    let navigate = useNavigate()
    const pageDispatch = useDispatch();
    const currentPage = useSelector(getcurrentPage);
    const { handleGetToken } = UseAuth();
    const sideBarIndex = useSelector(getIndex);

    useEffect(() => {
        if (currentPage === 1) {
            navigate("/");
        } else if (currentPage === 2) {
            navigate("/server");
        }
        else if (currentPage === 3) {
            navigate("/analytics")
        }
    }, [currentPage, navigate]);

    const componentArray = [{
        id: 1,
        name: 'Home',
        icon: 1 === currentPage ? <HomeIconFilled /> : <HomeIcon />,
        sideBar: <HomeFilter />,
    }, {
        id: 2,
        name: 'Server',
        icon: 2 === currentPage ? <ServerIconFilled /> : <ServerIcon />,

    }, {
        id: 3,
        name: 'Analytics',
        icon: 3 === currentPage ? <AnalyticsIconFilled /> : <AnalysisIcon />,
        sideBar: <AnalyticsSideBar />,
    }]

    const analyticsComponents = [{
        id: 1,
        name: <CameraAltIcon />
    }, {
        id: 2,
        name: <AddToDriveIcon />
    }, {
        id: 3,
        name: <AnalyticsOutlinedIcon />
    }]



    const activePageData = componentArray.find(val => val.id === currentPage);
    const activePageSideBar = activePageData ? activePageData.sideBar : null;


    const [isOpen, setIsOpen] = useState(false);
    const [flipped, setFlipped] = useState(false);
    const [spinnerState, setSpinnerState] = useState({
        rotation: 0,
        isRotated: false
    });
    const controls = useAnimation();



    const toggleDiv = () => {
        setFlipped(!flipped)
        setIsOpen(!isOpen);
        if (!flipped) {
            controls.start("open");
        } else {
            controls.start("closed");
        }
    };


    const handleSpin = () => {
        // Calculate the next rotation angle based on the current state
        const nextRotation = spinnerState.isRotated ? 0 : 110;

        // Update the spinner state
        setSpinnerState({
            rotation: nextRotation,
            isRotated: !spinnerState.isRotated
        });
        setUserDialogBox(!userDialogBox);
    };

    useEffect(() => {
        controls.start("open"); setFlipped(true)
    }, [currentPage, controls])



    const [GetUserProfile, SetUserProfile] = useState<GraphData | null>(null);
    const { instance, inProgress } = useMsal();
    let ReaderAccessToken = " ";
    const dispatch = useDispatch()


    const GetUserProfileFunc = useCallback(async () => {
        if (inProgress === InteractionStatus.None && !GetUserProfile) {
            //To Generate Access Token with Reader Access

            // eslint-disable-next-line react-hooks/exhaustive-deps
            ReaderAccessToken = (
                await msalInstance.acquireTokenSilent({
                    ...UserDetailsrequest,
                    account: Account!,
                })
            ).accessToken;

            GetUserDetails(ReaderAccessToken)
                .then((response) => {
                    SetUserProfile(response);
                })
                .catch((error) => {
                    console.log(`Error While fetching User details!!! ${error}`);
                });

            GetUserDisplayPicture(ReaderAccessToken)
                .then((response) => {
                    SetProfileImageURL(response);
                })
                .catch((error) => {
                    console.log(`Error While fetching Display Picture ${error}`);
                });
        }
    }, [inProgress, GetUserProfile, msalInstance, UserDetailsrequest, Account, SetUserProfile, GetUserDetails, GetUserDisplayPicture, SetProfileImageURL])

    useEffect(() => {
        GetUserProfileFunc();
    }, [inProgress, instance, GetUserProfile, ReaderAccessToken, GetUserProfileFunc]);

    useEffect(() => {
        let x = {
            userName: GetUserProfile ? GetUserProfile.displayName : "",
            userEmail: GetUserProfile ? GetUserProfile.mail : "",
            userProfileImage: GetProfileImageURL ? GetProfileImageURL : "",
        }
        dispatch(modifyUserDetails(x))
    }, [GetProfileImageURL, GetUserProfile, GetUserProfileFunc, dispatch])



    useEffect(() => {
        const getSASToken = async () => {
            try {
                const token = await handleGetToken();
                const Resp = await ApiFunction.getSasTokenAPI(token)
                dispatch(setSasToken(Resp.data[0].sasToken))
            } catch (error) {
                console.log(error);
            }
        }
        getSASToken()
    }, [dispatch])

    const currentActivePage = useSelector(getcurrentActivePage)

    const toggleMenu = (obj: any) => {
        if (obj === 3) {
            pageDispatch(setIndex(true));
        }
        else {
            pageDispatch(setIndex(false));
        }
    };


    return (
        <>
            {/* <input type="checkbox" id='toggelNav' className={`${styles.toggelNav} ${styles[theme]}`} />
            <label htmlFor="toggelNav" className={`${styles.toggelNavHamburgerContainer} ${styles[theme]}`}>
                <figure><HamburgerIcon className={`${styles.toggelNavHamburger} ${styles[theme]}`} /></figure>
            </label> */}
            <div className={`${styles.menubar} ${styles[theme]}`}>
                <div className={`${styles.parent}`}>
                    <div className={`${styles[theme]}`}>
                        <div className={`${styles.itemsAlign} ${styles[theme]}`} >
                            <figure className={`${styles.logoContainer} ${styles[theme]}`} >
                                <SmartiLogoSVG className={`${styles.logo} ${styles[theme]}`} onClick={() => { pageDispatch(setcurrentPage(1)) }} />
                            </figure>

                            <div className={`${styles.sectionsContainer} ${styles[theme]}`} >
                                <section className={`${styles.iconsSection} ${styles[theme]}`} >
                                    <nav className={`${styles.nav} ${styles[theme]}`}>
                                        {
                                            componentArray.map((obj) => {
                                                return (
                                                    <ul className={obj.id === currentPage ? `${styles.clickedStyle} ${styles[theme]}` : `${styles.hoverStyle} ${styles[theme]}`} key={obj.id} >
                                                        {
                                                            obj.id === currentPage && (
                                                                <motion.div
                                                                    className={`${styles.activePageIndicator} ${styles[theme]}`}
                                                                    layoutId='activePageIndicator'
                                                                // transition={{ duration: 0.5 }}
                                                                />
                                                            )
                                                        }
                                                        <div className={`${styles.iconsContainer} ${styles[theme]}`}
                                                            onClick={() => {
                                                                pageDispatch(setcurrentPage(obj.id));
                                                                toggleMenu(obj.id)
                                                            }}

                                                        >
                                                            <div className={`${styles.icons} ${styles[theme]}`}   >{obj.icon}</div>
                                                            <div className={`${styles.iconsLable} ${styles[theme]}`}  >{obj.name}</div>
                                                        </div>

                                                    </ul>
                                                )
                                            })
                                        }
                                    </nav>
                                </section>
                                <section className={`${styles.toolBarContainer} ${styles[theme]}`} >
                                    <div style={{ display: "flex", justifyContent: 'center' }} className={`${styles.arrowContainer} ${styles[theme]}`}>
                                        {activePageData?.id !== 2 && <Button onClick={toggleDiv}>
                                            <motion.span
                                                style={{
                                                    display: "inline-block",
                                                    transform: flipped ? "scaleX(-1)" : "scaleX(1)",
                                                    transition: "transform 0.2s ease-in-out"
                                                }}
                                            >
                                                <div style={{ display: "flex" }}>
                                                    <ArrowForwardIosRoundedIcon className={`${styles.arrowIcon1} ${styles[theme]}`} />
                                                    <ArrowForwardIosRoundedIcon className={`${styles.arrowIcon2} ${styles[theme]}`} />

                                                </div>
                                            </motion.span>
                                        </Button>}
                                    </div>
                                    <Button onClick={handleSpin} className={`${styles.settingContainer} ${styles[theme]}`}>
                                        <motion.div
                                            style={{ display: "flex", transformOrigin: "center", justifyContent: "center", alignItems: "center" }}
                                            animate={{ rotate: spinnerState.rotation }}
                                            transition={{ duration: 0.5 }}
                                        >
                                            <SettingsIcon className={`${styles.settingIcon} ${styles[theme]}`} />
                                        </motion.div>
                                    </Button>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            activePageData?.id !== 2 && (
                                <div className={` ${styles[theme]}`} >
                                    <motion.div
                                        className={`${styles.collapsableSidebar} ${styles[theme]}`}
                                        animate={controls}
                                        variants={{
                                            open: { width: "auto" },
                                            closed: { width: 0 }
                                        }}
                                    >
                                        <div className={`${styles.collapsableSidebarContent} ${styles[theme]}`} >
                                            <div>
                                                {activePageSideBar}
                                            </div>
                                        </div>
                                    </motion.div>
                                </div>
                            )}
                    </div>
                    {
                        userDialogBox && Account && (
                            <motion.div
                                className={`${styles.userProfile} ${styles[theme]}`}
                            >
                                <UserProfile
                                    userDialogBox={userDialogBox}
                                />
                            </motion.div>
                        )
                    }
                </div>
            </div>
            {sideBarIndex &&
                <div className={`${styles.mobileViewMenu} ${styles[theme]}`}>
                    {analyticsComponents.map((obj, index) => {
                        return (
                            <div className={currentActivePage === obj.id ? `${styles.clickedStylembl} ${styles[theme]}` : `${styles.hoverStylembl} ${styles[theme]}`}>
                                <div className={`${styles.sideBarItem} ${styles[theme]}`} key={index} onClick={() => {
                                    dispatch(setcurrentActivePage(obj.id));
                                }}>
                                    <div className={`${styles.iconStyle} ${styles[theme]}`}>{obj.name}</div>

                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </>
    )

}

export default MenuBar