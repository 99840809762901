import React , {SVGProps ,useContext } from "react"
import { ThemeContext } from '../../Context/ThemeContext'

const HomeIconOutlinedDarkTheme = (props: SVGProps<SVGSVGElement>) => (
    <div>
        <svg

            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="HomeIconOutlined">
                <path
                    id="Pentagon"
                    d="M1.69296 22.5178C1.89496 23.9321 3.1262 25 4.54983 25H20.4502C21.8738 25 23.105 23.9321 23.307 22.5178L24.9615 10.9342C24.9904 10.7322 25 10.5301 25 10.3281C25 8.9619 24.3459 7.6727 23.2397 6.86454L14.8134 0.736001C13.4667 -0.245334 11.5525 -0.245334 10.2058 0.736001L1.76991 6.87416C0.654098 7.6727 0 8.97152 0 10.3377C0 10.5397 0.0192381 10.7418 0.0384763 10.9438L1.69296 22.5178ZM2.89534 8.43275L11.3313 2.29459C11.6776 2.04445 12.0816 1.90976 12.5048 1.90976C12.9281 1.90976 13.3321 2.04445 13.6783 2.29459L22.1143 8.43275C22.7203 8.87531 23.0858 9.58726 23.0858 10.3377C23.0858 10.4531 23.0762 10.559 23.0666 10.6744L21.4121 22.258C21.3447 22.7295 20.9311 23.0854 20.4598 23.0854H4.54983C4.07849 23.0854 3.66487 22.7295 3.59754 22.258L1.94306 10.6648C1.93344 10.559 1.92382 10.4435 1.92382 10.3281C1.92382 9.58726 2.28934 8.87531 2.89534 8.43275Z"
                    fill="#F5F5F5"
                />
                <path
                    id="InnerLine"
                    d="M9.61913 21.1517H15.3906C15.9196 21.1517 16.3525 20.7188 16.3525 20.1896C16.3525 19.6605 15.9196 19.2275 15.3906 19.2275H9.61913C9.09009 19.2275 8.65723 19.6605 8.65723 20.1896C8.65723 20.7188 9.09009 21.1517 9.61913 21.1517Z"
                    fill="#F5F5F5"
                />
            </g>
        </svg>
    </div>
)

const HomeIconOutlinedLightTheme = (props: SVGProps<SVGSVGElement>) => (
    <div>
        <svg

            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="HomeIconOutlined">
                <path
                    id="Pentagon"
                    d="M1.69296 22.5178C1.89496 23.9321 3.1262 25 4.54983 25H20.4502C21.8738 25 23.105 23.9321 23.307 22.5178L24.9615 10.9342C24.9904 10.7322 25 10.5301 25 10.3281C25 8.9619 24.3459 7.6727 23.2397 6.86454L14.8134 0.736001C13.4667 -0.245334 11.5525 -0.245334 10.2058 0.736001L1.76991 6.87416C0.654098 7.6727 0 8.97152 0 10.3377C0 10.5397 0.0192381 10.7418 0.0384763 10.9438L1.69296 22.5178ZM2.89534 8.43275L11.3313 2.29459C11.6776 2.04445 12.0816 1.90976 12.5048 1.90976C12.9281 1.90976 13.3321 2.04445 13.6783 2.29459L22.1143 8.43275C22.7203 8.87531 23.0858 9.58726 23.0858 10.3377C23.0858 10.4531 23.0762 10.559 23.0666 10.6744L21.4121 22.258C21.3447 22.7295 20.9311 23.0854 20.4598 23.0854H4.54983C4.07849 23.0854 3.66487 22.7295 3.59754 22.258L1.94306 10.6648C1.93344 10.559 1.92382 10.4435 1.92382 10.3281C1.92382 9.58726 2.28934 8.87531 2.89534 8.43275Z"
                    fill="#28282b"
                />
                <path
                    id="InnerLine"
                    d="M9.61913 21.1517H15.3906C15.9196 21.1517 16.3525 20.7188 16.3525 20.1896C16.3525 19.6605 15.9196 19.2275 15.3906 19.2275H9.61913C9.09009 19.2275 8.65723 19.6605 8.65723 20.1896C8.65723 20.7188 9.09009 21.1517 9.61913 21.1517Z"
                    fill="#28282b"
                />
            </g>
        </svg>
    </div>
)

const HomeIconOutlinedSVG = () => {
    const { theme } = useContext(ThemeContext)
  
    return (
      <>
        {
          theme === "light"
            ?
            <HomeIconOutlinedLightTheme /> : <HomeIconOutlinedDarkTheme />}
      </>
    )
  }



export default HomeIconOutlinedSVG 
