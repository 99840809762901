export type GraphData = {
  displayName: string;
  jobTitle: string;
  mail: string;
  businessPhones: string[];
  officeLocation: string;
};

export async function GetUserDetails(AToken: string) {
  const ReaderAccessToken = AToken;

  return fetch("https://graph.microsoft.com/v1.0/me", {
    headers: {
      Authorization: `Bearer ${ReaderAccessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(`error while fetching user details!!! \n ${error}`);
    });
}

export async function GetUserDisplayPicture(AToken: string) {

  const ReaderAccessToken = AToken;



  const FetchImage = await fetch(
    "https://graph.microsoft.com/v1.0/me/photo/$value",
    {
      headers: {
        Authorization: `Bearer ${ReaderAccessToken}`,
      },
    }
  );
  if (FetchImage.ok) {
    const ImageBlob = await FetchImage.blob();
    return URL.createObjectURL(ImageBlob);
  } else {
    return " ";
  }
}