import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  PublicClientApplication,
  EventMessage,
  EventType,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./Config";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "../src/Context/ThemeContext";

export const msalInstance = new PublicClientApplication(msalConfig); // To create the instance of Application using credentials
const accounts = msalInstance.getAllAccounts(); // Get the list of accounts currently logged-in in this app

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]); //if any account already logged-in means make first account as Active account
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error?.message.includes("user_cancelled")) {
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (window.location.hash !== "") {
} else {
  root.render(
    <Router>
      <React.StrictMode>
        <ThemeProvider>
          <App pca={msalInstance} />
        </ThemeProvider>
      </React.StrictMode>
    </Router>
  );
}
