import React , {SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg

        viewBox="0 0 66 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.22038 0V1.4311C8.22038 12.3944 8.26906 23.3533 8.19648 34.3166C8.16727 38.6195 10.4958 41.4045 13.7927 42.1297C17.6409 42.9752 21.2182 41.4238 22.9565 37.6924C24.7815 33.774 26.4277 29.7739 28.0199 25.7546C29.9705 20.8326 33.4851 18.1381 38.8201 17.6342C42.3294 17.3032 45.6943 17.7158 48.715 19.4832C52.6314 21.7782 54.4953 27.0276 51.3436 30.8161C50.6267 31.6756 49.601 32.2805 48.6566 33.054C49.3779 33.5729 50.1815 34.1296 50.9657 34.7108C54.5626 37.3711 54.5192 42.9848 51.5463 46.0534C48.0512 49.6601 43.6269 50.4283 38.8582 50.2123C34.8011 50.0297 31.6211 48.229 29.0793 45.1552C28.4447 44.387 28.4164 43.7531 28.9146 42.8979C30.0041 41.0199 30.9866 39.0752 31.9548 37.2648C33.1992 38.6002 34.3798 40.1218 35.8225 41.3562C38.136 43.3395 41.1425 43.6899 43.2631 41.8944C44.996 40.4299 45.3784 36.9145 42.2559 36.3289C41.2779 36.1463 40.2761 36.0356 39.2839 36.0163C37.5749 35.983 37.5704 36.0207 37.5651 34.3552C37.5651 33.3947 37.5793 32.4342 37.5749 31.4737C37.5749 30.8161 37.8652 30.6089 38.5431 30.6001C39.4919 30.5808 40.4646 30.4798 41.385 30.2445C42.74 29.8986 43.5729 28.7221 43.5587 27.4306C43.549 26.3788 42.7117 25.5096 41.3363 25.1207C39.26 24.535 36.9412 25.212 35.6579 27.3156C34.0745 29.9039 32.7097 32.6554 31.5335 35.4553C29.7519 39.6863 27.7579 43.725 24.4027 46.9946C20.835 50.4713 13.5351 51.9024 8.70362 49.2518C3.07823 46.1684 0.100917 41.4484 0.0433892 35.0804C-0.0389206 25.7159 0.0141825 16.3567 0.0531248 6.9922C0.0531248 6.48298 0.333686 5.80167 0.721338 5.48911C2.88529 3.74632 5.12181 2.08432 7.34329 0.403869C7.55128 0.245833 7.82742 0.178229 8.21949 0.000877829L8.22038 0Z"
            fill="url(#paint0_linear_7219_1962)"
        />
        <path
            d="M24.5974 31.3251C21.5334 24.2565 14.7415 22.1722 9.1356 28.4629V26.7482C9.1356 25.1924 9.22234 23.627 9.1117 22.0756C9.00019 20.5049 9.81886 19.569 11.0482 18.9395C14.722 17.0668 18.4888 17.2345 22.2352 18.7086C24.5585 19.6261 26.2534 21.2635 27.3181 23.5111C27.4774 23.8421 27.6084 24.3127 27.4924 24.6296C26.7127 26.7526 25.871 28.851 25.0329 30.9449C24.9701 31.0986 24.7568 31.1899 24.5974 31.3198V31.3242V31.3251Z"
            fill="url(#paint1_linear_7219_1962)"
        />
        <path
            d="M27.8023 18.9834C25.1781 18.9738 23.3585 17.1537 23.3682 14.5461C23.3779 11.9526 25.2127 10.1089 27.7979 10.0948C30.4654 10.0808 32.3001 11.9245 32.2851 14.5997C32.2709 17.2117 30.4504 18.994 27.8023 18.9843V18.9834Z"
            fill="url(#paint2_linear_7219_1962)"
        />
        <path
            d="M52.5286 25.9654H55.4378V22.2129L51.3647 20.1927L53.6915 16.73L65.3291 22.5018L60.6746 24.8109L56.6016 22.7907V27.12H52.5286V28.2736H51.3647V24.8109H52.5286V25.9654Z"
            fill="url(#paint3_linear_7219_1962)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_7219_1962"
                x1={-18.4623}
                y1={53.053}
                x2={111.645}
                y2={52.6575}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.474542} stopColor="#D9D9D9" />
                <stop offset={1} stopColor="#181818" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="paint1_linear_7219_1962"
                x1={2.76631}
                y1={31.9855}
                x2={47.4117}
                y2={31.8142}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.474542} stopColor="#D9D9D9" />
                <stop offset={1} stopColor="#181818" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="paint2_linear_7219_1962"
                x1={20.3038}
                y1={19.4111}
                x2={41.8989}
                y2={19.3491}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.474542} stopColor="#D9D9D9" />
                <stop offset={1} stopColor="#181818" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="paint3_linear_7219_1962"
                x1={46.5659}
                y1={28.8278}
                x2={80.3843}
                y2={28.7106}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.474542} stopColor="#D9D9D9" />
                <stop offset={1} stopColor="#181818" stopOpacity={0} />
            </linearGradient>
        </defs>
    </svg>
)
export default SvgComponent
