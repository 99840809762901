import React , {SVGProps ,useContext } from "react"
import { ThemeContext } from '../../Context/ThemeContext'

const ServerIconFilledDarkTheme = (props: SVGProps<SVGSVGElement>) => (
    <svg
    
        viewBox="0 0 31 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Group 327">
            <g id="Vector">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.36287 0.133803C5.39225 0.133803 5.42207 0.133818 5.45234 0.133818H25.5476C25.5779 0.133818 25.6077 0.133803 25.6371 0.133803C26.269 0.133648 26.6996 0.13354 27.0743 0.208078C28.6075 0.513 29.8057 1.71137 30.1107 3.24436C30.1852 3.61906 30.1851 4.04978 30.1849 4.6817C30.1849 4.71108 30.1849 4.7409 30.1849 4.77118C30.1849 4.80145 30.1849 4.83128 30.1849 4.86067C30.1851 5.49257 30.1852 5.92331 30.1107 6.29799C29.8057 7.831 28.6075 9.02935 27.0743 9.33429C26.6996 9.40883 26.269 9.40872 25.6371 9.40857C25.6077 9.40855 25.5779 9.40855 25.5476 9.40855H5.45234C5.42207 9.40855 5.39225 9.40855 5.36287 9.40857C4.73095 9.40872 4.30021 9.40883 3.92552 9.33429C2.39253 9.02935 1.19415 7.831 0.88923 6.29799C0.814692 5.92329 0.814801 5.49257 0.814955 4.86065C0.814955 4.83127 0.814971 4.80145 0.814971 4.77118C0.814971 4.74092 0.814955 4.7111 0.814955 4.68171C0.814801 4.0498 0.814692 3.61906 0.88923 3.24436C1.19415 1.71137 2.39253 0.513 3.92552 0.208078C4.30021 0.13354 4.73095 0.133648 5.36287 0.133803ZM5.45234 1.67961C4.69703 1.67961 4.43226 1.68335 4.22708 1.72416C3.30729 1.90712 2.58827 2.62614 2.40531 3.54593C2.3645 3.7511 2.36076 4.01588 2.36076 4.77118C2.36076 5.52649 2.3645 5.79127 2.40531 5.99642C2.58827 6.91623 3.30729 7.63525 4.22708 7.81821C4.43226 7.85902 4.69703 7.86276 5.45234 7.86276H25.5476C26.3028 7.86276 26.5676 7.85902 26.7729 7.81821C27.6927 7.63525 28.4116 6.91623 28.5946 5.99642C28.6354 5.79127 28.6391 5.52649 28.6391 4.77118C28.6391 4.01588 28.6354 3.7511 28.5946 3.54593C28.4116 2.62614 27.6927 1.90712 26.7729 1.72416C26.5676 1.68335 26.3028 1.67961 25.5476 1.67961H5.45234Z"
                    fill="#F5F5F5"
                />
                <path
                    d="M5.45234 1.67961C4.69703 1.67961 4.43226 1.68335 4.22708 1.72416C3.30729 1.90712 2.58827 2.62614 2.40531 3.54593C2.3645 3.7511 2.36076 4.01588 2.36076 4.77118C2.36076 5.52649 2.3645 5.79127 2.40531 5.99642C2.58827 6.91623 3.30729 7.63525 4.22708 7.81821C4.43226 7.85902 4.69703 7.86276 5.45234 7.86276H25.5476C26.3028 7.86276 26.5676 7.85902 26.7729 7.81821C27.6927 7.63525 28.4116 6.91623 28.5946 5.99642C28.6354 5.79127 28.6391 5.52649 28.6391 4.77118C28.6391 4.01588 28.6354 3.7511 28.5946 3.54593C28.4116 2.62614 27.6927 1.90712 26.7729 1.72416C26.5676 1.68335 26.3028 1.67961 25.5476 1.67961H5.45234Z"
                    fill="#F5F5F5"
                />
            </g>
            <g id="Vector_2">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.36287 7.86281C5.39225 7.86281 5.42207 7.86282 5.45234 7.86282H25.5476C25.5779 7.86282 25.6077 7.86281 25.6371 7.86281C26.269 7.86265 26.6996 7.86255 27.0743 7.93708C28.6075 8.24201 29.8057 9.44038 30.1107 10.9734C30.1852 11.3481 30.1851 11.7787 30.1849 12.4107C30.1849 12.44 30.1849 12.4699 30.1849 12.5002C30.1849 12.5305 30.1849 12.5603 30.1849 12.5897C30.1851 13.2216 30.1852 13.6522 30.1107 14.0269C29.8057 15.56 28.6075 16.7583 27.0743 17.0633C26.6996 17.1378 26.269 17.1377 25.6371 17.1375C25.6077 17.1375 25.5779 17.1375 25.5476 17.1375H5.45234C5.42207 17.1375 5.39225 17.1375 5.36287 17.1375C4.73095 17.1377 4.30021 17.1378 3.92552 17.0633C2.39253 16.7583 1.19415 15.56 0.88923 14.0269C0.814692 13.6522 0.814801 13.2216 0.814955 12.5897C0.814955 12.5603 0.814971 12.5305 0.814971 12.5002C0.814971 12.4699 0.814955 12.44 0.814955 12.4107C0.814801 11.7787 0.814692 11.3481 0.88923 10.9734C1.19415 9.44038 2.39253 8.24201 3.92552 7.93708C4.30021 7.86255 4.73095 7.86265 5.36287 7.86281ZM5.45234 9.40861C4.69703 9.40861 4.43226 9.41235 4.22708 9.45316C3.30729 9.63612 2.58827 10.3551 2.40531 11.275C2.3645 11.4801 2.36076 11.7449 2.36076 12.5002C2.36076 13.2554 2.3645 13.5202 2.40531 13.7255C2.58827 14.6452 3.30729 15.3642 4.22708 15.5472C4.43226 15.588 4.69703 15.5917 5.45234 15.5917H25.5476C26.3028 15.5917 26.5676 15.588 26.7729 15.5472C27.6927 15.3642 28.4116 14.6452 28.5946 13.7255C28.6354 13.5202 28.6391 13.2554 28.6391 12.5002C28.6391 11.7449 28.6354 11.4801 28.5946 11.275C28.4116 10.3551 27.6927 9.63612 26.7729 9.45316C26.5676 9.41235 26.3028 9.40861 25.5476 9.40861H5.45234Z"
                    fill="#F5F5F5"
                />
                <path
                    d="M5.45234 9.40861C4.69703 9.40861 4.43226 9.41235 4.22708 9.45316C3.30729 9.63612 2.58827 10.3551 2.40531 11.275C2.3645 11.4801 2.36076 11.7449 2.36076 12.5002C2.36076 13.2554 2.3645 13.5202 2.40531 13.7255C2.58827 14.6452 3.30729 15.3642 4.22708 15.5472C4.43226 15.588 4.69703 15.5917 5.45234 15.5917H25.5476C26.3028 15.5917 26.5676 15.588 26.7729 15.5472C27.6927 15.3642 28.4116 14.6452 28.5946 13.7255C28.6354 13.5202 28.6391 13.2554 28.6391 12.5002C28.6391 11.7449 28.6354 11.4801 28.5946 11.275C28.4116 10.3551 27.6927 9.63612 26.7729 9.45316C26.5676 9.41235 26.3028 9.40861 25.5476 9.40861H5.45234Z"
                    fill="#F5F5F5"
                />
            </g>
            <g id="Vector_3">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.36287 15.5916C5.39225 15.5916 5.42207 15.5916 5.45234 15.5916H25.5476C25.5779 15.5916 25.6077 15.5916 25.6371 15.5916C26.269 15.5914 26.6996 15.5913 27.0743 15.6659C28.6075 15.9707 29.8057 17.1692 30.1107 18.7022C30.1852 19.0769 30.1851 19.5075 30.1849 20.1394C30.1849 20.1688 30.1849 20.1986 30.1849 20.2289C30.1849 20.2592 30.1849 20.2891 30.1849 20.3184C30.1851 20.9504 30.1852 21.381 30.1107 21.7557C29.8057 23.2888 28.6075 24.4871 27.0743 24.7921C26.6996 24.8666 26.269 24.8665 25.6371 24.8663C25.6077 24.8663 25.5779 24.8663 25.5476 24.8663H5.45234C5.42207 24.8663 5.39225 24.8663 5.36287 24.8663C4.73095 24.8665 4.30021 24.8666 3.92552 24.7921C2.39253 24.4871 1.19415 23.2888 0.88923 21.7557C0.814692 21.381 0.814801 20.9504 0.814955 20.3184C0.814955 20.2891 0.814971 20.2592 0.814971 20.2289C0.814971 20.1986 0.814955 20.1688 0.814955 20.1394C0.814801 19.5075 0.814692 19.0769 0.88923 18.7022C1.19415 17.1692 2.39253 15.9707 3.92552 15.6659C4.30021 15.5913 4.73095 15.5914 5.36287 15.5916ZM5.45234 17.1374C4.69703 17.1374 4.43226 17.1411 4.22708 17.1819C3.30729 17.3649 2.58827 18.0838 2.40531 19.0037C2.3645 19.2089 2.36076 19.4737 2.36076 20.2289C2.36076 20.9842 2.3645 21.249 2.40531 21.4543C2.58827 22.374 3.30729 23.093 4.22708 23.276C4.43226 23.3168 4.69703 23.3205 5.45234 23.3205H25.5476C26.3028 23.3205 26.5676 23.3168 26.7729 23.276C27.6927 23.093 28.4116 22.374 28.5946 21.4543C28.6354 21.249 28.6391 20.9842 28.6391 20.2289C28.6391 19.4737 28.6354 19.2089 28.5946 19.0037C28.4116 18.0838 27.6927 17.3649 26.7729 17.1819C26.5676 17.1411 26.3028 17.1374 25.5476 17.1374H5.45234Z"
                    fill="#F5F5F5"
                />
                <path
                    d="M5.45234 17.1374C4.69703 17.1374 4.43226 17.1411 4.22708 17.1819C3.30729 17.3649 2.58827 18.0838 2.40531 19.0037C2.3645 19.2089 2.36076 19.4737 2.36076 20.2289C2.36076 20.9842 2.3645 21.249 2.40531 21.4543C2.58827 22.374 3.30729 23.093 4.22708 23.276C4.43226 23.3168 4.69703 23.3205 5.45234 23.3205H25.5476C26.3028 23.3205 26.5676 23.3168 26.7729 23.276C27.6927 23.093 28.4116 22.374 28.5946 21.4543C28.6354 21.249 28.6391 20.9842 28.6391 20.2289C28.6391 19.4737 28.6354 19.2089 28.5946 19.0037C28.4116 18.0838 27.6927 17.3649 26.7729 17.1819C26.5676 17.1411 26.3028 17.1374 25.5476 17.1374H5.45234Z"
                    fill="#F5F5F5"
                />
            </g>
            <path
                id="Vector_4"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.45215 3.99824C5.45215 3.57138 5.79819 3.22534 6.22504 3.22534H12.4082C12.835 3.22534 13.1811 3.57138 13.1811 3.99824C13.1811 4.42509 12.835 4.77113 12.4082 4.77113H6.22504C5.79819 4.77113 5.45215 4.42509 5.45215 3.99824Z"
                fill="#27292B"
            />
            <path
                id="Vector_5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4561 3.99824C22.4561 3.57138 22.8022 3.22534 23.2289 3.22534H24.7747C25.2015 3.22534 25.5476 3.57138 25.5476 3.99824C25.5476 4.42509 25.2015 4.77113 24.7747 4.77113H23.2289C22.8022 4.77113 22.4561 4.42509 22.4561 3.99824Z"
                fill="#27292B"
            />
            <path
                id="Vector_6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4561 11.7272C22.4561 11.3004 22.8022 10.9543 23.2289 10.9543H24.7747C25.2015 10.9543 25.5476 11.3004 25.5476 11.7272C25.5476 12.154 25.2015 12.5001 24.7747 12.5001H23.2289C22.8022 12.5001 22.4561 12.154 22.4561 11.7272Z"
                fill="#27292B"
            />
            <path
                id="Vector_7"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4561 19.4562C22.4561 19.0295 22.8022 18.6833 23.2289 18.6833H24.7747C25.2015 18.6833 25.5476 19.0295 25.5476 19.4562C25.5476 19.883 25.2015 20.2291 24.7747 20.2291H23.2289C22.8022 20.2291 22.4561 19.883 22.4561 19.4562Z"
                fill="#27292B"
            />
            <path
                id="Vector_8"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.45215 11.7272C5.45215 11.3004 5.79819 10.9543 6.22504 10.9543H12.4082C12.835 10.9543 13.1811 11.3004 13.1811 11.7272C13.1811 12.154 12.835 12.5001 12.4082 12.5001H6.22504C5.79819 12.5001 5.45215 12.154 5.45215 11.7272Z"
                fill="#27292B"
            />
            <path
                id="Vector_9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.45215 19.4562C5.45215 19.0295 5.79819 18.6833 6.22504 18.6833H12.4082C12.835 18.6833 13.1811 19.0295 13.1811 19.4562C13.1811 19.883 12.835 20.2291 12.4082 20.2291H6.22504C5.79819 20.2291 5.45215 19.883 5.45215 19.4562Z"
                fill="#27292B"
            />
        </g>
    </svg>
)

const ServerIconFilledLightTheme = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 31 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Group 327">
            <g id="Vector">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.36287 0.133803C5.39225 0.133803 5.42207 0.133818 5.45234 0.133818H25.5476C25.5779 0.133818 25.6077 0.133803 25.6371 0.133803C26.269 0.133648 26.6996 0.13354 27.0743 0.208078C28.6075 0.513 29.8057 1.71137 30.1107 3.24436C30.1852 3.61906 30.1851 4.04978 30.1849 4.6817C30.1849 4.71108 30.1849 4.7409 30.1849 4.77118C30.1849 4.80145 30.1849 4.83128 30.1849 4.86067C30.1851 5.49257 30.1852 5.92331 30.1107 6.29799C29.8057 7.831 28.6075 9.02935 27.0743 9.33429C26.6996 9.40883 26.269 9.40872 25.6371 9.40857C25.6077 9.40855 25.5779 9.40855 25.5476 9.40855H5.45234C5.42207 9.40855 5.39225 9.40855 5.36287 9.40857C4.73095 9.40872 4.30021 9.40883 3.92552 9.33429C2.39253 9.02935 1.19415 7.831 0.88923 6.29799C0.814692 5.92329 0.814801 5.49257 0.814955 4.86065C0.814955 4.83127 0.814971 4.80145 0.814971 4.77118C0.814971 4.74092 0.814955 4.7111 0.814955 4.68171C0.814801 4.0498 0.814692 3.61906 0.88923 3.24436C1.19415 1.71137 2.39253 0.513 3.92552 0.208078C4.30021 0.13354 4.73095 0.133648 5.36287 0.133803ZM5.45234 1.67961C4.69703 1.67961 4.43226 1.68335 4.22708 1.72416C3.30729 1.90712 2.58827 2.62614 2.40531 3.54593C2.3645 3.7511 2.36076 4.01588 2.36076 4.77118C2.36076 5.52649 2.3645 5.79127 2.40531 5.99642C2.58827 6.91623 3.30729 7.63525 4.22708 7.81821C4.43226 7.85902 4.69703 7.86276 5.45234 7.86276H25.5476C26.3028 7.86276 26.5676 7.85902 26.7729 7.81821C27.6927 7.63525 28.4116 6.91623 28.5946 5.99642C28.6354 5.79127 28.6391 5.52649 28.6391 4.77118C28.6391 4.01588 28.6354 3.7511 28.5946 3.54593C28.4116 2.62614 27.6927 1.90712 26.7729 1.72416C26.5676 1.68335 26.3028 1.67961 25.5476 1.67961H5.45234Z"
                    fill="#28282b"
                />
                <path
                    d="M5.45234 1.67961C4.69703 1.67961 4.43226 1.68335 4.22708 1.72416C3.30729 1.90712 2.58827 2.62614 2.40531 3.54593C2.3645 3.7511 2.36076 4.01588 2.36076 4.77118C2.36076 5.52649 2.3645 5.79127 2.40531 5.99642C2.58827 6.91623 3.30729 7.63525 4.22708 7.81821C4.43226 7.85902 4.69703 7.86276 5.45234 7.86276H25.5476C26.3028 7.86276 26.5676 7.85902 26.7729 7.81821C27.6927 7.63525 28.4116 6.91623 28.5946 5.99642C28.6354 5.79127 28.6391 5.52649 28.6391 4.77118C28.6391 4.01588 28.6354 3.7511 28.5946 3.54593C28.4116 2.62614 27.6927 1.90712 26.7729 1.72416C26.5676 1.68335 26.3028 1.67961 25.5476 1.67961H5.45234Z"
                    fill="#343434"
                />
            </g>
            <g id="Vector_2">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.36287 7.86281C5.39225 7.86281 5.42207 7.86282 5.45234 7.86282H25.5476C25.5779 7.86282 25.6077 7.86281 25.6371 7.86281C26.269 7.86265 26.6996 7.86255 27.0743 7.93708C28.6075 8.24201 29.8057 9.44038 30.1107 10.9734C30.1852 11.3481 30.1851 11.7787 30.1849 12.4107C30.1849 12.44 30.1849 12.4699 30.1849 12.5002C30.1849 12.5305 30.1849 12.5603 30.1849 12.5897C30.1851 13.2216 30.1852 13.6522 30.1107 14.0269C29.8057 15.56 28.6075 16.7583 27.0743 17.0633C26.6996 17.1378 26.269 17.1377 25.6371 17.1375C25.6077 17.1375 25.5779 17.1375 25.5476 17.1375H5.45234C5.42207 17.1375 5.39225 17.1375 5.36287 17.1375C4.73095 17.1377 4.30021 17.1378 3.92552 17.0633C2.39253 16.7583 1.19415 15.56 0.88923 14.0269C0.814692 13.6522 0.814801 13.2216 0.814955 12.5897C0.814955 12.5603 0.814971 12.5305 0.814971 12.5002C0.814971 12.4699 0.814955 12.44 0.814955 12.4107C0.814801 11.7787 0.814692 11.3481 0.88923 10.9734C1.19415 9.44038 2.39253 8.24201 3.92552 7.93708C4.30021 7.86255 4.73095 7.86265 5.36287 7.86281ZM5.45234 9.40861C4.69703 9.40861 4.43226 9.41235 4.22708 9.45316C3.30729 9.63612 2.58827 10.3551 2.40531 11.275C2.3645 11.4801 2.36076 11.7449 2.36076 12.5002C2.36076 13.2554 2.3645 13.5202 2.40531 13.7255C2.58827 14.6452 3.30729 15.3642 4.22708 15.5472C4.43226 15.588 4.69703 15.5917 5.45234 15.5917H25.5476C26.3028 15.5917 26.5676 15.588 26.7729 15.5472C27.6927 15.3642 28.4116 14.6452 28.5946 13.7255C28.6354 13.5202 28.6391 13.2554 28.6391 12.5002C28.6391 11.7449 28.6354 11.4801 28.5946 11.275C28.4116 10.3551 27.6927 9.63612 26.7729 9.45316C26.5676 9.41235 26.3028 9.40861 25.5476 9.40861H5.45234Z"
                    fill="#28282b"
                />
                <path
                    d="M5.45234 9.40861C4.69703 9.40861 4.43226 9.41235 4.22708 9.45316C3.30729 9.63612 2.58827 10.3551 2.40531 11.275C2.3645 11.4801 2.36076 11.7449 2.36076 12.5002C2.36076 13.2554 2.3645 13.5202 2.40531 13.7255C2.58827 14.6452 3.30729 15.3642 4.22708 15.5472C4.43226 15.588 4.69703 15.5917 5.45234 15.5917H25.5476C26.3028 15.5917 26.5676 15.588 26.7729 15.5472C27.6927 15.3642 28.4116 14.6452 28.5946 13.7255C28.6354 13.5202 28.6391 13.2554 28.6391 12.5002C28.6391 11.7449 28.6354 11.4801 28.5946 11.275C28.4116 10.3551 27.6927 9.63612 26.7729 9.45316C26.5676 9.41235 26.3028 9.40861 25.5476 9.40861H5.45234Z"
                    fill="#343434"
                />
            </g>
            <g id="Vector_3">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.36287 15.5916C5.39225 15.5916 5.42207 15.5916 5.45234 15.5916H25.5476C25.5779 15.5916 25.6077 15.5916 25.6371 15.5916C26.269 15.5914 26.6996 15.5913 27.0743 15.6659C28.6075 15.9707 29.8057 17.1692 30.1107 18.7022C30.1852 19.0769 30.1851 19.5075 30.1849 20.1394C30.1849 20.1688 30.1849 20.1986 30.1849 20.2289C30.1849 20.2592 30.1849 20.2891 30.1849 20.3184C30.1851 20.9504 30.1852 21.381 30.1107 21.7557C29.8057 23.2888 28.6075 24.4871 27.0743 24.7921C26.6996 24.8666 26.269 24.8665 25.6371 24.8663C25.6077 24.8663 25.5779 24.8663 25.5476 24.8663H5.45234C5.42207 24.8663 5.39225 24.8663 5.36287 24.8663C4.73095 24.8665 4.30021 24.8666 3.92552 24.7921C2.39253 24.4871 1.19415 23.2888 0.88923 21.7557C0.814692 21.381 0.814801 20.9504 0.814955 20.3184C0.814955 20.2891 0.814971 20.2592 0.814971 20.2289C0.814971 20.1986 0.814955 20.1688 0.814955 20.1394C0.814801 19.5075 0.814692 19.0769 0.88923 18.7022C1.19415 17.1692 2.39253 15.9707 3.92552 15.6659C4.30021 15.5913 4.73095 15.5914 5.36287 15.5916ZM5.45234 17.1374C4.69703 17.1374 4.43226 17.1411 4.22708 17.1819C3.30729 17.3649 2.58827 18.0838 2.40531 19.0037C2.3645 19.2089 2.36076 19.4737 2.36076 20.2289C2.36076 20.9842 2.3645 21.249 2.40531 21.4543C2.58827 22.374 3.30729 23.093 4.22708 23.276C4.43226 23.3168 4.69703 23.3205 5.45234 23.3205H25.5476C26.3028 23.3205 26.5676 23.3168 26.7729 23.276C27.6927 23.093 28.4116 22.374 28.5946 21.4543C28.6354 21.249 28.6391 20.9842 28.6391 20.2289C28.6391 19.4737 28.6354 19.2089 28.5946 19.0037C28.4116 18.0838 27.6927 17.3649 26.7729 17.1819C26.5676 17.1411 26.3028 17.1374 25.5476 17.1374H5.45234Z"
                    fill="#28282b"
                />
                <path
                    d="M5.45234 17.1374C4.69703 17.1374 4.43226 17.1411 4.22708 17.1819C3.30729 17.3649 2.58827 18.0838 2.40531 19.0037C2.3645 19.2089 2.36076 19.4737 2.36076 20.2289C2.36076 20.9842 2.3645 21.249 2.40531 21.4543C2.58827 22.374 3.30729 23.093 4.22708 23.276C4.43226 23.3168 4.69703 23.3205 5.45234 23.3205H25.5476C26.3028 23.3205 26.5676 23.3168 26.7729 23.276C27.6927 23.093 28.4116 22.374 28.5946 21.4543C28.6354 21.249 28.6391 20.9842 28.6391 20.2289C28.6391 19.4737 28.6354 19.2089 28.5946 19.0037C28.4116 18.0838 27.6927 17.3649 26.7729 17.1819C26.5676 17.1411 26.3028 17.1374 25.5476 17.1374H5.45234Z"
                    fill="#343434"
                />
            </g>
            <path
                id="Vector_4"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.45215 3.99824C5.45215 3.57138 5.79819 3.22534 6.22504 3.22534H12.4082C12.835 3.22534 13.1811 3.57138 13.1811 3.99824C13.1811 4.42509 12.835 4.77113 12.4082 4.77113H6.22504C5.79819 4.77113 5.45215 4.42509 5.45215 3.99824Z"
                fill="#ffffff"
            />
            <path
                id="Vector_5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4561 3.99824C22.4561 3.57138 22.8022 3.22534 23.2289 3.22534H24.7747C25.2015 3.22534 25.5476 3.57138 25.5476 3.99824C25.5476 4.42509 25.2015 4.77113 24.7747 4.77113H23.2289C22.8022 4.77113 22.4561 4.42509 22.4561 3.99824Z"
                fill="#ffffff"
            />
            <path
                id="Vector_6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4561 11.7272C22.4561 11.3004 22.8022 10.9543 23.2289 10.9543H24.7747C25.2015 10.9543 25.5476 11.3004 25.5476 11.7272C25.5476 12.154 25.2015 12.5001 24.7747 12.5001H23.2289C22.8022 12.5001 22.4561 12.154 22.4561 11.7272Z"
                fill="#ffffff"
            />
            <path
                id="Vector_7"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4561 19.4562C22.4561 19.0295 22.8022 18.6833 23.2289 18.6833H24.7747C25.2015 18.6833 25.5476 19.0295 25.5476 19.4562C25.5476 19.883 25.2015 20.2291 24.7747 20.2291H23.2289C22.8022 20.2291 22.4561 19.883 22.4561 19.4562Z"
                fill="#ffffff"
            />
            <path
                id="Vector_8"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.45215 11.7272C5.45215 11.3004 5.79819 10.9543 6.22504 10.9543H12.4082C12.835 10.9543 13.1811 11.3004 13.1811 11.7272C13.1811 12.154 12.835 12.5001 12.4082 12.5001H6.22504C5.79819 12.5001 5.45215 12.154 5.45215 11.7272Z"
                fill="#ffffff"
            />
            <path
                id="Vector_9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.45215 19.4562C5.45215 19.0295 5.79819 18.6833 6.22504 18.6833H12.4082C12.835 18.6833 13.1811 19.0295 13.1811 19.4562C13.1811 19.883 12.835 20.2291 12.4082 20.2291H6.22504C5.79819 20.2291 5.45215 19.883 5.45215 19.4562Z"
                fill="white"
            />
        </g>
    </svg>
)


const ServerIconFilledSVG = () => {
    const { theme } = useContext(ThemeContext)
  
    return (
      <>
        {
          theme === "light"
  
            ?
            <ServerIconFilledLightTheme /> : <ServerIconFilledDarkTheme />}
      </>
    )
  }
  
export default ServerIconFilledSVG

