import React from "react";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import LogIn from "./pages/LogIn";
import "./App.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { ThemeContext } from "./Context/ThemeContext";
import { useContext, useEffect } from "react";
import { lightTheme, darkTheme } from "./Theme";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux'
import store from '../src/Store/store'
import MenuBar from "./Components/MenuBar";
import styles from '../src/styles/Application.module.scss'

const LazyLandingPage = React.lazy(() => import("./pages/VideoGrid"));
const LazyServerPage = React.lazy(() => import("./pages/ServerPage"));
const LazyAnalyticsPage = React.lazy(() => import("./pages/AnalyticsPage"));

type AppProps = {
  pca: PublicClientApplication;
};

function App({ pca }: AppProps) {
  const { theme } = useContext(ThemeContext);

  const selectedTheme = theme === "light" ? lightTheme : darkTheme;

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      // Disable common developer tools shortcuts
      if (event.key === 'I' && (event.metaKey || event.ctrlKey) && event.shiftKey) {
        event.preventDefault();
      }
      if (event.key === 'U' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
      }
      if (event.key === 'J' && (event.metaKey || event.ctrlKey) && event.shiftKey) {
        event.preventDefault();
      }
      if (event.key === 'S' && (event.metaKey || event.ctrlKey) && event.shiftKey) {
        event.preventDefault();
      }
    };


    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="App">
      <div className={`${styles[theme]}`}>
        <Provider store={store}>
          <ThemeProvider theme={selectedTheme}>
            <CssBaseline />
            <MsalProvider instance={pca}>
              {/* Without login UnauthenticatedTemplate will login to the Login Page*/}
              <UnauthenticatedTemplate>
                <LogIn />
              </UnauthenticatedTemplate>

              {/* After Authenticated redirect to the respective page*/}
              <AuthenticatedTemplate>
                <div style={{ display: "flex", }} className={`${styles.wallpaper} ${styles[theme]}`}>
                  <div >
                    <MenuBar />
                  </div>
                  <div style={{ width: "100%" }}>
                    <AuthenticatedPage />
                  </div>
                </div>
                {/* <MenuBar />
              <AuthenticatedPage /> */}
              </AuthenticatedTemplate>
            </MsalProvider>
          </ThemeProvider>
        </Provider>
      </div>
    </div>
  );
}

function AuthenticatedPage() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<></>}>
            {" "}
            <div style={{}}>
              <LazyLandingPage />
            </div>
          </React.Suspense>
        }
      />

      <Route
        path="/server"
        element={
          <React.Suspense fallback={<></>}>
            {" "}
            <div style={{}}>
              <LazyServerPage />
            </div>

          </React.Suspense>
        }
      />

      <Route
        path="/analytics"
        element={
          <React.Suspense fallback={<></>}>
            {" "}
            <div style={{}}>
              <LazyAnalyticsPage />
            </div>

          </React.Suspense>
        }
      />
    </Routes>
  );
}


export default App;