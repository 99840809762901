import React from "react";
import styles from "../styles/Footer.module.scss";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

const Footer = () => {
  return (
    <div style={{ bottom : "0" }}>
      <div style={{ padding: "10px 0", }} className={styles.footerContent}>
          <div className={styles.languageStyle}>
            <LanguageOutlinedIcon className={styles.languageContent}/>
            <p  className={styles.menuItemStyle}>English (Australia)</p>
          </div>
          <p className={styles.menuItemStyle}> Contact Us </p>
          <p className={styles.menuItemStyle}> Privacy </p>
          <p className={styles.menuItemStyle}> Terms of Use </p>
          <p className={styles.menuItemStyle}> &copy; Bi3 Technologies 2023 </p>
      </div>
    </div>
  );
};

export default Footer;