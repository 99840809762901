import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";

type userDetails = {
  userName: string;
  userEmail: string;
  userProfileImage: any;
};

const initialUserDetails: userDetails = {
  userName: "",
  userEmail: "",
  userProfileImage: "",
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: initialUserDetails,
  reducers: {
    modifyUserDetails: (state, action: PayloadAction<userDetails>) => {
      return action.payload;
    },
  },
});

export const { modifyUserDetails } = userDetailsSlice.actions;
export const getUserDetails = (state: Rootstate) => state.UserdetailsSlice;
export default userDetailsSlice.reducer;
