import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";

type Filter = {
  startdate: string;
  enddate: string;
  starttime: string;
  endtime: string;
  mediasource: string;
  name: string;
};

const initialFilter: Filter = {
  startdate: "1900-01-01",
  enddate: "2999-12-31",
  starttime: "00:00:00",
  endtime: "23:59:59",
  mediasource: "",
  name: "",
};

const filterSlice = createSlice({
  name: "filters",
  initialState: initialFilter,
  reducers: {
    modifyFilter: (_, action: PayloadAction<Filter>) => {
      return action.payload;
    },
  },
});

export const { modifyFilter } = filterSlice.actions;

export const getFiltersSelector = (state: Rootstate) => state.filters;
export const initialFilterValue = initialFilter;;

export default filterSlice.reducer;
