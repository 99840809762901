import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";

const initialState: boolean = false;

const SideBarNavigation = createSlice({
    name: "SideBarNavigation",
    initialState,
    reducers: {
        setIndex: (_, action: PayloadAction<boolean>) => {
            return action.payload;
        },
    }
})

export const { setIndex } = SideBarNavigation.actions;

export const getIndex = (state: Rootstate) => state.SideBarNavigationSlice;


export default SideBarNavigation.reducer;
