import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";

const initialState: number = 1;

const PageNavigation = createSlice({
    name: "PageNavigation",
    initialState,
    reducers: {
        setcurrentPage: (_, action: PayloadAction<number>) => {
            return action.payload;
        },
    }
})

export const { setcurrentPage } = PageNavigation.actions;

export const getcurrentPage = (state: Rootstate) => state.PageNavigation;


export default PageNavigation.reducer;
