import React , {SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
    
        viewBox="0 0 591 456"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Illustration_2" clipPath="url(#clip0_524_26)">
            <g id="Group">
                <g id="Capa 1">
                    <g id="Group_2">
                        <g
                            id="Vector"
                            style={{
                                mixBlendMode: "multiply",
                            }}
                        >
                            <path
                                d="M591 414.292C591 437.33 459.028 456 296.221 456C133.413 456 1.44116 437.33 1.44116 414.292C1.44116 391.254 133.413 372.568 296.221 372.568C459.028 372.568 591 391.254 591 414.292Z"
                                fill="#F8F9FC"
                                style={{
                                    mixBlendMode: "overlay",
                                }}
                            />
                        </g>
                        <g id="Group_3">
                            <g id="Group_4">
                                <path
                                    id="Vector_2"
                                    d="M474.37 385.491C474.37 390.563 470.197 394.676 465.033 394.676H127.393C122.229 394.676 118.056 390.563 118.056 385.491V155.217C118.056 150.144 122.229 146.032 127.393 146.032H465.033C470.182 146.032 474.37 150.144 474.37 155.217V385.491Z"
                                    fill="#00B1DE"
                                />
                                <path
                                    id="Vector_3"
                                    d="M461.37 157.618H130.291V361.072H461.37V157.618Z"
                                    fill="#231E4C"
                                />
                            </g>
                            <g id="Group_5" opacity={0.3}>
                                <path
                                    id="Vector_4"
                                    d="M438.642 157.618H399.475L180.987 361.072H224.358L438.642 157.618Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_5"
                                    d="M383.276 157.618H329.953C329.953 157.618 130.486 336.158 130.291 336.158V361.072H160.781L383.276 157.618Z"
                                    fill="#ABDFF9"
                                />
                            </g>
                            <path
                                id="Vector_6"
                                d="M490.388 391.884C490.388 394.811 488.377 397.182 485.885 397.182H106.557C104.065 397.182 102.053 394.811 102.053 391.884V379.517C102.053 376.591 104.08 374.219 106.557 374.219H485.885C488.377 374.219 490.388 376.591 490.388 379.517V391.884Z"
                                fill="#ABDFF9"
                            />
                        </g>
                        <g id="Group_6">
                            <path
                                id="Vector_7"
                                d="M86.1251 396.177C86.1251 396.987 85.4645 397.647 84.6539 397.647H26.7518C25.9412 397.647 25.2806 396.987 25.2806 396.177L22.3232 326.117C22.3232 325.307 22.9838 324.647 23.7944 324.647H87.5963C88.4069 324.647 89.0675 325.307 89.0675 326.117L86.1101 396.177H86.1251Z"
                                fill="#2D419A"
                            />
                            <path
                                id="Vector_8"
                                d="M18.1047 264.463C18.1047 264.463 12.0848 271.097 0 257.409C0 257.409 15.7328 254.302 18.465 263.547L18.1047 264.463Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_9"
                                d="M18.7653 263.517C18.7653 263.517 27.7126 263.623 25.9111 245.462C25.9111 245.462 12.9406 254.873 17.8495 263.157L18.7653 263.517Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_10"
                                d="M54.9146 220.654C54.9146 220.654 46.703 224.211 41.3286 206.771C41.3286 206.771 56.9413 210.418 55.6202 219.963L54.9146 220.654Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_11"
                                d="M56.4309 219.513C56.4309 219.513 64.5375 223.295 70.3773 206.005C70.3773 206.005 54.6745 209.232 55.7404 218.807L56.4159 219.513H56.4309Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_12"
                                d="M54.7044 235.347C54.7044 235.347 45.9673 239.129 40.2627 220.578C40.2627 220.578 56.8662 224.466 55.455 234.611L54.7044 235.347Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_13"
                                d="M56.3108 234.146C56.3108 234.146 64.9429 238.168 71.1429 219.768C71.1429 219.768 54.4493 223.205 55.5752 233.396L56.2958 234.146H56.3108Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_14"
                                d="M54.4193 252.051C54.4193 252.051 44.7664 256.238 38.4463 235.722C38.4463 235.722 56.8062 240.014 55.2449 251.241L54.4193 252.051Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_15"
                                d="M56.2056 250.715C56.2056 250.715 65.7383 255.158 72.6139 234.821C72.6139 234.821 54.1489 238.619 55.4099 249.89L56.2206 250.715H56.2056Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_16"
                                d="M54.4193 269.656C54.4193 269.656 44.7364 273.858 38.4163 253.297C38.4163 253.297 56.8062 257.604 55.2599 268.845L54.4343 269.656H54.4193Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_17"
                                d="M56.2055 268.32C56.2055 268.32 65.7683 272.778 72.6439 252.396C72.6439 252.396 54.1339 256.208 55.3949 267.495L56.2055 268.32Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_18"
                                d="M52.9029 282.788C52.9029 282.788 45.9523 285.805 41.4036 271.037C41.4036 271.037 54.6143 274.128 53.5034 282.218L52.9029 282.803V282.788Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_19"
                                d="M54.2391 282.248C54.2391 282.248 60.6192 286.33 67.4648 272.477C67.4648 272.477 53.9238 273.423 53.7437 281.588L54.2391 282.263V282.248Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_20"
                                d="M53.3685 293.534C53.3685 293.534 58.6077 296.881 64.2373 285.505C64.2373 285.505 53.1133 286.285 52.9631 292.979L53.3685 293.534Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_21"
                                d="M53.1581 293.534C53.1581 293.534 47.9189 296.881 42.2893 285.505C42.2893 285.505 53.4133 286.285 53.5635 292.979L53.1581 293.534Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_22"
                                d="M24.3649 278.481C24.3649 278.481 17.4593 286.09 3.573 270.376C3.573 270.376 21.6477 266.804 24.7852 277.43L24.3799 278.481H24.3649Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_23"
                                d="M25.1304 277.4C25.1304 277.4 35.4137 277.52 33.342 256.644C33.342 256.644 18.4349 267.465 24.0795 276.98L25.1304 277.4Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_24"
                                d="M29.9194 293.564C29.9194 293.564 22.1881 302.074 6.66553 284.499C6.66553 284.499 26.8719 280.507 30.3698 292.379L29.9194 293.549V293.564Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_25"
                                d="M30.775 292.364C30.775 292.364 42.2743 292.484 39.9474 269.161C39.9474 269.161 23.2839 281.257 29.604 291.898L30.775 292.364Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_26"
                                d="M34.7983 311.049C34.7983 311.049 26.4215 318.928 12.3401 300.183C12.3401 300.183 32.8017 297.797 35.3538 309.923L34.7983 311.064V311.049Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_27"
                                d="M35.744 309.908C35.744 309.908 47.1983 310.944 46.7179 287.501C46.7179 287.501 29.1536 298.232 34.6031 309.353L35.744 309.908Z"
                                fill="#ABDFF9"
                            />
                            <g id="Group_7">
                                <path
                                    id="Vector_28"
                                    d="M87.7463 267.735C87.7463 267.735 94.0364 274.098 105.536 259.931C105.536 259.931 89.6979 257.484 87.356 266.834L87.7463 267.735Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_29"
                                    d="M87.0406 266.834C87.0406 266.834 78.1083 267.315 79.1441 249.094C79.1441 249.094 92.505 257.949 87.9413 266.444L87.0406 266.849V266.834Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_30"
                                    d="M82.0717 282.023C82.0717 282.023 89.2926 289.347 102.518 273.048C102.518 273.048 84.3085 270.241 81.6213 280.987L82.0717 282.023Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_31"
                                    d="M81.261 280.972C81.261 280.972 70.9927 281.513 72.1936 260.576C72.1936 260.576 87.5511 270.751 82.2968 280.507L81.261 280.972Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_32"
                                    d="M77.1625 297.331C77.1625 297.331 85.2391 305.511 100.011 287.306C100.011 287.306 79.6545 284.169 76.6521 296.176L77.1625 297.331Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_33"
                                    d="M76.2618 296.161C76.2618 296.161 64.7775 296.776 66.1286 273.363C66.1286 273.363 83.2875 284.739 77.4177 295.651L76.2618 296.161Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_34"
                                    d="M73.0342 314.981C73.0342 314.981 81.7263 322.5 95.0271 303.185C95.0271 303.185 74.4904 301.669 72.4487 313.871L73.0492 314.981H73.0342Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_35"
                                    d="M72.0284 313.901C72.0284 313.901 60.6342 315.417 60.1238 291.973C60.1238 291.973 78.1234 301.969 73.1394 313.3L72.0284 313.901Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_36"
                                    d="M67.2095 340.21C67.2095 340.21 70.4371 305.691 91.9946 255.983"
                                    stroke="#2D419A"
                                    strokeMiterlimit={10}
                                />
                                <path
                                    id="Vector_37"
                                    d="M90.9288 256.389C90.9288 256.389 85.0891 249.62 100.146 239.294C100.146 239.294 101.317 255.278 91.7995 256.869L90.9288 256.404V256.389Z"
                                    fill="#ABDFF9"
                                />
                            </g>
                            <path
                                id="Vector_38"
                                d="M49.7205 337.539C49.7205 337.539 58.4876 236.998 54.8096 208.227"
                                stroke="#2D419A"
                                strokeMiterlimit={10}
                            />
                            <path
                                id="Vector_39"
                                d="M54.9597 208.932C54.9597 208.932 62.9161 204.835 52.7979 189.631C52.7979 189.631 45.7422 204.024 53.9689 209.037L54.9447 208.932H54.9597Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_40"
                                d="M41.6588 335.993C41.6588 335.993 36.99 301.639 13.3608 252.876"
                                stroke="#2D419A"
                                strokeMiterlimit={10}
                            />
                        </g>
                        <g id="Group_8">
                            <path
                                id="Vector_41"
                                d="M288.97 191.357C288.97 192.918 233.244 140.674 233.244 140.674C247.026 125.545 266.887 116.045 288.97 116.045V191.372V191.357Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_42"
                                d="M364.316 191.357H288.97V116.03C330.584 116.03 364.316 149.754 364.316 191.357Z"
                                fill="#5E9AF5"
                            />
                            <path
                                id="Vector_43"
                                d="M213.638 191.432H288.985V266.759C247.371 266.759 213.638 233.035 213.638 191.432Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_44"
                                d="M288.97 191.357H213.624C213.624 171.831 221.055 154.046 233.229 140.674L288.955 191.357H288.97Z"
                                fill="#2D419A"
                            />
                            <g id="Group_9">
                                <path
                                    id="Vector_45"
                                    d="M288.97 191.447C288.97 189.886 344.695 242.13 344.695 242.13C330.914 257.274 311.053 266.759 288.97 266.759V191.432V191.447Z"
                                    fill="#4945FF"
                                />
                                <path
                                    id="Vector_46"
                                    d="M288.97 191.447H364.316C364.316 210.958 356.885 228.758 344.71 242.13L288.985 191.447H288.97Z"
                                    fill="#0086CB"
                                />
                            </g>
                        </g>
                        <g id="Group_10">
                            <g id="Group_11">
                                <path
                                    id="Vector_47"
                                    d="M545.483 263.322H524.466V397.182H545.483V263.322Z"
                                    fill="#2D419A"
                                />
                                <path
                                    id="Vector_48"
                                    d="M439.587 318.868H418.57V397.182H439.587V318.868Z"
                                    fill="#4945FF"
                                />
                                <path
                                    id="Vector_49"
                                    d="M510.189 281.407H489.172V397.182H510.189V281.407Z"
                                    fill="#00B1DE"
                                />
                                <path
                                    id="Vector_50"
                                    d="M474.896 297.901H453.879V397.182H474.896V297.901Z"
                                    fill="#5E9AF5"
                                />
                                <path
                                    id="Vector_51"
                                    d="M404.293 333.847H383.276V397.182H404.293V333.847Z"
                                    fill="white"
                                />
                            </g>
                            <g id="Group_12">
                                <path
                                    id="Vector_52"
                                    d="M387.195 313.69L513.312 244.126"
                                    stroke="#2D419A"
                                    strokeWidth={4}
                                    strokeMiterlimit={10}
                                />
                                <path
                                    id="Vector_53"
                                    d="M510.895 262.377L519.527 239.864L495.538 237.418L510.895 262.377Z"
                                    fill="#2D419A"
                                />
                            </g>
                        </g>
                        <path
                            id="Vector_54"
                            d="M179.276 74.3966C179.516 72.9858 179.651 71.53 179.651 70.0442C179.651 57.7223 170.794 47.6366 159.58 46.7361C157.343 39.6222 151.083 34.4893 143.727 34.4893C141.955 34.4893 140.244 34.7895 138.638 35.3448C135.62 26.3547 127.619 19.9312 118.221 19.9312C109.859 19.9312 102.623 25.019 98.9903 32.4632C96.7685 31.2775 94.2615 30.6171 91.6043 30.6171C85.6295 30.6171 80.4052 33.979 77.4328 39.0518C74.2652 37.0557 70.5722 35.9151 66.624 35.9151C55.7102 35.9151 46.7029 44.68 45.3217 56.0263C36.4796 56.8068 29.4989 64.7162 29.3638 74.4116H179.261L179.276 74.3966Z"
                            fill="#ABDFF9"
                        />
                        <path
                            id="Vector_55"
                            d="M556.952 54.4655C557.193 53.0547 557.328 51.5989 557.328 50.113C557.328 37.7911 548.486 27.7055 537.256 26.805C535.02 19.691 528.76 14.5581 521.404 14.5581C519.632 14.5581 517.921 14.8583 516.314 15.4136C513.297 6.42359 505.295 0 495.898 0C487.536 0 480.3 5.08785 476.667 12.532C474.445 11.3463 471.938 10.686 469.281 10.686C463.306 10.686 458.082 14.0479 455.11 19.1207C451.942 17.1246 448.249 15.9839 444.316 15.9839C433.402 15.9839 424.395 24.7488 423.014 36.0952C414.171 36.8756 407.191 44.785 407.056 54.4805H556.952V54.4655Z"
                            fill="#ABDFF9"
                        />
                        <g id="Group_13">
                            <g id="Group_14">
                                <path
                                    id="Vector_56"
                                    d="M356.51 113.388H480.645C481.501 113.388 482.192 114.079 482.192 114.934V205.57C482.192 206.425 481.501 207.116 480.645 207.116H356.51C355.654 207.116 354.963 206.425 354.963 205.57V114.934C354.963 114.079 355.654 113.388 356.51 113.388Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_57"
                                    d="M482.192 128.832V114.934C482.192 114.079 481.501 113.388 480.645 113.388H356.51C355.654 113.388 354.963 114.079 354.963 114.934V128.832H482.192Z"
                                    fill="#2C295B"
                                />
                                <path
                                    id="Vector_58"
                                    d="M427.577 119.047H363.971C363.117 119.047 362.425 119.739 362.425 120.592V121.628C362.425 122.482 363.117 123.174 363.971 123.174H427.577C428.431 123.174 429.124 122.482 429.124 121.628V120.592C429.124 119.739 428.431 119.047 427.577 119.047Z"
                                    fill="#00B1DE"
                                />
                                <path
                                    id="Vector_59"
                                    d="M459.658 121.103C459.658 122.664 458.397 123.939 456.821 123.939C455.245 123.939 453.984 122.679 453.984 121.103C453.984 119.527 455.26 118.266 456.821 118.266C458.382 118.266 459.658 119.527 459.658 121.103Z"
                                    fill="#00B1DE"
                                />
                                <path
                                    id="Vector_60"
                                    d="M466.669 121.103C466.669 122.664 465.408 123.939 463.832 123.939C462.255 123.939 460.994 122.679 460.994 121.103C460.994 119.527 462.255 118.266 463.832 118.266C465.408 118.266 466.669 119.527 466.669 121.103Z"
                                    fill="#00B1DE"
                                />
                                <path
                                    id="Vector_61"
                                    d="M473.695 121.103C473.695 122.664 472.434 123.939 470.858 123.939C469.281 123.939 468.02 122.679 468.02 121.103C468.02 119.527 469.281 118.266 470.858 118.266C472.434 118.266 473.695 119.527 473.695 121.103Z"
                                    fill="#00B1DE"
                                />
                            </g>
                            <g id="Group_15">
                                <g id="Group_16">
                                    <path
                                        id="Vector_62"
                                        d="M373.579 132.974H367.919V173.407H373.579V132.974Z"
                                        fill="#2D419A"
                                    />
                                    <path
                                        id="Vector_63"
                                        d="M402.117 132.974H396.457V173.407H402.117V132.974Z"
                                        fill="#4945FF"
                                    />
                                    <path
                                        id="Vector_64"
                                        d="M383.096 142.205H377.437V173.407H383.096V142.205Z"
                                        fill="#00B1DE"
                                    />
                                    <path
                                        id="Vector_65"
                                        d="M392.614 146.647H386.954V173.407H392.614V146.647Z"
                                        fill="#5E9AF5"
                                    />
                                    <path
                                        id="Vector_66"
                                        d="M411.634 146.647H405.975V173.407H411.634V146.647Z"
                                        fill="#2C295B"
                                    />
                                    <path
                                        id="Vector_67"
                                        d="M421.152 150.939H415.493V173.407H421.152V150.939Z"
                                        fill="#0086CB"
                                    />
                                </g>
                                <g id="Group_17">
                                    <path
                                        id="Vector_68"
                                        d="M469.236 139.788H430.099V142.235H469.236V139.788Z"
                                        fill="#2C295B"
                                    />
                                    <path
                                        id="Vector_69"
                                        d="M469.236 145.912H430.099V148.358H469.236V145.912Z"
                                        fill="#2C295B"
                                    />
                                    <path
                                        id="Vector_70"
                                        d="M469.236 152.035H430.099V154.481H469.236V152.035Z"
                                        fill="#2C295B"
                                    />
                                    <path
                                        id="Vector_71"
                                        d="M469.236 158.158H430.099V160.605H469.236V158.158Z"
                                        fill="#2C295B"
                                    />
                                    <path
                                        id="Vector_72"
                                        d="M469.236 164.282H430.099V166.728H469.236V164.282Z"
                                        fill="#2C295B"
                                    />
                                    <path
                                        id="Vector_73"
                                        d="M469.236 170.405H430.099V172.852H469.236V170.405Z"
                                        fill="#2C295B"
                                    />
                                </g>
                                <path
                                    id="Vector_74"
                                    d="M469.236 179.635H367.919V200.692H469.236V179.635Z"
                                    fill="#2C295B"
                                />
                            </g>
                        </g>
                        <g id="Group_18">
                            <g id="Group_19">
                                <path
                                    id="Vector_75"
                                    d="M157.193 234.551H281.329C282.184 234.551 282.875 235.242 282.875 236.097V326.733C282.875 327.588 282.184 328.279 281.329 328.279H157.193C156.337 328.279 155.646 327.588 155.646 326.733V236.097C155.646 235.242 156.337 234.551 157.193 234.551Z"
                                    fill="#ABDFF9"
                                />
                                <path
                                    id="Vector_76"
                                    d="M282.875 249.995V236.097C282.875 235.242 282.184 234.551 281.329 234.551H157.193C156.337 234.551 155.646 235.242 155.646 236.097V249.995H282.875Z"
                                    fill="#2C295B"
                                />
                                <path
                                    id="Vector_77"
                                    d="M228.275 240.209H164.654C163.8 240.209 163.107 240.901 163.107 241.755V242.791C163.107 243.645 163.8 244.337 164.654 244.337H228.275C229.129 244.337 229.822 243.645 229.822 242.791V241.755C229.822 240.901 229.129 240.209 228.275 240.209Z"
                                    fill="#00B1DE"
                                />
                                <path
                                    id="Vector_78"
                                    d="M260.342 242.265C260.342 243.826 259.066 245.102 257.504 245.102C255.943 245.102 254.667 243.841 254.667 242.265C254.667 240.69 255.928 239.429 257.504 239.429C259.081 239.429 260.342 240.69 260.342 242.265Z"
                                    fill="#00B1DE"
                                />
                                <path
                                    id="Vector_79"
                                    d="M267.352 242.265C267.352 243.826 266.091 245.102 264.515 245.102C262.939 245.102 261.677 243.841 261.677 242.265C261.677 240.69 262.939 239.429 264.515 239.429C266.091 239.429 267.352 240.69 267.352 242.265Z"
                                    fill="#00B1DE"
                                />
                                <path
                                    id="Vector_80"
                                    d="M274.378 242.265C274.378 243.826 273.102 245.102 271.541 245.102C269.979 245.102 268.703 243.841 268.703 242.265C268.703 240.69 269.979 239.429 271.541 239.429C273.102 239.429 274.378 240.69 274.378 242.265Z"
                                    fill="#00B1DE"
                                />
                            </g>
                            <g id="Group_20">
                                <path
                                    id="Vector_81"
                                    d="M233.064 309.743C232.374 309.743 231.683 309.413 231.263 308.798C230.572 307.807 230.812 306.426 231.818 305.736L256.183 288.836L231.878 272.883C230.857 272.222 230.587 270.856 231.248 269.836C231.908 268.815 233.274 268.545 234.295 269.205L261.332 286.93C261.948 287.336 262.308 288.011 262.323 288.731C262.323 289.467 261.978 290.142 261.377 290.562L234.34 309.308C233.965 309.578 233.53 309.698 233.094 309.698L233.064 309.743Z"
                                    fill="#2C295B"
                                />
                                <path
                                    id="Vector_82"
                                    d="M204.181 309.188C203.745 309.188 203.31 309.053 202.935 308.798L175.898 290.052C175.297 289.632 174.937 288.957 174.952 288.221C174.952 287.486 175.327 286.81 175.943 286.42L202.98 268.68C204.001 268.02 205.352 268.305 206.027 269.311C206.688 270.331 206.403 271.682 205.397 272.357L181.092 288.311L205.457 305.211C206.448 305.901 206.703 307.267 206.012 308.272C205.592 308.888 204.901 309.218 204.211 309.218L204.181 309.188Z"
                                    fill="#2C295B"
                                />
                                <path
                                    id="Vector_83"
                                    d="M210.456 322.605C210.291 322.605 210.111 322.59 209.93 322.545C208.744 322.26 208.024 321.075 208.309 319.889L223.441 257.664C223.727 256.493 224.913 255.758 226.099 256.043C227.285 256.328 228.005 257.514 227.72 258.7L212.588 320.924C212.347 321.93 211.447 322.605 210.456 322.605Z"
                                    fill="#2C295B"
                                />
                            </g>
                        </g>
                        <g id="Group_21">
                            <path
                                id="Vector_84"
                                d="M166.725 176.304C166.725 176.304 134.614 208.932 95.0121 208.932C55.4099 208.932 23.2988 176.304 23.2988 176.304C23.2988 176.304 55.4099 143.42 95.0121 143.42C134.614 143.42 166.725 176.304 166.725 176.304Z"
                                fill="#ABDFF9"
                            />
                            <path
                                id="Vector_85"
                                d="M124.511 176.168C124.511 192.453 111.3 205.66 95.0121 205.66C78.7239 205.66 65.5132 192.453 65.5132 176.168C65.5132 159.884 78.7239 146.677 95.0121 146.677C111.3 146.677 124.511 159.884 124.511 176.168Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_86"
                                d="M109.814 176.169C109.814 184.333 103.194 190.967 95.012 190.967C86.8303 190.967 80.21 184.348 80.21 176.169C80.21 167.989 86.8454 161.37 95.012 161.37C103.179 161.37 109.814 168.004 109.814 176.169Z"
                                fill="#2C295B"
                            />
                        </g>
                        <g id="Group_22">
                            <path
                                id="Vector_87"
                                d="M154.355 319.244H145.903V345.493H154.355V319.244Z"
                                fill="white"
                            />
                            <path
                                id="Vector_88"
                                d="M189.664 284.259C189.664 305.856 172.145 323.371 150.542 323.371C128.94 323.371 111.42 305.856 111.42 284.259C111.42 262.662 128.94 245.147 150.542 245.147C172.145 245.147 189.664 262.662 189.664 284.259Z"
                                fill="#00B1DE"
                            />
                            <path
                                id="Vector_89"
                                d="M142.045 332.361V392.514C142.045 396.687 145.423 400.064 149.597 400.064H150.677C154.851 400.064 158.229 396.687 158.229 392.514V332.361H142.045Z"
                                fill="#5E76F5"
                            />
                            <path
                                id="Vector_90"
                                d="M181.032 284.259C181.032 301.098 167.386 314.741 150.542 314.741C133.699 314.741 120.052 301.098 120.052 284.259C120.052 267.42 133.699 253.777 150.542 253.777C167.386 253.777 181.032 267.42 181.032 284.259Z"
                                fill="white"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_524_26">
                <rect width={591} height={456} fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export default SvgComponent 
