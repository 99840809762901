import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";
import { annotateVideoDetails } from "../types";

const annotateVideoDetailsInitialValue: annotateVideoDetails = {
  annotateID: "",
  sourceType: "",
  referenceMediaSourceId: "",
  sourceId: "",
  polygonPoints: {
    zonesetName: [],
    zonesetDetails: [],
  },
  sourceFolderPath: "",
  targetDriveID: "",
  objectModel: [],
  outFps: 0,
  inferenceGroupID: "",
  requestID: "",
  isSaveAnnotatedVideo: 1,
  isSaveRawVideo: 0,
  createdOn: "",
  createdBy: "",
  noOfMinToDetect: "",
  containerName: "",
  zonesFromDatabase: [],
};

const annotateVideoDetailsSlice = createSlice({
  name: "annotateVideoDetails",
  initialState: annotateVideoDetailsInitialValue,
  reducers: {
    modifyAnnotateVideoDetails: (
      _,
      action: PayloadAction<annotateVideoDetails>
    ) => {
      return action.payload;
    },
  },
});

export const { modifyAnnotateVideoDetails } = annotateVideoDetailsSlice.actions;
export const getAnnotateVideoDetails = (state: Rootstate) =>
  state.AnnotateVideoDetailsSlice;
export default annotateVideoDetailsSlice.reducer;
