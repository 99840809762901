import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContext } from "react";
import styles from "../../styles/UIComponent.module.scss";
import { ThemeContext } from "../../Context/ThemeContext"
import { styled } from "@mui/system";
import { createTheme, ThemeProvider } from '@mui/material/styles';


export default function DropdownComponent(props: any) {
  const { theme } = useContext(ThemeContext);
  const StyledMenuItem = styled(MenuItem)({
    width: "100%",
    fontSize: "1vw",
    padding: "3%",
    borderLeft: "0.2vw solid transparent",
    "&:hover": {
      backgroundImage:
        theme === "light"
          ? "linear-gradient(to right, #dee0f8, #e7e7f9, #efeefa, #f6f5fc, #fdfdfd)"
          : "linear-gradient(to right, #846ae8, #6456b0, #49417a, #302d47)",
      borderLeft:
        theme === "light" ? "0.2vw solid #2958FF" : "0.2vw solid #6a48f2",
    },
  });


  const styledTheme = createTheme({
    palette: {
      primary: {
        main: "#6a48f2",
      },
      mode: theme === "light" ? "light" : "dark"
    },
  });

  const { handleChangeFunc, value, label, menuItemValues, name, error, autoFocus } = props;


  const handleChange = (event: any) => {
    handleChangeFunc(event)
  }

  return (
    <ThemeProvider theme={styledTheme}>
      <div className={`${styles[theme]}`}>
        <Box>
          <FormControl className={styles.formControlStyle}>
            <div className={`${styles.labelContainer} ${styles[theme]}`}>
              <p className={`${styles.labelContent} ${styles[theme]}`}>{label}</p>
            </div>
            <div >
              <Select
                className={`${styles.dropdownStyle} ${styles[theme]}`}
                id="dropDown-id"
                name={name}
                value={value}
                error={error}
                onChange={(event: any) => handleChange(event)}
                autoFocus={autoFocus ? autoFocus : false}
              >
                {menuItemValues?.map((item: any) => (
                  <StyledMenuItem
                    key={item.key}
                    value={item.key}
                    className={`${styles.dropdownValue} ${styles[theme]}`}
                  >
                    <p className={`${styles.dropdownSelected} ${styles[theme]}`}>    {item.value}</p>
                  </StyledMenuItem>
                ))}
              </Select>

            </div>
          </FormControl>
        </Box>
      </div >
    </ThemeProvider>
  );
}