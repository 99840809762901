import { Button } from '@mui/material';
import * as React from 'react';
import { useContext } from "react";
import { ThemeContext } from "../../Context/ThemeContext";
import styles from "../../styles/UIComponent.module.scss";



export default function SecondaryButtonComponent(props: any) {

    const { onClick, label, variant, fullWidth, size } = props
    const { theme } = useContext(ThemeContext);

    return (
        <div className={`${styles[theme]}`}>
            <Button
                variant={variant || "outlined"}
                onClick={onClick}
                fullWidth={fullWidth || true}
                size={size}
                className={`${styles.SecondaryButtonStyle} ${styles[theme]}`}

            >
                {label}
            </Button>
        </div>

    )
};