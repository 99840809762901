import React , {SVGProps ,useContext } from "react"
import { ThemeContext } from '../../Context/ThemeContext'

const SvgComponentDarkTheme = (props: SVGProps<SVGSVGElement>) => (

  <svg
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5401 12.4086C27.9067 11.7752 26.8796 11.7793 26.2421 12.4148L20.4754 18.1814L14.7087 12.4148C14.0732 11.7793 13.0421 11.7752 12.4107 12.4086C11.7772 13.0421 11.7814 14.0712 12.4169 14.7067L18.1835 20.4733L12.4169 26.24C11.7814 26.8755 11.7772 27.9046 12.4107 28.538C13.0441 29.1715 14.0732 29.1674 14.7087 28.5319L20.4754 22.7652L26.2421 28.5319C26.8796 29.1674 27.9067 29.1715 28.5401 28.538C29.1735 27.9046 29.1715 26.8755 28.534 26.24L22.7673 20.4733L28.534 14.7067C29.1715 14.0712 29.1735 13.0421 28.5401 12.4086ZM20.5 0C9.17785 0 0 9.17785 0 20.5C0 31.8222 9.17785 41 20.5 41C31.8222 41 41 31.8222 41 20.5C41 9.17785 31.8222 0 20.5 0Z"
      fill="#A3A3A4"
    />
  </svg>
)
const SvgComponentLightTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5401 12.4086C27.9067 11.7752 26.8796 11.7793 26.2421 12.4148L20.4754 18.1814L14.7087 12.4148C14.0732 11.7793 13.0421 11.7752 12.4107 12.4086C11.7772 13.0421 11.7814 14.0712 12.4169 14.7067L18.1835 20.4733L12.4169 26.24C11.7814 26.8755 11.7772 27.9046 12.4107 28.538C13.0441 29.1715 14.0732 29.1674 14.7087 28.5319L20.4754 22.7652L26.2421 28.5319C26.8796 29.1674 27.9067 29.1715 28.5401 28.538C29.1735 27.9046 29.1715 26.8755 28.534 26.24L22.7673 20.4733L28.534 14.7067C29.1715 14.0712 29.1735 13.0421 28.5401 12.4086ZM20.5 0C9.17785 0 0 9.17785 0 20.5C0 31.8222 9.17785 41 20.5 41C31.8222 41 41 31.8222 41 20.5C41 9.17785 31.8222 0 20.5 0Z"
      fill="#A3A3A4"
    />
  </svg>
)
const CloseIcon = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      {
        theme === "light"

          ?
          <SvgComponentLightTheme /> : <SvgComponentDarkTheme />}
    </>
  )
}

export default CloseIcon


