import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from 'redux-persist'
import { combineReducers } from '@reduxjs/toolkit'
import filters from "./Fliters.slice";
import ApiCall from "./VideoFiltersApiCall.slice";
import ComponentNavigation from "./ComponentNavigation.slice";
// import RTSPConfigJsonSlice from "./RTSPConfigJson.slice";
import UserdetailsSlice from "./Userdetails.slice";
// import BlobConfigJSONSlice from "./BlobConfigJSON.slice";
import PageNavigation from "./PageNavigation.slice";
import AnnotateVideoDetailsSlice from "./AnnotateVideoDetails.slice";
import SasTokenSlice from "./SasToken.slice";
import FileExplorerSlice from "./FileExplorer.slice";
import HomeFilterSlice from "./HomeFilter.slice";
import SideBarNavigationSlice from "./SideBarNavigation.slice";


const persistConfig = {
  key: "root",
  version: 1,
  storage

}

const reducer = combineReducers({
  filters,
  ApiCall,
  ComponentNavigation,
  // RTSPConfigJsonSlice,
  UserdetailsSlice,
  // BlobConfigJSONSlice,
  PageNavigation,
  AnnotateVideoDetailsSlice,
  SasTokenSlice,
  FileExplorerSlice,
  HomeFilterSlice,
  SideBarNavigationSlice
})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"], // Ignore these actions
        ignoredActionPaths: ["register", "rehydrate"], // Ignore paths
        ignoredPaths: ["_persist"] // Ignore _persist in the state
      }
    })
});

export default store;
export type Rootstate = ReturnType<typeof store.getState>;
