
import { env } from './Config.ts'

const ApiConfig = {



  api: env === "dev" ?
    {
      //dev
      getSASToken:
        "https://smartifuncapp.azurewebsites.net/api/GetSasToken_UAT?",
      getAiVideo:
        "https://smartifuncapp.azurewebsites.net/api/GetAIVideo_UAT",
      getSelectedVideo:
        "https://smartifuncapp.azurewebsites.net/api/GetAIVideoBlob_UAT",
      getInferenceGroup:
        "https://smartifuncapp.azurewebsites.net/api/GetInferenceGroupDetails_UAT",
      getServerDetails:
        "https://smartifuncapp.azurewebsites.net/api/GetAllServer_UAT",
      getMediaDrive:
        "https://smartifuncapp.azurewebsites.net/api/GetMediaDetails_UAT",
      getValidatedCameraDetails:
        "https://smartifuncapp.azurewebsites.net/api/GetCameraDetails_UAT",
      insertUpdateMediaDrive:
        "https://smartifuncapp.azurewebsites.net/api/InsertUpdateMedia_UAT",
      insertAnnotationDetails:
        "https://smartifuncapp.azurewebsites.net/api/InsertAnnotationDetails_UAT",
      insertUpdateServer:
        "https://smartifuncapp.azurewebsites.net/api/InsertUpdateServer_UAT",
      updateContainerStatus:
        "https://smartifuncapp.azurewebsites.net/api/UpdateContainerStatusDetails_UAT",
      getRunAnalytics:
        "https://smartifuncapp.azurewebsites.net/api/GetAnalyticsRun_UAT",
      getServerMatix:
        "https://smartifuncapp.azurewebsites.net/api/GetMatrixServer_UAT",
      getReferenceMediaSource:
        "https://smartifuncapp.azurewebsites.net/api/GetReferenceMediaSource_UAT",
      getServerIP:
        "https://smartifuncapp.azurewebsites.net/api/GetServerIPDetails_UAT",
      getContainerDetails:
        "https://smartifuncapp.azurewebsites.net/api/GetContainerDetails_UAT",
      insertInferenceGroup:
        "https://smartifuncapp.azurewebsites.net/api/InsertInferenceGroup_UAT",
      getContainerHistory:
        "https://smartifuncapp.azurewebsites.net/api/GetContainerHistoryDetails_UAT?",
      deleteRTSP:
        "https://smartifuncapp.azurewebsites.net/api/DeleteLive_UAT",
      insertUpdateLive:
        "https://smartifuncapp.azurewebsites.net/api/InsertUpdateLive_UAT?",
      insertZoneDetails:
        "https://smartifuncapp.azurewebsites.net/api/InsertZoneDetails_UAT?",
      getAllZoneDetails:
        "https://smartifuncapp.azurewebsites.net/api/GetAllZoneDetails_UAT?",
      insertMediaFile:
        "https://smartifuncapp.azurewebsites.net/api/InsertMediaFile_UAT?",
      stopContainer:
        "https://smartifuncapp.azurewebsites.net/api/StopDocker_UAT?"
    }
    :
    {

      //production
      getSASToken:
        "https://smartifuncapp.azurewebsites.net/api/GetSasToken?",
      getAiVideo:
        "https://smartifuncapp.azurewebsites.net/api/GetAIVideo",
      getSelectedVideo:
        "https://smartifuncapp.azurewebsites.net/api/GetAIVideoBlob",
      getInferenceGroup:
        "https://smartifuncapp.azurewebsites.net/api/GetInferenceGroupDetails",
      getServerDetails:
        "https://smartifuncapp.azurewebsites.net/api/GetAllServer",
      getMediaDrive:
        "https://smartifuncapp.azurewebsites.net/api/GetMediaDetials",
      getValidatedCameraDetails:
        "https://smartifuncapp.azurewebsites.net/api/GetCameraDetails",
      insertUpdateMediaDrive:
        "https://smartifuncapp.azurewebsites.net/api/InsertUpdateMedia",
      insertAnnotationDetails:
        "https://smartifuncapp.azurewebsites.net/api/InsertAnnotationDetails",
      insertUpdateServer:
        "https://smartifuncapp.azurewebsites.net/api/InsertUpdateServer",
      updateContainerStatus:
        "https://smartifuncapp.azurewebsites.net/api/UpdateContainerStatusDetails",
      getRunAnalytics:
        "https://smartifuncapp.azurewebsites.net/api/GetAnalyticsRun",
      getServerMatix:
        "https://smartifuncapp.azurewebsites.net/api/GetMatrixServer",
      getReferenceMediaSource:
        "https://smartifuncapp.azurewebsites.net/api/GetReferenceMediaSource",
      getServerIP:
        "https://smartifuncapp.azurewebsites.net/api/GetServerIPDetails",
      getContainerDetails:
        "https://smartifuncapp.azurewebsites.net/api/GetContainerDetails",
      insertInferenceGroup:
        "https://smartifuncapp.azurewebsites.net/api/InsertInferenceGroup",
      getContainerHistory:
        "https://smartifuncapp.azurewebsites.net/api/GetContainerHistoryDetails?",
      deleteRTSP:
        "https://smartifuncapp.azurewebsites.net/api/DeleteLive",
      insertUpdateLive:
        "https://smartifuncapp.azurewebsites.net/api/InsertUpdateLive",
      insertZoneDetails:
        "https://smartifuncapp.azurewebsites.net/api/InsertZoneDetails",
      getAllZoneDetails:
        "https://smartifuncapp.azurewebsites.net/api/GetAllZoneDetails",
      insertMediaFile:
        "https://smartifuncapp.azurewebsites.net/api/InsertMediaFile",
      stopContainer:
        "https://smartifuncapp.azurewebsites.net/api/StopDocker?"
    },




}



export default ApiConfig;