import React, { useContext } from 'react'
import { ThemeContext } from "../Context/ThemeContext";
import styles from '../styles/ComponentStyles/AnalyticsSideBar.module.scss'
import { motion } from "framer-motion"
import { setcurrentActivePage, getcurrentActivePage } from "../Store/ComponentNavigation.slice"
import { useDispatch, useSelector } from 'react-redux'

const AnalyticsSideBar = () => {

    const { theme } = useContext(ThemeContext);
    const currentActivePage = useSelector(getcurrentActivePage)
    const dispatch = useDispatch();
    const components = [{
        id: 1,
        name: "Live"
    }, {
        id: 2,
        name: "Media Drive"
    }, {
        id: 3,
        name: "Analytics Run"
    }]


    return (
        <div className={`${styles[theme]}`} >
            <div className={`${styles.sideBarParent} ${styles[theme]}`} >
                {components.map((obj, index) => {
                    return (
                      <div  className={ currentActivePage === obj.id?`${styles.clickedStyle} ${styles[theme]}`:`${styles.hoverStyle} ${styles[theme]}`}>
                          <div className={`${styles.sideBarItem} ${styles[theme]}`} key={index} onClick={() => {
                            dispatch(setcurrentActivePage(obj.id));
                        }}>
                            {currentActivePage === obj.id && <motion.div layoutId='ActivePageSideBar' className={`${styles.motionDiv} ${styles[theme]}`} />}
                            <p className={`${styles.sideBarItemLable} ${styles[theme]}`} >{obj.name}</p>

                        </div>
                      </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AnalyticsSideBar