import React , {SVGProps ,useContext } from "react"
import { ThemeContext } from '../../Context/ThemeContext'

const ServerIconDarkTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 31 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 327">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36287 0.133681C5.39225 0.133681 5.42207 0.133696 5.45234 0.133696H25.5476C25.5779 0.133696 25.6077 0.133681 25.6371 0.133681C26.269 0.133526 26.6996 0.133418 27.0743 0.207956C28.6075 0.512878 29.8057 1.71125 30.1107 3.24424C30.1852 3.61894 30.1851 4.04966 30.1849 4.68158C30.1849 4.71096 30.1849 4.74078 30.1849 4.77106C30.1849 4.80133 30.1849 4.83116 30.1849 4.86055C30.1851 5.49245 30.1852 5.92319 30.1107 6.29787C29.8057 7.83087 28.6075 9.02923 27.0743 9.33417C26.6996 9.40871 26.269 9.4086 25.6371 9.40844C25.6077 9.40843 25.5779 9.40843 25.5476 9.40843H5.45234C5.42207 9.40843 5.39225 9.40843 5.36287 9.40844C4.73095 9.4086 4.30021 9.40871 3.92552 9.33417C2.39253 9.02923 1.19415 7.83087 0.88923 6.29787C0.814692 5.92317 0.814801 5.49245 0.814955 4.86053C0.814955 4.83115 0.814971 4.80133 0.814971 4.77106C0.814971 4.7408 0.814955 4.71098 0.814955 4.68159C0.814801 4.04967 0.814692 3.61894 0.88923 3.24424C1.19415 1.71125 2.39253 0.512878 3.92552 0.207956C4.30021 0.133418 4.73095 0.133526 5.36287 0.133681ZM5.45234 1.67949C4.69703 1.67949 4.43226 1.68323 4.22708 1.72404C3.30729 1.90699 2.58827 2.62602 2.40531 3.54581C2.3645 3.75098 2.36076 4.01576 2.36076 4.77106C2.36076 5.52637 2.3645 5.79114 2.40531 5.9963C2.58827 6.91611 3.30729 7.63513 4.22708 7.81809C4.43226 7.8589 4.69703 7.86264 5.45234 7.86264H25.5476C26.3028 7.86264 26.5676 7.8589 26.7729 7.81809C27.6927 7.63513 28.4116 6.91611 28.5946 5.9963C28.6354 5.79114 28.6391 5.52637 28.6391 4.77106C28.6391 4.01576 28.6354 3.75098 28.5946 3.54581C28.4116 2.62602 27.6927 1.90699 26.7729 1.72404C26.5676 1.68323 26.3028 1.67949 25.5476 1.67949H5.45234Z"
        fill="#F5F5F5"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36287 7.86267C5.39225 7.86267 5.42207 7.86269 5.45234 7.86269H25.5476C25.5779 7.86269 25.6077 7.86267 25.6371 7.86267C26.269 7.86251 26.6996 7.86241 27.0743 7.93695C28.6075 8.24187 29.8057 9.44024 30.1107 10.9732C30.1852 11.3479 30.1851 11.7786 30.1849 12.4105C30.1849 12.4399 30.1849 12.4697 30.1849 12.5C30.1849 12.5303 30.1849 12.5602 30.1849 12.5895C30.1851 13.2214 30.1852 13.6521 30.1107 14.0268C29.8057 15.5599 28.6075 16.7582 27.0743 17.0632C26.6996 17.1377 26.269 17.1375 25.6371 17.1374C25.6077 17.1374 25.5779 17.1374 25.5476 17.1374H5.45234C5.42207 17.1374 5.39225 17.1374 5.36287 17.1374C4.73095 17.1375 4.30021 17.1377 3.92552 17.0632C2.39253 16.7582 1.19415 15.5599 0.88923 14.0268C0.814692 13.6521 0.814801 13.2214 0.814955 12.5895C0.814955 12.5602 0.814971 12.5303 0.814971 12.5C0.814971 12.4697 0.814955 12.4399 0.814955 12.4105C0.814801 11.7786 0.814692 11.3479 0.88923 10.9732C1.19415 9.44024 2.39253 8.24187 3.92552 7.93695C4.30021 7.86241 4.73095 7.86251 5.36287 7.86267ZM5.45234 9.40847C4.69703 9.40847 4.43226 9.41221 4.22708 9.45302C3.30729 9.63598 2.58827 10.3549 2.40531 11.2748C2.3645 11.48 2.36076 11.7447 2.36076 12.5C2.36076 13.2553 2.3645 13.5201 2.40531 13.7254C2.58827 14.6451 3.30729 15.3641 4.22708 15.5471C4.43226 15.5879 4.69703 15.5916 5.45234 15.5916H25.5476C26.3028 15.5916 26.5676 15.5879 26.7729 15.5471C27.6927 15.3641 28.4116 14.6451 28.5946 13.7254C28.6354 13.5201 28.6391 13.2553 28.6391 12.5C28.6391 11.7447 28.6354 11.48 28.5946 11.2748C28.4116 10.3549 27.6927 9.63598 26.7729 9.45302C26.5676 9.41221 26.3028 9.40847 25.5476 9.40847H5.45234Z"
        fill="#F5F5F5"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36287 15.5915C5.39225 15.5915 5.42207 15.5915 5.45234 15.5915H25.5476C25.5779 15.5915 25.6077 15.5915 25.6371 15.5915C26.269 15.5914 26.6996 15.5912 27.0743 15.6659C28.6075 15.9707 29.8057 17.1692 30.1107 18.7021C30.1852 19.0768 30.1851 19.5075 30.1849 20.1394C30.1849 20.1688 30.1849 20.1986 30.1849 20.2289C30.1849 20.2592 30.1849 20.289 30.1849 20.3184C30.1851 20.9503 30.1852 21.381 30.1107 21.7557C29.8057 23.2888 28.6075 24.4871 27.0743 24.7921C26.6996 24.8666 26.269 24.8664 25.6371 24.8663C25.6077 24.8663 25.5779 24.8663 25.5476 24.8663H5.45234C5.42207 24.8663 5.39225 24.8663 5.36287 24.8663C4.73095 24.8664 4.30021 24.8666 3.92552 24.7921C2.39253 24.4871 1.19415 23.2888 0.88923 21.7557C0.814692 21.381 0.814801 20.9503 0.814955 20.3184C0.814955 20.289 0.814971 20.2592 0.814971 20.2289C0.814971 20.1986 0.814955 20.1688 0.814955 20.1394C0.814801 19.5075 0.814692 19.0768 0.88923 18.7021C1.19415 17.1692 2.39253 15.9707 3.92552 15.6659C4.30021 15.5912 4.73095 15.5914 5.36287 15.5915ZM5.45234 17.1373C4.69703 17.1373 4.43226 17.141 4.22708 17.1818C3.30729 17.3649 2.58827 18.0838 2.40531 19.0037C2.3645 19.2088 2.36076 19.4736 2.36076 20.2289C2.36076 20.9842 2.3645 21.249 2.40531 21.4542C2.58827 22.374 3.30729 23.0929 4.22708 23.276C4.43226 23.3168 4.69703 23.3205 5.45234 23.3205H25.5476C26.3028 23.3205 26.5676 23.3168 26.7729 23.276C27.6927 23.0929 28.4116 22.374 28.5946 21.4542C28.6354 21.249 28.6391 20.9842 28.6391 20.2289C28.6391 19.4736 28.6354 19.2088 28.5946 19.0037C28.4116 18.0838 27.6927 17.3649 26.7729 17.1818C26.5676 17.141 26.3028 17.1373 25.5476 17.1373H5.45234Z"
        fill="#F5F5F5"
      />
      <path
        id="Vector_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45166 3.99811C5.45166 3.57126 5.7977 3.22522 6.22455 3.22522H12.4077C12.8345 3.22522 13.1806 3.57126 13.1806 3.99811C13.1806 4.42497 12.8345 4.77101 12.4077 4.77101H6.22455C5.7977 4.77101 5.45166 4.42497 5.45166 3.99811Z"
        fill="#F5F5F5"
      />
      <path
        id="Vector_5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4565 3.99811C22.4565 3.57126 22.8026 3.22522 23.2294 3.22522H24.7752C25.202 3.22522 25.5481 3.57126 25.5481 3.99811C25.5481 4.42497 25.202 4.77101 24.7752 4.77101H23.2294C22.8026 4.77101 22.4565 4.42497 22.4565 3.99811Z"
        fill="#F5F5F5"
      />
      <path
        id="Vector_6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4565 11.7271C22.4565 11.3003 22.8026 10.9542 23.2294 10.9542H24.7752C25.202 10.9542 25.5481 11.3003 25.5481 11.7271C25.5481 12.1538 25.202 12.5 24.7752 12.5H23.2294C22.8026 12.5 22.4565 12.1538 22.4565 11.7271Z"
        fill="#F5F5F5"
      />
      <path
        id="Vector_7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4565 19.456C22.4565 19.0293 22.8026 18.6832 23.2294 18.6832H24.7752C25.202 18.6832 25.5481 19.0293 25.5481 19.456C25.5481 19.8828 25.202 20.2289 24.7752 20.2289H23.2294C22.8026 20.2289 22.4565 19.8828 22.4565 19.456Z"
        fill="#F5F5F5"
      />
      <path
        id="Vector_8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45166 11.7271C5.45166 11.3003 5.7977 10.9542 6.22455 10.9542H12.4077C12.8345 10.9542 13.1806 11.3003 13.1806 11.7271C13.1806 12.1538 12.8345 12.5 12.4077 12.5H6.22455C5.7977 12.5 5.45166 12.1538 5.45166 11.7271Z"
        fill="#F5F5F5"
      />
      <path
        id="Vector_9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45166 19.456C5.45166 19.0293 5.7977 18.6832 6.22455 18.6832H12.4077C12.8345 18.6832 13.1806 19.0293 13.1806 19.456C13.1806 19.8828 12.8345 20.2289 12.4077 20.2289H6.22455C5.7977 20.2289 5.45166 19.8828 5.45166 19.456Z"
        fill="#F5F5F5"
      />
    </g>
  </svg>
)

const ServerIconLightTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg

    viewBox="0 0 31 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 327">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36287 0.133681C5.39225 0.133681 5.42207 0.133696 5.45234 0.133696H25.5476C25.5779 0.133696 25.6077 0.133681 25.6371 0.133681C26.269 0.133526 26.6996 0.133418 27.0743 0.207956C28.6075 0.512878 29.8057 1.71125 30.1107 3.24424C30.1852 3.61894 30.1851 4.04966 30.1849 4.68158C30.1849 4.71096 30.1849 4.74078 30.1849 4.77106C30.1849 4.80133 30.1849 4.83116 30.1849 4.86055C30.1851 5.49245 30.1852 5.92319 30.1107 6.29787C29.8057 7.83087 28.6075 9.02923 27.0743 9.33417C26.6996 9.40871 26.269 9.4086 25.6371 9.40844C25.6077 9.40843 25.5779 9.40843 25.5476 9.40843H5.45234C5.42207 9.40843 5.39225 9.40843 5.36287 9.40844C4.73095 9.4086 4.30021 9.40871 3.92552 9.33417C2.39253 9.02923 1.19415 7.83087 0.88923 6.29787C0.814692 5.92317 0.814801 5.49245 0.814955 4.86053C0.814955 4.83115 0.814971 4.80133 0.814971 4.77106C0.814971 4.7408 0.814955 4.71098 0.814955 4.68159C0.814801 4.04967 0.814692 3.61894 0.88923 3.24424C1.19415 1.71125 2.39253 0.512878 3.92552 0.207956C4.30021 0.133418 4.73095 0.133526 5.36287 0.133681ZM5.45234 1.67949C4.69703 1.67949 4.43226 1.68323 4.22708 1.72404C3.30729 1.90699 2.58827 2.62602 2.40531 3.54581C2.3645 3.75098 2.36076 4.01576 2.36076 4.77106C2.36076 5.52637 2.3645 5.79114 2.40531 5.9963C2.58827 6.91611 3.30729 7.63513 4.22708 7.81809C4.43226 7.8589 4.69703 7.86264 5.45234 7.86264H25.5476C26.3028 7.86264 26.5676 7.8589 26.7729 7.81809C27.6927 7.63513 28.4116 6.91611 28.5946 5.9963C28.6354 5.79114 28.6391 5.52637 28.6391 4.77106C28.6391 4.01576 28.6354 3.75098 28.5946 3.54581C28.4116 2.62602 27.6927 1.90699 26.7729 1.72404C26.5676 1.68323 26.3028 1.67949 25.5476 1.67949H5.45234Z"
        fill="#28282b"
      />
      <path
        id="Vector_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36287 7.86267C5.39225 7.86267 5.42207 7.86269 5.45234 7.86269H25.5476C25.5779 7.86269 25.6077 7.86267 25.6371 7.86267C26.269 7.86251 26.6996 7.86241 27.0743 7.93695C28.6075 8.24187 29.8057 9.44024 30.1107 10.9732C30.1852 11.3479 30.1851 11.7786 30.1849 12.4105C30.1849 12.4399 30.1849 12.4697 30.1849 12.5C30.1849 12.5303 30.1849 12.5602 30.1849 12.5895C30.1851 13.2214 30.1852 13.6521 30.1107 14.0268C29.8057 15.5599 28.6075 16.7582 27.0743 17.0632C26.6996 17.1377 26.269 17.1375 25.6371 17.1374C25.6077 17.1374 25.5779 17.1374 25.5476 17.1374H5.45234C5.42207 17.1374 5.39225 17.1374 5.36287 17.1374C4.73095 17.1375 4.30021 17.1377 3.92552 17.0632C2.39253 16.7582 1.19415 15.5599 0.88923 14.0268C0.814692 13.6521 0.814801 13.2214 0.814955 12.5895C0.814955 12.5602 0.814971 12.5303 0.814971 12.5C0.814971 12.4697 0.814955 12.4399 0.814955 12.4105C0.814801 11.7786 0.814692 11.3479 0.88923 10.9732C1.19415 9.44024 2.39253 8.24187 3.92552 7.93695C4.30021 7.86241 4.73095 7.86251 5.36287 7.86267ZM5.45234 9.40847C4.69703 9.40847 4.43226 9.41221 4.22708 9.45302C3.30729 9.63598 2.58827 10.3549 2.40531 11.2748C2.3645 11.48 2.36076 11.7447 2.36076 12.5C2.36076 13.2553 2.3645 13.5201 2.40531 13.7254C2.58827 14.6451 3.30729 15.3641 4.22708 15.5471C4.43226 15.5879 4.69703 15.5916 5.45234 15.5916H25.5476C26.3028 15.5916 26.5676 15.5879 26.7729 15.5471C27.6927 15.3641 28.4116 14.6451 28.5946 13.7254C28.6354 13.5201 28.6391 13.2553 28.6391 12.5C28.6391 11.7447 28.6354 11.48 28.5946 11.2748C28.4116 10.3549 27.6927 9.63598 26.7729 9.45302C26.5676 9.41221 26.3028 9.40847 25.5476 9.40847H5.45234Z"
        fill="#28282b"
      />
      <path
        id="Vector_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36287 15.5915C5.39225 15.5915 5.42207 15.5915 5.45234 15.5915H25.5476C25.5779 15.5915 25.6077 15.5915 25.6371 15.5915C26.269 15.5914 26.6996 15.5912 27.0743 15.6659C28.6075 15.9707 29.8057 17.1692 30.1107 18.7021C30.1852 19.0768 30.1851 19.5075 30.1849 20.1394C30.1849 20.1688 30.1849 20.1986 30.1849 20.2289C30.1849 20.2592 30.1849 20.289 30.1849 20.3184C30.1851 20.9503 30.1852 21.381 30.1107 21.7557C29.8057 23.2888 28.6075 24.4871 27.0743 24.7921C26.6996 24.8666 26.269 24.8664 25.6371 24.8663C25.6077 24.8663 25.5779 24.8663 25.5476 24.8663H5.45234C5.42207 24.8663 5.39225 24.8663 5.36287 24.8663C4.73095 24.8664 4.30021 24.8666 3.92552 24.7921C2.39253 24.4871 1.19415 23.2888 0.88923 21.7557C0.814692 21.381 0.814801 20.9503 0.814955 20.3184C0.814955 20.289 0.814971 20.2592 0.814971 20.2289C0.814971 20.1986 0.814955 20.1688 0.814955 20.1394C0.814801 19.5075 0.814692 19.0768 0.88923 18.7021C1.19415 17.1692 2.39253 15.9707 3.92552 15.6659C4.30021 15.5912 4.73095 15.5914 5.36287 15.5915ZM5.45234 17.1373C4.69703 17.1373 4.43226 17.141 4.22708 17.1818C3.30729 17.3649 2.58827 18.0838 2.40531 19.0037C2.3645 19.2088 2.36076 19.4736 2.36076 20.2289C2.36076 20.9842 2.3645 21.249 2.40531 21.4542C2.58827 22.374 3.30729 23.0929 4.22708 23.276C4.43226 23.3168 4.69703 23.3205 5.45234 23.3205H25.5476C26.3028 23.3205 26.5676 23.3168 26.7729 23.276C27.6927 23.0929 28.4116 22.374 28.5946 21.4542C28.6354 21.249 28.6391 20.9842 28.6391 20.2289C28.6391 19.4736 28.6354 19.2088 28.5946 19.0037C28.4116 18.0838 27.6927 17.3649 26.7729 17.1818C26.5676 17.141 26.3028 17.1373 25.5476 17.1373H5.45234Z"
        fill="#28282b"
      />
      <path
        id="Vector_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45166 3.99811C5.45166 3.57126 5.7977 3.22522 6.22455 3.22522H12.4077C12.8345 3.22522 13.1806 3.57126 13.1806 3.99811C13.1806 4.42497 12.8345 4.77101 12.4077 4.77101H6.22455C5.7977 4.77101 5.45166 4.42497 5.45166 3.99811Z"
        fill="#28282b"
      />
      <path
        id="Vector_5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4565 3.99811C22.4565 3.57126 22.8026 3.22522 23.2294 3.22522H24.7752C25.202 3.22522 25.5481 3.57126 25.5481 3.99811C25.5481 4.42497 25.202 4.77101 24.7752 4.77101H23.2294C22.8026 4.77101 22.4565 4.42497 22.4565 3.99811Z"
        fill="#28282b"
      />
      <path
        id="Vector_6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4565 11.7271C22.4565 11.3003 22.8026 10.9542 23.2294 10.9542H24.7752C25.202 10.9542 25.5481 11.3003 25.5481 11.7271C25.5481 12.1538 25.202 12.5 24.7752 12.5H23.2294C22.8026 12.5 22.4565 12.1538 22.4565 11.7271Z"
        fill="#28282b"
      />
      <path
        id="Vector_7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4565 19.456C22.4565 19.0293 22.8026 18.6832 23.2294 18.6832H24.7752C25.202 18.6832 25.5481 19.0293 25.5481 19.456C25.5481 19.8828 25.202 20.2289 24.7752 20.2289H23.2294C22.8026 20.2289 22.4565 19.8828 22.4565 19.456Z"
        fill="#28282b"
      />
      <path
        id="Vector_8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45166 11.7271C5.45166 11.3003 5.7977 10.9542 6.22455 10.9542H12.4077C12.8345 10.9542 13.1806 11.3003 13.1806 11.7271C13.1806 12.1538 12.8345 12.5 12.4077 12.5H6.22455C5.7977 12.5 5.45166 12.1538 5.45166 11.7271Z"
        fill="#28282b"
      />
      <path
        id="Vector_9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45166 19.456C5.45166 19.0293 5.7977 18.6832 6.22455 18.6832H12.4077C12.8345 18.6832 13.1806 19.0293 13.1806 19.456C13.1806 19.8828 12.8345 20.2289 12.4077 20.2289H6.22455C5.7977 20.2289 5.45166 19.8828 5.45166 19.456Z"
        fill="#28282b"
      />
    </g>
  </svg>
)

const ServerIconOutlinedSVG = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      {
        theme === "light"
          ?
          <ServerIconLightTheme /> : <ServerIconDarkTheme />}
    </>
  )
}

export default ServerIconOutlinedSVG 
