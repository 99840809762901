import { useMsal } from "@azure/msal-react";


export const UseAuth = () => {
  const { instance, accounts } = useMsal();

  const handleGetToken = async () => {
    if (accounts.length > 0) {
      const request = {
        scopes: ["https://storage.azure.com/user_impersonation"], 
        account: accounts[0],
      };
      try {
        const response = await instance.acquireTokenSilent(request);
        return response.accessToken;
      } catch (error) {
        console.log("error" , error)
      }
    }
  };


  return { handleGetToken };
};






