import React, { useState, createContext, ReactNode, useEffect } from "react";

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext<any>({ theme: "dark" });

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string | null>("");
  useEffect(() => {
    // if (!localStorage.getItem("theme")) {
      localStorage.setItem('theme', "dark");
    // }
    setTheme(localStorage.getItem('theme'));
  }, [theme])

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};