import React , {SVGProps ,useContext } from "react"
import { ThemeContext } from '../../Context/ThemeContext'


const SvgComponentDarkTheme = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox="0 0 263 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.4834 0V2.35283C13.4834 20.3772 13.5633 38.3944 13.4442 56.4188C13.3963 63.4931 17.2157 68.0718 22.6233 69.2641C28.9353 70.6541 34.8031 68.1035 37.6542 61.9688C40.6476 55.5267 43.3478 48.9504 45.9594 42.3422C49.159 34.2502 54.9237 29.8203 63.6745 28.9917C69.4305 28.4476 74.9499 29.126 79.9045 32.0316C86.3283 35.8048 89.3856 44.4352 84.2161 50.6637C83.0402 52.0769 81.3577 53.0714 79.8087 54.3431C80.9918 55.1962 82.31 56.1113 83.5962 57.0669C89.4959 61.4405 89.4248 70.67 84.5485 75.7148C78.8157 81.6446 71.5587 82.9076 63.7369 82.5525C57.0823 82.2522 51.8664 79.2917 47.6971 74.2382C46.6562 72.9752 46.6098 71.933 47.4271 70.5271C49.2141 67.4395 50.8255 64.2423 52.4137 61.2659C54.4548 63.4614 56.3913 65.9629 58.7576 67.9924C62.5523 71.2531 67.4838 71.8291 70.962 68.8772C73.8045 66.4695 74.4316 60.69 69.31 59.7272C67.7059 59.4269 66.0626 59.245 64.4352 59.2133C61.632 59.1584 61.6247 59.2205 61.616 56.4823C61.616 54.9031 61.6392 53.324 61.632 51.7449C61.632 50.6637 62.1081 50.3231 63.2201 50.3086C64.7763 50.2769 66.3718 50.1109 67.8815 49.724C70.1041 49.1553 71.4701 47.2211 71.4469 45.0978C71.4309 43.3685 70.0576 41.9395 67.8017 41.3001C64.396 40.3373 60.5925 41.4502 58.4876 44.9087C55.8905 49.164 53.652 53.6878 51.7227 58.2909C48.8004 65.2469 45.5297 71.8868 40.0263 77.2622C34.1745 82.9783 22.2009 85.3311 14.2761 80.9733C5.04905 75.9039 0.165529 68.1439 0.0711688 57.6746C-0.0638393 42.2787 0.0232628 26.8915 0.0871376 11.4957C0.0871376 10.6585 0.547326 9.53833 1.18317 9.02446C4.73258 6.15921 8.40102 3.42676 12.0448 0.663988C12.3859 0.404166 12.8389 0.293021 13.482 0.00144321L13.4834 0Z"
            fill="#F5F5F5"
        />
        <path
            d="M40.3457 51.5009C35.32 39.8797 24.1796 36.4529 14.9845 46.7953V43.9762C14.9845 41.4184 15.1268 38.8447 14.9453 36.2942C14.7624 33.7118 16.1053 32.1731 18.1217 31.1382C24.1477 28.0593 30.3261 28.335 36.4712 30.7585C40.2819 32.2669 43.0619 34.959 44.8083 38.6542C45.0696 39.1984 45.2844 39.9721 45.0942 40.4932C43.8153 43.9834 42.4347 47.4333 41.06 50.8759C40.9569 51.1285 40.607 51.2786 40.3457 51.4923V51.4995V51.5009Z"
            fill="#F5F5F5"
        />
        <path
            d="M45.6022 31.2103C41.2979 31.1944 38.3132 28.2022 38.3292 23.9151C38.3451 19.6512 41.3545 16.6199 45.5949 16.5968C49.9703 16.5737 52.9797 19.605 52.955 24.0032C52.9318 28.2974 49.9457 31.2276 45.6022 31.2118V31.2103Z"
            fill="#F5F5F5"
        />
        <path
            d="M86.16 42.6887H90.9317V36.5193L84.251 33.198L88.0675 27.505L107.156 36.9942L99.5214 40.7905L92.8407 37.4691V44.5868H86.16V46.4835H84.251V40.7905H86.16V42.6887Z"
            fill="#F5F5F5"
        />
        <path
            d="M105.195 70.084C105.315 72.2347 105.826 73.9798 106.725 75.3208C108.436 77.8324 111.454 79.0868 115.779 79.0868C117.715 79.0868 119.481 78.8125 121.072 78.2611C124.151 77.1944 125.69 75.2861 125.69 72.5335C125.69 70.4693 125.041 68.9999 123.744 68.1223C122.429 67.262 120.372 66.5143 117.569 65.8777L112.405 64.7172C109.033 63.9608 106.646 63.1265 105.244 62.2142C102.822 60.6322 101.612 58.2678 101.612 55.1197C101.612 51.7146 102.796 48.9201 105.165 46.7361C107.535 44.5522 110.891 43.4595 115.232 43.4595C119.227 43.4595 122.621 44.4179 125.415 46.3363C128.208 48.2546 129.605 51.3191 129.605 55.5325H124.753C124.493 53.503 123.94 51.947 123.093 50.8629C121.519 48.8854 118.846 47.8966 115.076 47.8966C112.032 47.8966 109.844 48.5332 108.512 49.8063C107.179 51.0795 106.514 52.5576 106.514 54.2435C106.514 56.1012 107.292 57.4595 108.848 58.3198C109.869 58.8698 112.177 59.5583 115.776 60.384L121.121 61.5964C123.698 62.181 125.688 62.9807 127.089 63.9955C129.511 65.7666 130.721 68.3388 130.721 71.7093C130.721 75.9054 129.185 78.9063 126.116 80.7121C123.046 82.5178 119.479 83.42 115.414 83.42C110.675 83.42 106.966 82.2162 104.284 79.8085C101.603 77.4181 100.289 74.1761 100.34 70.0825H105.192L105.195 70.084Z"
            fill="#F5F5F5"
        />
        <path
            d="M136.64 54.6823H141.258V58.6042C142.364 57.2459 143.369 56.2571 144.267 55.6379C145.806 54.5885 147.554 54.0645 149.508 54.0645C151.722 54.0645 153.503 54.6058 154.853 55.6898C155.614 56.3091 156.306 57.2213 156.929 58.4237C157.967 56.9442 159.186 55.8486 160.587 55.1341C161.988 54.421 163.562 54.0631 165.31 54.0631C169.046 54.0631 171.588 55.404 172.937 58.0874C173.663 59.5323 174.027 61.4752 174.027 63.9175V82.3114H169.175V63.1178C169.175 61.2774 168.712 60.0144 167.788 59.3259C166.861 58.6388 165.733 58.2938 164.402 58.2938C162.569 58.2938 160.991 58.9044 159.667 60.1256C158.343 61.3467 157.682 63.3849 157.682 66.24V82.3114H152.934V64.2798C152.934 62.4062 152.709 61.0378 152.259 60.1775C151.549 58.8871 150.226 58.2433 148.29 58.2433C146.526 58.2433 144.92 58.9232 143.477 60.2815C142.033 61.6398 141.311 64.0994 141.311 67.6589V82.3114H136.641V54.6823H136.64Z"
            fill="#F5F5F5"
        />
        <path
            d="M195.794 65.5688C196.866 65.4317 197.584 64.9842 197.946 64.2279C198.154 63.815 198.257 63.2218 198.257 62.4481C198.257 60.8661 197.691 59.7185 196.557 59.004C195.425 58.2909 193.803 57.933 191.693 57.933C189.254 57.933 187.525 58.5868 186.504 59.8932C185.934 60.6149 185.561 61.6903 185.388 63.1178H181.03C181.115 59.7127 182.227 57.344 184.364 56.0103C186.5 54.678 188.978 54.0111 191.797 54.0111C195.066 54.0111 197.721 54.6303 199.763 55.8688C201.786 57.1073 202.798 59.0329 202.798 61.647V77.5639C202.798 78.046 202.897 78.4329 203.096 78.7245C203.295 79.0175 203.714 79.1633 204.354 79.1633C204.562 79.1633 204.796 79.1503 205.055 79.1243C205.315 79.0983 205.591 79.0593 205.886 79.0088V82.4399C205.16 82.6463 204.605 82.7748 204.225 82.8267C203.845 82.8787 203.325 82.9047 202.669 82.9047C201.06 82.9047 199.893 82.3374 199.166 81.2029C198.786 80.6009 198.517 79.7493 198.362 78.6494C197.411 79.8879 196.043 80.9633 194.262 81.8741C192.481 82.7849 190.517 83.241 188.372 83.241C185.796 83.241 183.689 82.463 182.055 80.907C180.42 79.3509 179.603 77.4023 179.603 75.0639C179.603 72.5017 180.407 70.5155 182.016 69.1053C183.624 67.695 185.735 66.8261 188.346 66.4999L195.792 65.5717L195.794 65.5688ZM185.961 78.1326C186.947 78.9063 188.114 79.2932 189.464 79.2932C191.107 79.2932 192.699 78.915 194.237 78.1586C196.832 76.9028 198.129 74.8488 198.129 71.9936V68.2536C197.559 68.6145 196.823 68.9162 195.924 69.1558C195.024 69.3969 194.143 69.5686 193.278 69.6711L190.45 70.032C188.754 70.2557 187.483 70.6079 186.636 71.09C185.201 71.8984 184.483 73.1888 184.483 74.9599C184.483 76.3009 184.977 77.359 185.963 78.1326H185.961Z"
            fill="#F5F5F5"
        />
        <path
            d="M210.582 54.6823H215.018V59.4544C215.381 58.5262 216.273 57.3946 217.691 56.0622C219.109 54.7299 220.744 54.0631 222.595 54.0631C222.68 54.0631 222.829 54.0717 223.036 54.089C223.244 54.1064 223.598 54.141 224.1 54.1915V59.0935C223.823 59.0415 223.569 59.0083 223.335 58.991C223.102 58.9737 222.846 58.965 222.57 58.965C220.217 58.965 218.41 59.7171 217.148 61.2226C215.885 62.7281 215.254 64.4603 215.254 66.4205V82.3114H210.583V54.6823H210.582Z"
            fill="#F5F5F5"
        />
        <path
            d="M231.471 46.9685H236.193V54.6823H240.629V58.4743H236.193V76.5059C236.193 77.4686 236.521 78.1139 237.179 78.4401C237.542 78.6292 238.147 78.7245 238.995 78.7245C239.22 78.7245 239.462 78.7201 239.721 78.7115C239.98 78.7028 240.282 78.6812 240.628 78.6465V82.31C240.092 82.4644 239.533 82.577 238.954 82.6449C238.375 82.7127 237.748 82.7474 237.073 82.7474C234.894 82.7474 233.414 82.1931 232.636 81.0831C231.858 79.9745 231.469 78.5339 231.469 76.7614V58.4714H227.708V54.6794H231.469V46.9656L231.471 46.9685Z"
            fill="#F5F5F5"
        />
        <path
            d="M252.747 82.3778V56.4C255.704 56.4 258.861 56.1676 262.216 55.7028V82.3778H252.747Z"
            fill="#F5F5F5"
        />
        <path
            d="M257.468 41.0402C254.707 41.0402 252.467 43.266 252.467 46.0129C252.467 48.7598 254.705 50.9856 257.468 50.9856C260.23 50.9856 262.469 48.7598 262.469 46.0129C262.469 43.266 260.23 41.0402 257.468 41.0402ZM257.468 48.4986C256.087 48.4986 254.968 47.3857 254.968 46.0129C254.968 44.6402 256.087 43.5273 257.468 43.5273C258.848 43.5273 259.967 44.6402 259.967 46.0129C259.967 47.3857 258.848 48.4986 257.468 48.4986Z"
            fill="#F5F5F5"
        />
        <path
            d="M257.468 44.3558C257.315 44.3558 257.171 44.3818 257.031 44.4208C257.369 44.9072 257.151 45.7632 256.374 45.7632C256.161 45.7632 255.991 45.6968 255.862 45.5929C255.826 45.7286 255.801 45.8671 255.801 46.0129C255.801 46.9281 256.547 47.67 257.468 47.67C258.388 47.67 259.134 46.9281 259.134 46.0129C259.134 45.0978 258.388 44.3558 257.468 44.3558Z"
            fill="#F5F5F5"
        />
        <path
            d="M260.534 53.018C260.651 53.018 260.754 52.9429 260.792 52.8332C260.83 52.7221 260.791 52.6009 260.696 52.5316C259.795 51.8647 258.677 51.4692 257.468 51.4692C256.258 51.4692 255.141 51.8647 254.239 52.5316C254.145 52.6009 254.107 52.7221 254.143 52.8332C254.18 52.9444 254.284 53.018 254.402 53.018H260.535H260.534Z"
            fill="#F5F5F5"
        />
        <path d="M240.766 54.1266H227.41V59.1137H240.766V54.1266Z" fill="#F5F5F5" />
    </svg>
)

const SvgComponentLightTheme = (props: SVGProps<SVGSVGElement>) => (
    <svg

        viewBox="0 0 263 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.7483 0.464912V2.81774C13.7483 20.8421 13.8282 38.8593 13.7091 56.8837C13.6612 63.958 17.4806 68.5367 22.8882 69.729C29.2002 71.119 35.068 68.5684 37.9191 62.4338C40.9125 55.9916 43.6127 49.4153 46.2243 42.8071C49.4238 34.7151 55.1885 30.2852 63.9394 29.4567C69.6954 28.9125 75.2147 29.5909 80.1694 32.4966C86.5932 36.2697 89.6505 44.9001 84.4809 51.1286C83.3051 52.5418 81.6226 53.5363 80.0736 54.808C81.2567 55.6611 82.5749 56.5762 83.8611 57.5318C89.7608 61.9055 89.6897 71.1349 84.8134 76.1798C79.0806 82.1095 71.8236 83.3725 64.0018 83.0174C57.3472 82.7172 52.1313 79.7566 47.962 74.7031C46.9211 73.4401 46.8747 72.3979 47.692 70.992C49.479 67.9044 51.0904 64.7072 52.6786 61.7308C54.7197 63.9263 56.6562 66.4278 59.0225 68.4573C62.8172 71.718 67.7487 72.294 71.2269 69.3421C74.0694 66.9344 74.6965 61.1549 69.5749 60.1921C67.9708 59.8918 66.3274 59.71 64.7001 59.6782C61.8969 59.6234 61.8896 59.6854 61.8809 56.9472C61.8809 55.3681 61.9041 53.7889 61.8969 52.2098C61.8969 51.1286 62.373 50.788 63.485 50.7736C65.0412 50.7418 66.6367 50.5758 68.1464 50.189C70.369 49.6202 71.735 47.686 71.7118 45.5627C71.6958 43.8334 70.3225 42.4044 68.0666 41.765C64.6609 40.8022 60.8574 41.9151 58.7525 45.3736C56.1554 49.6289 53.9169 54.1527 51.9875 58.7558C49.0653 65.7118 45.7946 72.3517 40.2912 77.7271C34.4394 83.4432 22.4658 85.796 14.5409 81.4383C5.31394 76.3689 0.430422 68.6088 0.336061 58.1395C0.201053 42.7436 0.288155 27.3564 0.35203 11.9606C0.35203 11.1234 0.812219 10.0032 1.44806 9.48938C4.99747 6.62412 8.66592 3.89167 12.3097 1.1289C12.6508 0.869079 13.1038 0.757933 13.7469 0.466356L13.7483 0.464912Z"
            fill="#181818"
        />
        <path
            d="M40.6106 51.9659C35.5848 40.3446 24.4445 36.9179 15.2494 47.2602V44.4411C15.2494 41.8833 15.3917 39.3097 15.2102 36.7591C15.0273 34.1767 16.3701 32.638 18.3866 31.6031C24.4126 28.5242 30.591 28.7999 36.736 31.2234C40.5468 32.7318 43.3268 35.4239 45.0732 39.1191C45.3345 39.6633 45.5493 40.437 45.3591 40.9581C44.0802 44.4484 42.6996 47.8982 41.3249 51.3408C41.2218 51.5934 40.8719 51.7436 40.6106 51.9572V51.9644V51.9659Z"
            fill="#181818"
        />
        <path
            d="M45.8671 31.6752C41.5628 31.6594 38.5781 28.6671 38.5941 24.38C38.61 20.1161 41.6194 17.0848 45.8598 17.0617C50.2352 17.0386 53.2446 20.0699 53.2199 24.4681C53.1967 28.7623 50.2106 31.6926 45.8671 31.6767V31.6752Z"
            fill="#181818"
        />
        <path
            d="M86.4247 43.1536H91.1965V36.9843L84.5157 33.6629L88.3323 27.9699L107.421 37.4591L99.7862 41.2554L93.1055 37.934V45.0517H86.4247V46.9484H84.5157V41.2554H86.4247V43.1536Z"
            fill="#181818"
        />
        <path
            d="M105.459 70.5489C105.58 72.6996 106.091 74.4447 106.99 75.7857C108.701 78.2973 111.719 79.5517 116.044 79.5517C117.98 79.5517 119.746 79.2774 121.337 78.726C124.416 77.6593 125.955 75.7511 125.955 72.9984C125.955 70.9343 125.306 69.4648 124.009 68.5872C122.694 67.7269 120.637 66.9792 117.834 66.3426L112.67 65.1821C109.298 64.4257 106.911 63.5914 105.509 62.6792C103.087 61.0971 101.877 58.7328 101.877 55.5846C101.877 52.1795 103.061 49.385 105.43 47.201C107.8 45.0171 111.156 43.9244 115.496 43.9244C119.492 43.9244 122.886 44.8828 125.68 46.8012C128.473 48.7195 129.87 51.784 129.87 55.9974H125.018C124.758 53.9679 124.205 52.4119 123.357 51.3279C121.784 49.3503 119.111 48.3616 115.341 48.3616C112.297 48.3616 110.109 48.9981 108.777 50.2712C107.444 51.5444 106.779 53.0225 106.779 54.7084C106.779 56.5661 107.557 57.9244 109.113 58.7847C110.134 59.3347 112.442 60.0232 116.041 60.8489L121.386 62.0614C123.963 62.646 125.953 63.4456 127.354 64.4604C129.775 66.2315 130.986 68.8037 130.986 72.1742C130.986 76.3703 129.45 79.3712 126.381 81.177C123.311 82.9828 119.744 83.8849 115.679 83.8849C110.94 83.8849 107.23 82.6811 104.549 80.2734C101.868 77.883 100.554 74.641 100.605 70.5474H105.457L105.459 70.5489Z"
            fill="#181818"
        />
        <path
            d="M136.905 55.1472H141.523V59.0691C142.629 57.7108 143.633 56.722 144.532 56.1028C146.071 55.0534 147.819 54.5294 149.773 54.5294C151.986 54.5294 153.768 55.0707 155.118 56.1548C155.878 56.774 156.571 57.6862 157.194 58.8886C158.232 57.4091 159.451 56.3135 160.852 55.599C162.253 54.886 163.826 54.528 165.574 54.528C169.311 54.528 171.853 55.8689 173.202 58.5523C173.927 59.9972 174.292 61.9401 174.292 64.3824V82.7763H169.44V63.5828C169.44 61.7424 168.977 60.4793 168.052 59.7908C167.126 59.1037 165.998 58.7587 164.667 58.7587C162.833 58.7587 161.255 59.3693 159.932 60.5905C158.608 61.8116 157.947 63.8498 157.947 66.7049V82.7763H153.199V64.7447C153.199 62.8711 152.974 61.5027 152.523 60.6424C151.814 59.352 150.491 58.7082 148.555 58.7082C146.791 58.7082 145.185 59.3881 143.742 60.7464C142.298 62.1047 141.576 64.5643 141.576 68.1239V82.7763H136.906V55.1472H136.905Z"
            fill="#181818"
        />
        <path
            d="M196.059 66.0337C197.131 65.8966 197.849 65.4491 198.211 64.6928C198.419 64.2799 198.522 63.6867 198.522 62.913C198.522 61.331 197.956 60.1834 196.822 59.4689C195.69 58.7559 194.068 58.3979 191.958 58.3979C189.519 58.3979 187.79 59.0518 186.769 60.3581C186.199 61.0798 185.826 62.1552 185.653 63.5828H181.295C181.38 60.1777 182.492 57.8089 184.629 56.4752C186.765 55.1429 189.243 54.476 192.062 54.476C195.331 54.476 197.986 55.0953 200.028 56.3337C202.051 57.5722 203.063 59.4978 203.063 62.1119V78.0288C203.063 78.5109 203.162 78.8978 203.361 79.1894C203.56 79.4824 203.979 79.6282 204.619 79.6282C204.827 79.6282 205.061 79.6152 205.32 79.5892C205.58 79.5632 205.856 79.5242 206.151 79.4737V82.9048C205.425 83.1112 204.87 83.2397 204.49 83.2917C204.11 83.3436 203.59 83.3696 202.934 83.3696C201.325 83.3696 200.158 82.8023 199.431 81.6678C199.051 81.0659 198.782 80.2142 198.627 79.1143C197.676 80.3528 196.308 81.4282 194.527 82.339C192.746 83.2498 190.782 83.7059 188.638 83.7059C186.061 83.7059 183.954 82.9279 182.32 81.3719C180.685 79.8158 179.868 77.8672 179.868 75.5288C179.868 72.9666 180.672 70.9804 182.281 69.5702C183.889 68.1599 186 67.291 188.611 66.9648L196.057 66.0366L196.059 66.0337ZM186.226 78.5976C187.212 79.3712 188.379 79.7581 189.729 79.7581C191.373 79.7581 192.964 79.3799 194.502 78.6235C197.097 77.3677 198.394 75.3137 198.394 72.4585V68.7186C197.824 69.0794 197.088 69.3811 196.189 69.6207C195.289 69.8618 194.408 70.0336 193.543 70.136L190.715 70.4969C189.019 70.7206 187.748 71.0728 186.901 71.5549C185.466 72.3633 184.748 73.6537 184.748 75.4248C184.748 76.7658 185.242 77.8239 186.228 78.5976H186.226Z"
            fill="#181818"
        />
        <path
            d="M210.847 55.1472H215.283V59.9193C215.646 58.9911 216.538 57.8595 217.956 56.5272C219.374 55.1949 221.009 54.528 222.86 54.528C222.946 54.528 223.094 54.5366 223.301 54.554C223.509 54.5713 223.863 54.6059 224.365 54.6564V59.5584C224.088 59.5064 223.834 59.4732 223.6 59.4559C223.367 59.4386 223.111 59.4299 222.835 59.4299C220.482 59.4299 218.675 60.182 217.413 61.6875C216.15 63.193 215.519 64.9252 215.519 66.8854V82.7763H210.848V55.1472H210.847Z"
            fill="#181818"
        />
        <path
            d="M231.736 47.4334H236.458V55.1472H240.894V58.9392H236.458V76.9708C236.458 77.9336 236.786 78.5788 237.444 78.905C237.807 79.0941 238.412 79.1894 239.26 79.1894C239.485 79.1894 239.727 79.185 239.986 79.1764C240.245 79.1677 240.547 79.1461 240.893 79.1114V82.7749C240.357 82.9294 239.798 83.0419 239.219 83.1098C238.64 83.1776 238.013 83.2123 237.338 83.2123C235.159 83.2123 233.679 82.658 232.901 81.548C232.123 80.4394 231.734 78.9988 231.734 77.2263V58.9363H227.973V55.1443H231.734V47.4305L231.736 47.4334Z"
            fill="#181818"
        />
        <path
            d="M253.012 82.8427V56.8649C255.969 56.8649 259.126 56.6325 262.481 56.1677V82.8427H253.012Z"
            fill="#181818"
        />
        <path
            d="M257.733 41.5052C254.971 41.5052 252.731 43.731 252.731 46.4779C252.731 49.2247 254.97 51.4505 257.733 51.4505C260.495 51.4505 262.734 49.2247 262.734 46.4779C262.734 43.731 260.495 41.5052 257.733 41.5052ZM257.733 48.9635C256.352 48.9635 255.233 47.8506 255.233 46.4779C255.233 45.1051 256.352 43.9922 257.733 43.9922C259.113 43.9922 260.232 45.1051 260.232 46.4779C260.232 47.8506 259.113 48.9635 257.733 48.9635Z"
            fill="#181818"
        />
        <path
            d="M257.733 44.8208C257.58 44.8208 257.436 44.8467 257.296 44.8857C257.634 45.3722 257.416 46.2281 256.639 46.2281C256.426 46.2281 256.256 46.1617 256.127 46.0578C256.091 46.1935 256.066 46.3321 256.066 46.4778C256.066 47.393 256.812 48.1349 257.733 48.1349C258.653 48.1349 259.399 47.393 259.399 46.4778C259.399 45.5627 258.653 44.8208 257.733 44.8208Z"
            fill="#181818"
        />
        <path
            d="M260.799 53.4829C260.916 53.4829 261.019 53.4079 261.057 53.2982C261.095 53.187 261.055 53.0658 260.961 52.9965C260.06 52.3296 258.942 51.9341 257.733 51.9341C256.523 51.9341 255.405 52.3296 254.504 52.9965C254.41 53.0658 254.372 53.187 254.408 53.2982C254.444 53.4093 254.549 53.4829 254.667 53.4829H260.8H260.799Z"
            fill="#181818"
        />
        <path
            d="M241.031 54.5915H227.675V59.5786H241.031V54.5915Z"
            fill="#181818"
        />
    </svg>
)



const SmartiFullLogoSVG = () => {
    const { theme } = useContext(ThemeContext)

    return (
        <>
            {
                theme === "light"

                    ?
                    <SvgComponentLightTheme /> : <SvgComponentDarkTheme />}
        </>
    )
}

export default SmartiFullLogoSVG



