import React , {SVGProps ,useContext } from "react"
import { ThemeContext } from '../../Context/ThemeContext'

const SvgComponentDarkTheme = (props: SVGProps<SVGSVGElement>) => (

  <svg
    viewBox="0 0 200 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2905_547)">
      <path
        d="M10.2146 0C10.2146 0.699867 10.2146 1.2233 10.2146 1.75261C10.2146 15.1795 10.2748 28.6005 10.1845 42.0273C10.1484 47.2969 13.0418 50.708 17.1383 51.5961C21.9206 52.6312 26.3659 50.7316 28.5255 46.1618C30.7933 41.3627 32.8385 36.4637 34.8176 31.5411C37.2418 25.5128 41.609 22.2134 48.2379 21.5959C52.5991 21.1901 56.7798 21.6959 60.5334 23.8602C65.3999 26.6714 67.7158 33.0996 63.7998 37.7399C62.9095 38.7926 61.6342 39.5337 60.4612 40.4806C61.3575 41.1157 62.3561 41.798 63.3306 42.5096C67.8 45.7678 67.7459 52.643 64.0524 56.4011C59.7093 60.8179 54.2112 61.7589 48.2861 61.4942C43.2452 61.2707 39.293 59.0653 36.135 55.3013C35.3469 54.3603 35.3108 53.584 35.9304 52.5371C37.2839 50.2375 38.505 47.8556 39.7081 45.6384C41.2541 47.2734 42.7218 49.1377 44.5144 50.6492C47.3898 53.0782 51.1253 53.5075 53.7601 51.3079C55.9136 49.5141 56.3888 45.2091 52.5089 44.4916C51.2938 44.2681 50.0486 44.1328 48.8154 44.1093C46.692 44.0681 46.686 44.1152 46.6799 42.0744C46.6799 40.8981 46.698 39.7219 46.692 38.5456C46.692 37.7399 47.0529 37.487 47.8951 37.4752C49.0741 37.4517 50.2832 37.3282 51.4261 37.04C53.1104 36.6166 54.1451 35.1757 54.127 33.5936C54.115 32.3056 53.0743 31.2411 51.3659 30.7648C48.7853 30.0472 45.904 30.8765 44.3099 33.4525C42.3428 36.6225 40.6465 39.9924 39.1848 43.4212C36.9711 48.6025 34.4927 53.5487 30.3241 57.5538C25.8907 61.8118 16.8195 63.5644 10.8161 60.318C3.82624 56.5422 0.126774 50.761 0.0545896 42.9624C-0.0476722 31.494 0.0184972 20.0315 0.0666204 8.56308C0.0666204 7.93967 0.415514 7.10453 0.896746 6.72225C3.58563 4.58737 6.36474 2.55246 9.12581 0.494024C9.38447 0.299943 9.72735 0.217606 10.2146 0Z"
        fill="#F5F5F5"
      />
      <path
        d="M30.5642 38.3633C26.7564 29.7061 18.3168 27.1537 11.351 34.8581C11.351 33.9348 11.351 33.3466 11.351 32.7585C11.351 30.853 11.4593 28.9357 11.3209 27.0361C11.1826 25.1129 12.1992 23.9661 13.7271 23.1956C18.2928 20.9019 22.9728 21.1078 27.6287 22.9133C30.5161 24.0366 32.6215 26.0421 33.9448 28.7945C34.1433 29.2004 34.3058 29.7767 34.1614 30.1649C33.1929 32.7644 32.1462 35.3345 31.1056 37.8987C31.0274 38.0869 30.7627 38.1986 30.5642 38.3574V38.3633Z"
        fill="#F5F5F5"
      />
      <path
        d="M171.89 46.5617C172.233 50.1846 170.368 51.8901 166.639 51.4491C166.278 51.4079 165.875 51.7372 165.49 51.8901C165.802 52.2136 166.085 52.7547 166.44 52.8076C167.421 52.9605 168.431 52.89 169.43 52.9429C171.457 53.0429 172.834 54.0662 173.033 55.5894C173.28 57.4714 172.245 58.7712 170.164 59.4417C168.179 60.0768 166.26 60.0415 164.305 59.3887C162.295 58.7183 161.718 56.9362 162.903 55.1895C163.174 54.7955 163.071 54.1309 163.065 53.5898C163.065 53.1076 162.741 52.5194 162.927 52.1666C163.679 50.7551 163.276 49.4377 163.071 47.9909C162.698 45.362 165.141 43.4741 168.064 43.8093C169.201 43.9387 170.356 43.9505 171.493 44.074C172.155 44.1446 173.225 43.6917 173.183 44.8797C173.159 45.4561 172.594 46.0148 172.275 46.5853C172.143 46.5794 172.01 46.5676 171.884 46.5617H171.89ZM167.691 54.6132C166.163 54.8955 165.291 55.4601 165.333 56.4246C165.393 57.6773 166.404 57.8008 167.366 57.8714C168.78 57.9713 170.506 56.9304 170.091 56.3011C169.598 55.5483 168.515 55.1601 167.691 54.6132ZM169.315 47.8027C169.315 46.5735 168.732 45.9913 167.571 45.9677C166.41 45.9442 165.586 46.6676 165.682 47.738C165.778 48.7966 166.404 49.4612 167.541 49.4494C168.672 49.4377 169.285 48.8378 169.315 47.8086V47.8027Z"
        fill="#F5F5F5"
      />
      <path
        d="M34.5464 23.2485C31.2861 23.2368 29.0243 21.0078 29.0363 17.8143C29.0483 14.6384 31.3282 12.38 34.5404 12.3623C37.8549 12.3447 40.1347 14.6031 40.1167 17.8789C40.0986 21.0783 37.8368 23.2603 34.5464 23.2485Z"
        fill="#F5F5F5"
      />
      <path
        d="M116.945 55.2895C116.302 55.3601 115.808 55.4365 115.321 55.4601C114.299 55.5071 113.763 55.1425 113.817 54.0074C113.902 52.2489 113.865 50.4787 113.829 48.7202C113.799 47.1675 113.071 46.25 112.007 46.3147C110.876 46.3794 109.595 47.7027 109.582 48.8907C109.558 50.9903 109.576 53.0899 109.576 55.3307H106.581V39.2102C106.803 39.1396 106.984 39.0514 107.17 39.0338C109.462 38.7691 109.57 38.8691 109.576 41.0569C109.576 42.3214 109.576 43.5858 109.576 44.8856C110.749 44.4857 111.766 43.9387 112.831 43.8211C115.111 43.5682 116.843 45.1267 116.927 47.4851C117.024 50.0258 116.951 52.5724 116.951 55.3013L116.945 55.2895Z"
        fill="#F5F5F5"
      />
      <path
        d="M143.154 49.6318C143.154 53.5134 140.947 55.7953 137.205 55.7953C133.692 55.7953 131.545 53.5428 131.563 49.8611C131.581 46.0324 133.843 43.7505 137.614 43.7623C141.338 43.774 143.166 45.709 143.16 49.6259L143.154 49.6318ZM140.008 49.8141C140.014 47.6086 139.455 46.4794 138.204 46.1795C136.562 45.7913 135.202 46.6911 134.811 48.432C134.264 50.8668 135.172 53.0664 136.838 53.3369C136.934 53.3546 137.037 53.3487 137.139 53.3546C139.13 53.4663 140.002 52.3901 140.008 49.8082V49.8141Z"
        fill="#F5F5F5"
      />
      <path
        d="M161.634 49.7435C161.586 53.5428 159.234 55.9835 155.486 55.76C151.57 55.5306 149.91 53.7369 150.006 49.7435C150.102 45.8795 151.763 43.8152 156.106 43.7799C159.751 43.7505 161.682 45.8913 161.634 49.7435ZM155.793 46.1501C154.554 46.0619 153.693 46.7382 153.495 47.7792C153.26 49.026 153.242 50.3728 153.447 51.6255C153.663 52.9488 154.746 53.531 156.112 53.3428C157.357 53.1723 158.211 52.5018 158.265 51.1785C158.307 50.214 158.349 49.2436 158.253 48.285C158.103 46.8382 157.249 46.1501 155.799 46.1501H155.793Z"
        fill="#F5F5F5"
      />
      <path
        d="M126.841 55.3895C126.841 53.6133 126.841 52.0078 126.841 50.4022C126.841 49.914 126.895 49.4141 126.829 48.9378C126.672 47.7792 126.492 46.5323 125.03 46.2853C123.953 46.103 122.462 47.6909 122.407 49.0201C122.347 50.6316 122.311 52.2548 122.401 53.8662C122.474 55.2189 121.848 55.5718 120.615 55.4777C119.749 55.413 119.418 55.0778 119.43 54.1956C119.478 51.355 119.406 48.5202 119.478 45.6796C119.49 45.1267 119.809 44.2622 120.212 44.1093C121.054 43.7858 122.016 43.9093 122.263 45.2032C123.743 44.2622 125.168 43.4329 126.997 43.9152C128.423 44.2916 129.379 45.1502 129.506 46.497C129.752 49.1671 129.794 51.8607 129.848 54.5426C129.848 54.819 129.373 55.2895 129.054 55.3542C128.399 55.4836 127.695 55.3895 126.841 55.3895Z"
        fill="#F5F5F5"
      />
      <path
        d="M186.477 50.3904C185.629 50.3904 184.775 50.414 183.927 50.3904C182.928 50.361 182.694 50.8786 182.916 51.702C183.205 52.7724 183.969 53.4016 185.07 53.4193C186.153 53.4369 187.241 53.2193 188.33 53.1664C188.781 53.1429 189.244 53.284 189.702 53.3546C189.491 53.9427 189.467 54.8014 189.04 55.0778C187.127 56.3246 183.247 55.8718 181.382 54.5014C179.872 53.384 179.241 49.9611 179.812 48.1438C180.991 44.3798 183.265 43.1448 187.187 44.074C189.383 44.5915 191.079 47.885 190.093 49.8494C189.942 50.1493 189.305 50.3199 188.865 50.3669C188.071 50.4493 187.265 50.3904 186.465 50.3904H186.477ZM182.982 48.7261C184.198 48.7261 185.346 48.7319 186.489 48.7261C187.338 48.7202 187.512 48.2614 187.283 47.5498C186.904 46.3383 186.062 45.8207 184.787 45.9795C183.47 46.1442 182.904 46.9264 182.982 48.7319V48.7261Z"
        fill="#F5F5F5"
      />
      <path
        d="M94.5922 48.5378C94.6282 50.3728 94.6282 50.3787 92.6853 50.3845C91.2356 50.3845 89.7859 50.4081 88.3361 50.3845C87.2413 50.3669 86.8804 50.9962 87.3917 51.7549C87.8549 52.4489 88.6549 53.1899 89.4309 53.3546C90.4475 53.5722 91.5905 53.2075 92.6793 53.1781C93.1605 53.1664 93.6477 53.3546 94.129 53.4546C93.8763 54.0309 93.762 54.966 93.3409 55.1307C91.1453 55.9776 88.8835 56.1305 86.6639 55.0837C84.4442 54.0309 83.6742 51.0844 84.1013 48.7202C84.8171 44.7092 87.5421 43.0624 91.5845 44.0681C93.4673 44.5386 94.544 46.1383 94.5922 48.5378ZM87.3015 48.726C88.5286 48.726 89.6174 48.7143 90.7062 48.726C91.7108 48.7437 91.8852 48.2261 91.6566 47.4028C91.2957 46.0854 90.2851 45.8148 89.1121 45.9854C87.6564 46.1971 87.2413 47.2028 87.3015 48.7202V48.726Z"
        fill="#F5F5F5"
      />
      <path
        d="M81.4245 42.5802C81.4245 46.1912 81.4245 49.6082 81.4245 53.0252C81.4245 55.6894 81.238 55.8306 78.2604 55.2131V42.6037C77.0152 42.6037 75.9324 42.5625 74.8556 42.6155C73.8511 42.6625 73.7007 42.0861 73.6826 41.3216C73.6646 40.5276 73.8571 40.0101 74.8436 40.0218C78.3446 40.0512 81.8515 40.0336 85.527 40.0336V42.5861H81.4184L81.4245 42.5802Z"
        fill="#F5F5F5"
      />
      <path
        d="M198.995 46.4088C197.997 46.25 197.034 45.9913 196.078 45.9971C195.615 45.9971 195.158 46.5147 194.694 46.797C194.989 47.2557 195.212 47.7968 195.603 48.1497C195.946 48.4555 196.481 48.5614 196.944 48.7319C199.224 49.5729 200.229 50.8727 199.964 52.6488C199.717 54.2779 198.057 55.6718 195.94 55.7482C194.827 55.7894 193.66 55.5424 192.583 55.213C191.482 54.8778 191.59 53.9192 191.819 52.8017C192.348 52.9488 192.824 53.137 193.317 53.1958C194.231 53.3134 195.2 53.5545 196.066 53.3722C197.167 53.1311 197.299 52.0313 196.349 51.4138C195.621 50.9374 194.761 50.6492 193.948 50.2846C192.481 49.6318 191.578 48.332 191.681 47.0146C191.801 45.4855 192.884 44.2387 194.604 44.0034C195.759 43.8446 196.986 43.9093 198.141 44.0975C199.41 44.3034 199.681 45.2855 198.995 46.403V46.4088Z"
        fill="#F5F5F5"
      />
      <path
        d="M104.74 52.1901C105.185 54.8425 102.923 56.3893 100.09 55.6836C96.6975 54.8367 95.6388 52.0078 96.108 48.6731C96.6494 44.8092 99.5909 42.9919 103.308 44.1328C103.922 44.321 104.487 44.9562 104.848 45.5208C105.047 45.8266 104.764 46.4265 104.698 46.897C104.253 46.8264 103.73 46.8852 103.381 46.6735C101.101 45.2738 99.3262 46.8441 99.1999 48.9201C99.1578 49.6024 99.1518 50.2964 99.254 50.9668C99.5668 52.9253 101.161 53.7369 103.02 52.937C103.579 52.6959 104.133 52.4548 104.74 52.196V52.1901Z"
        fill="#F5F5F5"
      />
      <path
        d="M145.218 55.1543V39.075C145.825 39.0338 146.361 38.9985 146.896 38.9632C147.925 38.8985 148.274 39.3455 148.262 40.3629C148.213 44.6268 148.244 48.8907 148.244 53.1546C148.244 55.5895 147.822 55.8835 145.224 55.1543H145.218Z"
        fill="#F5F5F5"
      />
      <path
        d="M174.693 49.667C174.693 48.2026 174.723 46.7323 174.687 45.2679C174.657 44.1563 175.373 44.0152 176.245 43.9858C177.166 43.9505 177.821 44.1857 177.815 45.2502C177.815 48.232 177.797 51.2138 177.827 54.1956C177.839 55.2895 177.154 55.5071 176.269 55.4836C175.439 55.4601 174.657 55.3542 174.687 54.2132C174.729 52.7018 174.699 51.1844 174.693 49.667Z"
        fill="#F5F5F5"
      />
      <path
        d="M178.056 40.9099C178.104 42.0155 177.256 42.3155 176.317 42.3214C175.403 42.3214 174.525 42.1155 174.513 40.8922C174.501 39.6572 175.253 39.2925 176.323 39.3043C177.322 39.3161 178.074 39.6689 178.056 40.9099Z"
        fill="#F5F5F5"
      />
    </g>
    <defs>
      <clipPath id="clip0_2905_547">
        <rect width={200} height={62} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
const SvgComponentLightTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg

    viewBox="0 0 200 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_346_248)">
      <path
        d="M10.2146 0C10.2146 0.699867 10.2146 1.2233 10.2146 1.75261C10.2146 15.1795 10.2748 28.6005 10.1845 42.0273C10.1484 47.2969 13.0418 50.708 17.1383 51.5961C21.9206 52.6312 26.3659 50.7316 28.5255 46.1618C30.7933 41.3627 32.8385 36.4637 34.8176 31.5411C37.2418 25.5128 41.609 22.2134 48.2379 21.5959C52.5991 21.1901 56.7798 21.6959 60.5334 23.8602C65.3999 26.6714 67.7158 33.0996 63.7998 37.7399C62.9095 38.7926 61.6342 39.5337 60.4612 40.4806C61.3575 41.1157 62.3561 41.798 63.3306 42.5096C67.8 45.7678 67.7459 52.643 64.0524 56.4011C59.7093 60.8179 54.2112 61.7589 48.2861 61.4942C43.2452 61.2707 39.293 59.0653 36.135 55.3013C35.3469 54.3603 35.3108 53.584 35.9304 52.5371C37.2839 50.2375 38.505 47.8556 39.7081 45.6384C41.2541 47.2734 42.7218 49.1377 44.5144 50.6492C47.3898 53.0782 51.1253 53.5075 53.7601 51.3079C55.9136 49.5141 56.3888 45.2091 52.5089 44.4916C51.2938 44.2681 50.0486 44.1328 48.8154 44.1093C46.692 44.0681 46.686 44.1152 46.6799 42.0744C46.6799 40.8981 46.698 39.7219 46.692 38.5456C46.692 37.7399 47.0529 37.487 47.8951 37.4752C49.0741 37.4517 50.2832 37.3282 51.4261 37.04C53.1104 36.6166 54.1451 35.1757 54.127 33.5936C54.115 32.3056 53.0743 31.2411 51.3659 30.7648C48.7853 30.0472 45.904 30.8765 44.3099 33.4525C42.3428 36.6225 40.6465 39.9924 39.1848 43.4212C36.9711 48.6025 34.4927 53.5487 30.3241 57.5538C25.8907 61.8118 16.8195 63.5644 10.8161 60.318C3.82624 56.5422 0.126774 50.761 0.0545896 42.9624C-0.0476722 31.494 0.0184972 20.0315 0.0666204 8.56308C0.0666204 7.93967 0.415514 7.10454 0.896746 6.72225C3.58563 4.58737 6.36474 2.55246 9.12581 0.494024C9.38447 0.299943 9.72735 0.217606 10.2146 0Z"
        fill="#181818"
      />
      <path
        d="M30.5642 38.3633C26.7564 29.7061 18.3168 27.1537 11.351 34.8581C11.351 33.9348 11.351 33.3466 11.351 32.7585C11.351 30.853 11.4593 28.9357 11.3209 27.0361C11.1826 25.1129 12.1992 23.9661 13.7271 23.1956C18.2928 20.9019 22.9728 21.1078 27.6287 22.9133C30.5161 24.0366 32.6215 26.0421 33.9448 28.7945C34.1433 29.2004 34.3058 29.7767 34.1614 30.1649C33.1929 32.7644 32.1462 35.3345 31.1056 37.8987C31.0274 38.0869 30.7627 38.1986 30.5642 38.3574V38.3633Z"
        fill="#181818"
      />
      <path
        d="M171.89 46.5617C172.233 50.1846 170.368 51.8901 166.639 51.4491C166.278 51.4079 165.875 51.7372 165.49 51.8901C165.802 52.2136 166.085 52.7547 166.44 52.8076C167.421 52.9605 168.431 52.89 169.43 52.9429C171.457 53.0429 172.834 54.0662 173.033 55.5894C173.28 57.4714 172.245 58.7712 170.164 59.4417C168.179 60.0768 166.26 60.0415 164.305 59.3887C162.295 58.7183 161.718 56.9362 162.903 55.1895C163.174 54.7955 163.071 54.1309 163.065 53.5898C163.065 53.1076 162.741 52.5194 162.927 52.1666C163.679 50.7551 163.276 49.4377 163.071 47.9909C162.698 45.362 165.141 43.4741 168.064 43.8093C169.201 43.9387 170.356 43.9505 171.493 44.074C172.155 44.1446 173.225 43.6917 173.183 44.8797C173.159 45.4561 172.594 46.0148 172.275 46.5853C172.143 46.5794 172.01 46.5676 171.884 46.5617H171.89ZM167.691 54.6132C166.163 54.8955 165.291 55.4601 165.333 56.4246C165.393 57.6773 166.404 57.8008 167.366 57.8714C168.78 57.9713 170.506 56.9304 170.091 56.3011C169.598 55.5483 168.515 55.1601 167.691 54.6132ZM169.315 47.8027C169.315 46.5735 168.732 45.9913 167.571 45.9677C166.41 45.9442 165.586 46.6676 165.682 47.738C165.778 48.7966 166.404 49.4612 167.541 49.4494C168.672 49.4377 169.285 48.8378 169.315 47.8086V47.8027Z"
        fill="#181818"
      />
      <path
        d="M34.5465 23.2485C31.2862 23.2368 29.0244 21.0078 29.0364 17.8143C29.0485 14.6384 31.3283 12.38 34.5405 12.3623C37.855 12.3447 40.1348 14.6031 40.1168 17.8789C40.0987 21.0783 37.837 23.2603 34.5465 23.2485Z"
        fill="#181818"
      />
      <path
        d="M116.945 55.2895C116.302 55.3601 115.808 55.4365 115.321 55.4601C114.299 55.5071 113.763 55.1425 113.817 54.0074C113.902 52.2489 113.865 50.4787 113.829 48.7202C113.799 47.1675 113.071 46.25 112.007 46.3147C110.876 46.3794 109.595 47.7027 109.582 48.8907C109.558 50.9903 109.576 53.0899 109.576 55.3307H106.581V39.2102C106.803 39.1396 106.984 39.0514 107.17 39.0338C109.462 38.7691 109.57 38.8691 109.576 41.0569C109.576 42.3214 109.576 43.5858 109.576 44.8856C110.749 44.4857 111.766 43.9387 112.831 43.8211C115.111 43.5682 116.843 45.1267 116.927 47.4851C117.024 50.0258 116.951 52.5724 116.951 55.3013L116.945 55.2895Z"
        fill="#181818"
      />
      <path
        d="M143.154 49.6318C143.154 53.5134 140.947 55.7953 137.205 55.7953C133.692 55.7953 131.545 53.5428 131.563 49.8611C131.581 46.0324 133.843 43.7505 137.614 43.7623C141.338 43.774 143.166 45.709 143.16 49.6259L143.154 49.6318ZM140.008 49.8141C140.014 47.6086 139.455 46.4794 138.204 46.1795C136.562 45.7913 135.202 46.6911 134.811 48.432C134.264 50.8668 135.172 53.0664 136.838 53.3369C136.934 53.3546 137.037 53.3487 137.139 53.3546C139.13 53.4663 140.002 52.3901 140.008 49.8082V49.8141Z"
        fill="#181818"
      />
      <path
        d="M161.634 49.7435C161.586 53.5428 159.234 55.9835 155.486 55.76C151.57 55.5306 149.91 53.7369 150.006 49.7435C150.102 45.8795 151.763 43.8152 156.106 43.7799C159.751 43.7505 161.682 45.8913 161.634 49.7435ZM155.793 46.1501C154.554 46.0619 153.693 46.7382 153.495 47.7792C153.26 49.026 153.242 50.3728 153.447 51.6255C153.663 52.9488 154.746 53.531 156.112 53.3428C157.357 53.1723 158.211 52.5018 158.265 51.1785C158.307 50.214 158.349 49.2436 158.253 48.285C158.103 46.8382 157.249 46.1501 155.799 46.1501H155.793Z"
        fill="#181818"
      />
      <path
        d="M126.841 55.3895C126.841 53.6133 126.841 52.0078 126.841 50.4022C126.841 49.914 126.895 49.4141 126.829 48.9378C126.672 47.7792 126.492 46.5323 125.03 46.2853C123.953 46.103 122.462 47.6909 122.407 49.0201C122.347 50.6316 122.311 52.2548 122.401 53.8662C122.474 55.2189 121.848 55.5718 120.615 55.4777C119.749 55.413 119.418 55.0778 119.43 54.1956C119.478 51.355 119.406 48.5202 119.478 45.6796C119.49 45.1267 119.809 44.2622 120.212 44.1093C121.054 43.7858 122.016 43.9093 122.263 45.2032C123.743 44.2622 125.168 43.4329 126.997 43.9152C128.423 44.2916 129.379 45.1502 129.506 46.497C129.752 49.1671 129.794 51.8607 129.848 54.5426C129.848 54.819 129.373 55.2895 129.054 55.3542C128.399 55.4836 127.695 55.3895 126.841 55.3895Z"
        fill="#181818"
      />
      <path
        d="M186.477 50.3904C185.629 50.3904 184.775 50.414 183.927 50.3904C182.928 50.361 182.694 50.8786 182.916 51.702C183.205 52.7724 183.969 53.4016 185.07 53.4193C186.153 53.4369 187.241 53.2193 188.33 53.1664C188.781 53.1429 189.244 53.284 189.702 53.3546C189.491 53.9427 189.467 54.8014 189.04 55.0778C187.127 56.3246 183.247 55.8718 181.382 54.5014C179.872 53.384 179.241 49.9611 179.812 48.1438C180.991 44.3798 183.265 43.1448 187.187 44.074C189.383 44.5915 191.079 47.885 190.093 49.8494C189.942 50.1493 189.305 50.3199 188.865 50.3669C188.071 50.4493 187.265 50.3904 186.465 50.3904H186.477ZM182.982 48.7261C184.198 48.7261 185.346 48.7319 186.489 48.7261C187.338 48.7202 187.512 48.2614 187.283 47.5498C186.904 46.3383 186.062 45.8207 184.787 45.9795C183.47 46.1442 182.904 46.9264 182.982 48.7319V48.7261Z"
        fill="#181818"
      />
      <path
        d="M94.5922 48.5378C94.6282 50.3728 94.6282 50.3787 92.6853 50.3846C91.2356 50.3846 89.7859 50.4081 88.3361 50.3846C87.2413 50.3669 86.8804 50.9962 87.3917 51.7549C87.8549 52.4489 88.655 53.1899 89.4309 53.3546C90.4475 53.5722 91.5905 53.2075 92.6793 53.1781C93.1605 53.1664 93.6477 53.3546 94.129 53.4546C93.8763 54.0309 93.762 54.966 93.3409 55.1307C91.1453 55.9776 88.8835 56.1305 86.6639 55.0837C84.4442 54.0309 83.6742 51.0844 84.1013 48.7202C84.8171 44.7092 87.5421 43.0624 91.5845 44.0681C93.4673 44.5386 94.544 46.1383 94.5922 48.5378ZM87.3015 48.726C88.5286 48.726 89.6174 48.7143 90.7062 48.726C91.7108 48.7437 91.8852 48.2261 91.6566 47.4028C91.2957 46.0854 90.2851 45.8148 89.1121 45.9854C87.6564 46.1971 87.2413 47.2028 87.3015 48.7202V48.726Z"
        fill="#181818"
      />
      <path
        d="M81.4245 42.5802C81.4245 46.1912 81.4245 49.6082 81.4245 53.0252C81.4245 55.6894 81.238 55.8306 78.2604 55.2131V42.6037C77.0152 42.6037 75.9324 42.5625 74.8556 42.6155C73.8511 42.6625 73.7007 42.0861 73.6826 41.3216C73.6646 40.5276 73.8571 40.0101 74.8436 40.0218C78.3446 40.0512 81.8515 40.0336 85.527 40.0336V42.5861H81.4184L81.4245 42.5802Z"
        fill="#181818"
      />
      <path
        d="M198.995 46.4088C197.997 46.25 197.034 45.9913 196.078 45.9971C195.615 45.9971 195.158 46.5147 194.694 46.797C194.989 47.2557 195.212 47.7968 195.603 48.1497C195.946 48.4555 196.481 48.5614 196.944 48.7319C199.224 49.5729 200.229 50.8727 199.964 52.6488C199.717 54.2779 198.057 55.6718 195.94 55.7482C194.827 55.7894 193.66 55.5424 192.583 55.213C191.482 54.8778 191.59 53.9192 191.819 52.8017C192.348 52.9488 192.824 53.137 193.317 53.1958C194.231 53.3134 195.2 53.5545 196.066 53.3722C197.167 53.1311 197.299 52.0313 196.349 51.4138C195.621 50.9374 194.761 50.6492 193.948 50.2846C192.481 49.6318 191.578 48.332 191.681 47.0146C191.801 45.4855 192.884 44.2387 194.604 44.0034C195.759 43.8446 196.986 43.9093 198.141 44.0975C199.41 44.3034 199.681 45.2855 198.995 46.403V46.4088Z"
        fill="#181818"
      />
      <path
        d="M104.74 52.1901C105.185 54.8425 102.923 56.3893 100.09 55.6836C96.6975 54.8367 95.6388 52.0078 96.108 48.6731C96.6494 44.8092 99.5909 42.9919 103.308 44.1328C103.922 44.321 104.487 44.9562 104.848 45.5208C105.047 45.8266 104.764 46.4265 104.698 46.897C104.253 46.8264 103.73 46.8852 103.381 46.6735C101.101 45.2738 99.3262 46.8441 99.1999 48.9201C99.1578 49.6024 99.1518 50.2964 99.254 50.9668C99.5668 52.9253 101.161 53.7369 103.02 52.937C103.579 52.6959 104.133 52.4548 104.74 52.196V52.1901Z"
        fill="#181818"
      />
      <path
        d="M145.218 55.1543V39.075C145.825 39.0338 146.361 38.9985 146.896 38.9632C147.925 38.8985 148.274 39.3455 148.262 40.3629C148.213 44.6268 148.244 48.8907 148.244 53.1546C148.244 55.5895 147.822 55.8835 145.224 55.1543H145.218Z"
        fill="#181818"
      />
      <path
        d="M174.693 49.667C174.693 48.2026 174.723 46.7323 174.687 45.2679C174.657 44.1563 175.373 44.0152 176.245 43.9858C177.166 43.9505 177.821 44.1857 177.815 45.2502C177.815 48.232 177.797 51.2138 177.827 54.1956C177.839 55.2895 177.154 55.5071 176.269 55.4836C175.439 55.4601 174.657 55.3542 174.687 54.2132C174.729 52.7018 174.699 51.1844 174.693 49.667Z"
        fill="#181818"
      />
      <path
        d="M178.056 40.9099C178.104 42.0155 177.256 42.3155 176.317 42.3214C175.403 42.3214 174.525 42.1155 174.513 40.8922C174.501 39.6572 175.253 39.2925 176.323 39.3043C177.322 39.3161 178.074 39.6689 178.056 40.9099Z"
        fill="#181818"
      />
    </g>
    <defs>
      <clipPath id="clip0_346_248">
        <rect width={200} height={62} fill="white" />
      </clipPath>
    </defs>
  </svg>
)
const Bi3TechLogoSVG = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      {
        theme === "light"

          ?
          <SvgComponentLightTheme /> : <SvgComponentDarkTheme />}
    </>
  )
}

export default Bi3TechLogoSVG


