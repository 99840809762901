import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";



const homeFilterSlice = createSlice({
  name: "homeFilter",
  initialState: false,
  reducers: {
    modifyHomeFilter: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { modifyHomeFilter } = homeFilterSlice.actions;
export const getHomeFilter = (state: Rootstate) => state.HomeFilterSlice;
export default homeFilterSlice.reducer;
