import React, { useState, useEffect, useRef, useContext} from "react";
import { useMsal } from "@azure/msal-react";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import LogoutIcon from "@mui/icons-material/Logout";
import { ThemeContext } from "../Context/ThemeContext";
import { motion } from "framer-motion";
import { useSelector } from 'react-redux'
import { getUserDetails } from '../Store/Userdetails.slice'
import styles from '../styles/ComponentStyles/UserProfile.module.scss'
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch"
import ToolTip from "../../src/Components/UIComponents/ToolTipComponent"



export interface ProfileProps {
  userDialogBox: boolean;
}

const UserProfile: React.FC<ProfileProps> = ({ userDialogBox }) => {
  const { instance } = useMsal();
  const [getshowCard, setShowCard] = useState(userDialogBox);
  const cardRef = useRef<HTMLDivElement>(null);
  const { theme, setTheme } = useContext(ThemeContext);
  const UserDetails = useSelector(getUserDetails)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setShowCard(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));


  const HandleLogOut = async () => {
    await instance.logoutPopup({
      mainWindowRedirectUri: "/",
    });
  };

  function UserLogoName(name: any) {
    if (name) {
      if (name.split(" ").length >= 2) {
        return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
      } else {
        return name.split(" ")[0][0];
      }
    }
  }

  useEffect(() => {
    setTheme("dark");
    localStorage.setItem('theme', "dark");
  }, [setTheme])


  const card = (
    <React.Fragment>
      <div className={` ${styles[theme]}`}>
        <div
          className={`${styles.cardContainer} ${styles[theme]}`}
        >

          <CardContent className={`${styles.noPadding} ${styles[theme]}`}
          >

            <div
              className={`${styles.contentContainer} ${styles[theme]}`}  >

              <div >

                {
                  UserDetails.userProfileImage === " "
                    // true
                    ?
                    (
                      <div className={`${styles.noDisplayImageBackGround} ${styles[theme]}`}>

                        <span className={`${styles.userInitials} ${styles[theme]}`}>{UserLogoName(UserDetails.userName)}</span>
                      </div>
                    ) : (
                      <div className={`${styles.userDisplayPictureContainer} ${styles[theme]}`}>

                        <img src={UserDetails.userProfileImage} alt="displayimage" className={`${styles.userDisplayPicture} ${styles[theme]}`} />

                      </div>
                    )}



              </div>
              <div className={`${styles.textContainer} ${styles[theme]}`}>
                <div className={`${styles.userName} ${styles[theme]}`} >
                  {UserDetails.userName}
                </div>
                {/* <div className={`${styles.userEmail} ${styles[theme]}`}>
                {UserDetails.userEmail}
                </div> */}

                <ToolTip data={UserDetails.userEmail} lastRow={false} ></ToolTip>
              </div>
            </div>



            <CardActions>
              <div className={`${styles.bttnsRow} ${styles[theme]}`}>

                {/* {theme === "dark" ? (
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    defaultChecked
                    onChange={(event) => {
                      if (event.target.checked) {
                        setTheme("dark");
                        localStorage.setItem('theme', "dark");
                      } else {
                        setTheme("light");
                        localStorage.setItem('theme', "light");
                      }
                    }}
                  />
                ) : (
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setTheme("dark");
                        localStorage.setItem('theme', "dark");
                      } else {
                        setTheme("light");
                        localStorage.setItem('theme', "dark");
                      }
                    }}
                  />
                )} */}

                <div>
                  <Button
                    size="small"
                    startIcon={<LogoutIcon className={`${styles.logoutIcon} ${styles[theme]}`} />}
                    // sx={{ fontSize: "1vw" }}
                    className={`${styles.bttnTxt} ${styles[theme]}`}
                    onClick={() => {
                      HandleLogOut();
                    }}
                  >
                    Logout
                  </Button>
                </div>
              </div>

            </CardActions>

          </CardContent>
        </div>
      </div>

    </React.Fragment>
  );


  return (
    <motion.div layoutId='userDetails'
      animate={{
        opacity: getshowCard ? "1" : "0.5"
      }}
      transition={{ duration: 0.5 }}
    >
      {getshowCard && (
        <div
          ref={cardRef}
        >
          {card}
        </div>
      )}
    </motion.div>
  );
};

export default UserProfile;
