import { Button } from '@mui/material';
import * as React from 'react';
import { useContext } from "react";
import { ThemeContext } from "../../Context/ThemeContext";
import styles from "../../styles/UIComponent.module.scss";



export default function PrimaryButtonComponent(props: any) {
    const { onClick, label, variant, fullWidth, size, disabled } = props
    const { theme } = useContext(ThemeContext);


    return (
        <div className={`${styles[theme]}`}>
            <div>
                <Button
                    variant={variant || "contained"}
                    onClick={onClick}
                    fullWidth={fullWidth || true}
                    size={size}
                    disabled={disabled || false}
                    className={disabled ? `${styles.PrimaryButtonStyleDisabled} ${styles[theme]}` : `${styles.PrimaryButtonStyle} ${styles[theme]}`}
                >
                    {label}
                </Button>
            </div>
        </div>
    )
};