import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rootstate } from "./store";

const initialState: number = 1;

const ComponentNavigation = createSlice({
    name: "ComponentNavigation",
    initialState,
    reducers: {
        setcurrentActivePage: (_, action: PayloadAction<number>) => {
            return action.payload;
        },
    }
})


export const { setcurrentActivePage } = ComponentNavigation.actions;

export const getcurrentActivePage = (state: Rootstate) => state.ComponentNavigation;

export default ComponentNavigation.reducer;