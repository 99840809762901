import {Configuration,PopupRequest} from "@azure/msal-browser"

export const msalConfig:Configuration = {
    auth: {
        // FOR PROD
        clientId: "95bd4a6f-4ce4-4d10-ae5d-634859f8d27e",
        authority: "https://login.microsoftonline.com/840f444f-3c56-46d7-a120-3448ecc8e653",
        redirectUri: "https://smarti.bi3demo.com/"

        //FOR BETA
        // clientId: "95bd4a6f-4ce4-4d10-ae5d-634859f8d27e",
        // authority: "https://login.microsoftonline.com/840f444f-3c56-46d7-a120-3448ecc8e653",
        // redirectUri: "https://beta-smarti.bi3demo.com/"

        // FOR LOCAL
        // clientId: "34c062c9-3a3d-4a95-b766-2dbfbf984db7",
        // authority: "https://login.microsoftonline.com/840f444f-3c56-46d7-a120-3448ecc8e653",                  
        // redirectUri: "http://localhost:3000"
    },
    system: {
        allowNativeBroker: false, iframeHashTimeout: 60000, // Disables WAM Broker
    },
};

// export const env : string =  "dev" 
export const env : string =  "prod" 


// export const LogInRequest:PopupRequest={
//     scopes:
//     ["api://f5640c50-be0c-4b65-99cc-ba06a6535ee4/Microsoft.Storage/storageAccounts/blobServices/generateUserDelegationKey/action"],
//     prompt:'consent'
// }

export const LogInRequest:PopupRequest={
    scopes:
    ["https://storage.azure.com/user_impersonation"]
}


export const UserDetailsrequest:PopupRequest={
    scopes:["User.Read","openid","offline_access","profile"]
}