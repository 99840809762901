import React , {SVGProps ,useContext } from "react"
import { ThemeContext } from '../../Context/ThemeContext'


const HomeIconFilledDarkTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 287">
      <g id="Vector">
        <path
          d="M1.69296 22.5178C1.89496 23.9321 3.1262 25 4.54983 25H20.4502C21.8738 25 23.105 23.9321 23.307 22.5178L24.9615 10.9342C24.9904 10.7322 25 10.5301 25 10.3281C25 8.9619 24.3459 7.67269 23.2397 6.86454L14.8134 0.736001C13.4667 -0.245334 11.5525 -0.245334 10.2058 0.736001L1.76991 6.87416C0.654098 7.6727 0 8.97152 0 10.3377C0 10.5397 0.0192381 10.7418 0.0384763 10.9438L1.69296 22.5178ZM2.89534 8.43275L11.3313 2.29459C11.6776 2.04445 12.0816 1.90976 12.5048 1.90976C12.928 1.90976 13.332 2.04445 13.6783 2.29459L22.1143 8.43275C22.7203 8.87531 23.0858 9.58726 23.0858 10.3377C23.0858 10.4531 23.0762 10.559 23.0666 10.6744L21.4121 22.258C21.3447 22.7295 20.9311 23.0854 20.4598 23.0854H4.54983C4.07849 23.0854 3.66487 22.7295 3.59754 22.258L2.7703 16.4614L1.94306 10.6648C1.93344 10.559 1.92382 10.4435 1.92382 10.3281C1.92382 9.58726 2.28934 8.87531 2.89534 8.43275Z"
          fill="#F5F5F5"
        />
        <path
          d="M2.89534 8.43275L11.3313 2.29459C11.6776 2.04445 12.0816 1.90976 12.5048 1.90976C12.928 1.90976 13.332 2.04445 13.6783 2.29459L22.1143 8.43275C22.7203 8.87531 23.0858 9.58726 23.0858 10.3377C23.0858 10.4531 23.0762 10.559 23.0666 10.6744L21.4121 22.258C21.3447 22.7295 20.9311 23.0854 20.4598 23.0854H4.54983C4.07849 23.0854 3.66487 22.7295 3.59754 22.258L2.7703 16.4614L1.94306 10.6648C1.93344 10.559 1.92382 10.4435 1.92382 10.3281C1.92382 9.58726 2.28934 8.87531 2.89534 8.43275Z"
          fill="#F5F5F5"
        />
      </g>
      <path
        id="Vector_2"
        d="M9.15429 22.5H16.08C16.7149 22.5 17.2343 21.9805 17.2343 21.3455C17.2343 20.7105 16.7149 20.191 16.08 20.191H9.15429C8.51943 20.191 8 20.7105 8 21.3455C8 21.9805 8.51943 22.5 9.15429 22.5Z"
        fill="#1D1E20"
      />
    </g>
  </svg>
)


const HomeIconFilledLightTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.69296 22.5178C1.89496 23.9321 3.1262 25 4.54983 25H20.4502C21.8738 25 23.105 23.9321 23.307 22.5178L24.9615 10.9342C24.9904 10.7322 25 10.5301 25 10.3281C25 8.9619 24.3459 7.67269 23.2397 6.86454L14.8134 0.736001C13.4667 -0.245334 11.5525 -0.245334 10.2058 0.736001L1.76991 6.87416C0.654098 7.67269 0 8.97152 0 10.3377C0 10.5397 0.0192381 10.7418 0.0384763 10.9438L1.69296 22.5178ZM2.89534 8.43275L11.3313 2.29459C11.6776 2.04445 12.0816 1.90976 12.5048 1.90976C12.928 1.90976 13.332 2.04445 13.6783 2.29459L22.1143 8.43275C22.7203 8.87531 23.0858 9.58726 23.0858 10.3377C23.0858 10.4531 23.0762 10.559 23.0666 10.6744L21.4121 22.258C21.3447 22.7295 20.9311 23.0854 20.4598 23.0854H4.54983C4.07849 23.0854 3.66487 22.7295 3.59754 22.258L2.7703 16.4614L1.94306 10.6648C1.93344 10.559 1.92382 10.4435 1.92382 10.3281C1.92382 9.58726 2.28934 8.87531 2.89534 8.43275Z"
      fill="#28282b"
    />
    <path
      d="M2.89534 8.43275L11.3313 2.29459C11.6776 2.04445 12.0816 1.90976 12.5048 1.90976C12.928 1.90976 13.332 2.04445 13.6783 2.29459L22.1143 8.43275C22.7203 8.87531 23.0858 9.58726 23.0858 10.3377C23.0858 10.4531 23.0762 10.559 23.0666 10.6744L21.4121 22.258C21.3447 22.7295 20.9311 23.0854 20.4598 23.0854H4.54983C4.07849 23.0854 3.66487 22.7295 3.59754 22.258L2.7703 16.4614L1.94306 10.6648C1.93344 10.559 1.92382 10.4435 1.92382 10.3281C1.92382 9.58726 2.28934 8.87531 2.89534 8.43275Z"
      fill="#343434"
    />
    <path
      d="M9.15429 22.5H16.08C16.7149 22.5 17.2343 21.9805 17.2343 21.3455C17.2343 20.7105 16.7149 20.191 16.08 20.191H9.15429C8.51943 20.191 8 20.7105 8 21.3455C8 21.9805 8.51943 22.5 9.15429 22.5Z"
      fill="white"
    />
  </svg>
)



const HomeIconFilledSVG = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      {
        theme === "light"

          ?
          <HomeIconFilledLightTheme /> : <HomeIconFilledDarkTheme />}
    </>
  )
}

export default HomeIconFilledSVG
