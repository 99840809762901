import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import styles from "../../styles/UIComponent.module.scss";
import { useContext } from "react";
import { ThemeContext } from "../../Context/ThemeContext"
import dayjs from "dayjs";

export default function DatePickerComponent(props: any) {

  const { value, handleChange, label } = props
  const { theme } = useContext(ThemeContext);

  const handleDateChange = (event: any) => {
    const formattedDate = dayjs(event).format("YYYY-MM-DD");

    // Check if the formatted date is "Invalid Date"
    if (formattedDate === "Invalid Date") {
      handleChange(null); // Optionally, handle the invalid date
      return; // Exit the function early
    }
    handleChange(formattedDate);
  }

  return (
    <Box>
      <FormControl style={{width: "100%" }}>
        <p className={`${styles.labelContent} ${styles[theme]}`}>{label}</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disableFuture
            showDaysOutsideCurrentMonth
            format={"DD/MM/YYYY"}
            value={value ? dayjs(value) : null}
            onChange={(event: any) => handleDateChange(event)}
            className={`${styles.dateTimePickerStyle} ${styles[theme]}`}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme === 'light' ? "#2e5cff" : "#6a48f2"
              }
            }}

            slotProps={{
              textField: {
                inputProps: {
                  // style: {
                  //   height: "3.5vh",
                  //   fontSize: "0.9vw",
                  //   padding: "0.5vw",
                  //   borderRadius: "1vw",
                  // },
                  className : `${styles.dateTimePropStyle}`
                },
              },
              // layout: {
              //   sx: {
              //     '.MuiDateCalendar-root': {
              //       width: "18.8vw",
              //       height: "18.5vw",
              //       maxHeight: "20vw",
              //       // border: "2px solid red",
              //     },
              //     '.MuiPickersCalendarHeader-root': {
              //       height: "3.3vw",
              //       maxHeight: "3vw",
              //       // border: "2px solid red",
              //       padding: "1%",
              //       fontSize: "1.3vw"
              //     },
              //     '.MuiPickersCalendarHeader-labelContainer': {
              //       fontSize: "0.9vw",
              //       paddingLeft: "2vw",
              //       // border: "2px solid blue"
              //     },
              //     '.MuiDayCalendar-header': {
              //       height: "3vw",
              //       maxHeight: "10vw",
              //       // border: "2px solid green"
              //     },
              //     '.MuiDayCalendar-weekDayLabel': {
              //       fontSize: "0.7vw",
              //       height: "3vw",
              //       maxHeight: "10vw",
              //       padding: "0.5vw",
              //       width: "2vw",
              //       // border: "2px solid yellow"

              //     },
              //     // '.MuiDayCalendar-root' : {
              //     //   backgroundColor :"red",
              //     //   height : "25vw" ,
              //     //   maxHeight : "50vw",
              //     // },
              //     '.MuiButtonBase-root': {
              //       fontSize: "0.9vw",
              //       height: "2vw",
              //       maxHeight: "10vw",
              //       padding: "0.5vw",
              //       width: "2vw",
              //       // border: "2px solid black"

              //     },
              //     '.MuiDayCalendar-slideTransition': {
              //       // border: "2px solid orange",
              //       // width: "200vw",
              //       height: "11vw",
              //       maxHeight: "11vw"
              //     },
              //     '.MuiDayCalendar-monthContainer ': {
              //       // border: "2px solid purple",
              //       // width: "200vw",
              //       height: "11vw",
              //       maxHeight: "11vw"


              //     },
              //     '.MuiYearCalendar-root': {
              //       width: "18.8vw",
              //       height: "19vw",
              //       maxHeight: "20vw",
              //     },
              //     '.MuiPickersYear-root': {
              //       width: "18.8vw",
              //       maxWidth: "18.8vw",
              //     },
              //     '.MuiPickersYear-yearButton': {
              //       fontSize: "1vw",
              //       width: "4.5vw",
              //       height: "2vw",
              //       margin: "0.5vw"
              //     }
              //   },
              // },
            }}
          />

        </LocalizationProvider>
      </FormControl>
    </Box>
  );
}